import { useEffect, useState } from "react";

interface BluredInputProps {
  initialValue: string;
  onBlur: (value: string) => void;
  className: string;
}

const BluredInput = (props: BluredInputProps) => {
  const [value, setValue] = useState(props.initialValue);
  useEffect(() => setValue(props.initialValue), [props.initialValue]);
  return (
    <input
      value={value}
      onChange={e => setValue(e.currentTarget.value)}
      onBlur={() => props.onBlur(value)}
    />
  );
};

export default BluredInput;
