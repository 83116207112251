import UsersManager from "../components/UsersManager";
import { trpc } from "../../main/components/MainContainer";
import useUser from "../hooks/useUser.ts";
import { useEffect } from "react";
import { useLocation } from "wouter";

export type SmsCountMap = { [userId: string]: number };

const UsersManagerContainer = () => {
  const user = useUser();
  const [, navigate] = useLocation();
  useEffect(() => {
    if (!user || user.username !== "admin") {
      navigate("/");
    }
  }, [user]);
  const { data: smsSentCountMap } =
    trpc.reminder.getEverySmsSentCount.useQuery();
  const { data: users } = trpc.user.getAll.useQuery();
  const trpcUtils = trpc.useContext();
  const updateUserMutation = trpc.user.updateUser.useMutation({
    onMutate: async (input) => {
      trpcUtils.user.getAll.setData(
        undefined,
        (users) =>
          users &&
          users.map((user) => {
            if (user._id === input._id) {
              return {
                ...user,
                active: input.active,
              };
            }
            return user;
          })
      );
    },
    onSettled: () => {
      trpcUtils.user.invalidate();
    },
  });
  const updateUserActiveStatusMutation =
    trpc.user.updateActiveStatus.useMutation({
      onSettled: () => {
        trpcUtils.user.invalidate();
      },
    });
  const onAddUser = (
    receptorId: string,
    index: number,
    userId: string | null
  ) => {
    const user = users && users.find((u) => u._id === receptorId);
    if (!user) {
      throw new Error("no user found in onAddUser");
    }
    let userIds: string[] = [];
    if (user.tag === "doctor") {
      userIds = user.secretaries || [];
    }
    if (user.tag === "secretary") {
      userIds = user.doctor_id || [];
    }
    if (userId === null) {
      if (userIds[index]) {
        userIds.splice(index, 1);
      }
    }
    if (userId && userIds.includes(userId)) {
      return;
    }
    if (userId !== null) {
      if (index !== 0) {
        userIds.splice(index, 1, userId);
      } else {
        //index = 0, on ajoute à la fin
        userIds.push(userId);
      }
    }

    const updatedUser =
      user.tag === "secretary"
        ? { ...user, doctor_id: userIds }
        : user.tag === "doctor"
        ? { ...user, secretaries: userIds }
        : null;
    updatedUser && updateUserMutation.mutate(updatedUser);
  };
  const onUpdateFullName = (userId: string, fullname: string) => {
    const user = users && users.find((u) => u._id === userId);
    if (!user) {
      throw new Error("no user found in onUpdateFullName");
    }
    updateUserMutation.mutate({ ...user, fullname });
  };
  const onUpdateMessageName = (userId: string, messagename: string) => {
    const user = users && users.find((u) => u._id === userId);
    if (!user) {
      throw new Error("no user found in onUpdateFullName");
    }
    const newUser = { ...user, messagename };

    updateUserMutation.mutate(newUser);
  };
  const onUpdateActiveStatus = (userId: string, active: boolean) => {
    const user = users && users.find((u) => u._id === userId);
    if (!user) {
      throw new Error("no user found in onUpdateActiveStatus");
    }
    updateUserActiveStatusMutation.mutate({ userId, active });
  };
  const makeSecretaireMutation = trpc.user.makeSecretaire.useMutation({
    onSettled: () => {
      trpcUtils.user.invalidate();
    },
  });
  const makeDoctorMutation = trpc.user.makeDoctor.useMutation({
    onSettled: () => {
      trpcUtils.user.invalidate();
    },
  });
  const onUpdateRole = (
    userId: string,
    role: "docteur" | "secrétaire" | null | undefined
  ) => {
    const user = users && users.find((u) => u._id === userId);
    if (!user) {
      throw new Error("no user found in onUpdateRole");
    }
    if (role === "secrétaire") {
      makeSecretaireMutation.mutate(userId);
    }
    if (role === "docteur") {
      makeDoctorMutation.mutate(userId);
    }
    if (role === null) {
      return;
    }
  };
  return (
    <UsersManager
      users={users || []}
      smsCountMap={smsSentCountMap || {}}
      onAddUser={onAddUser}
      onUpdateFullName={onUpdateFullName}
      onUpdateMessageName={onUpdateMessageName}
      onAssignRole={onUpdateRole}
      onUpdateActiveStatus={onUpdateActiveStatus}
    />
  );
};

export default UsersManagerContainer;
