import formatMessageForDisplay from "./formatMessageForDisplay";
import { Collections } from "cadendar-shared";

const formatThreadsForDisplay = (
  threads: Collections.Message[],
  now: Date,
  allUsers: Collections.User[],
  currentUserId: string
) => {
  return threads.map((thread) => ({
    ...thread,
    messages: thread.messages.map((msg) =>
      formatMessageForDisplay(msg, now, allUsers, currentUserId)
    ),
  }));
};

export default formatThreadsForDisplay;
