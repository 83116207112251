import useUser from "../../users/hooks/useUser";
import { Route, Switch } from "wouter";
import AgendaPage from "../../agenda/containers/AgendaPage.tsx";
import DuplicateMerger from "../../patients/components/DuplicateMerger.tsx";
import PréférencesMainPanel from "../../preferences/components/PréférencesMainPanel.tsx";
import HorairesContainer from "../../horaires/containers/HorairesContainer.tsx";
import StatsContainer from "../../stats/containers/StatsContainer.tsx";
import UsersManagerContainer from "../../users/containers/UsersManagerContainer.tsx";
import Logout from "../../main/components/Logout.tsx";

// export const trpc = createTRPCReact<AppRouter>();

const AppSelectorContainer = () => {
  // const user = useTracker(() => Meteor.user()) as User | null;
  const user = useUser();
  // const doctors = allUsers.filter((u) => "is_doctor" in u && !!u.is_doctor);
  // const [_doc, setSeletectedDoc] = useAtom(SeletectedDoctorState);
  // const firstDoc = _.first(doctors);
  // setSeletectedDoc(firstDoc?._id || "");

  return user ? (
    <Switch>
      <Route path="/" component={AgendaPage} />
      <Route path="/fusion" component={DuplicateMerger} />
      <Route path="/prefs" component={PréférencesMainPanel} />
      <Route path="/horaires" component={HorairesContainer} />
      <Route path="/stats" component={StatsContainer} />
      <Route path="/users" component={UsersManagerContainer} />
      <Route path="/logout" component={Logout} />
    </Switch>
  ) : null;
};

export default AppSelectorContainer;

// export const queryClient = new QueryClient();
//
// const url = Meteor.settings.public.API_HOST;
// const ws_url = Meteor.settings.public.WS_HOST;
//
// if (!url) {
//   throw new Error("API_HOST is not defined");
// }
//
// if (!ws_url) {
//   throw new Error("WS_HOST is not defined");
// }
//
// // let _userId = "";
// // let _owner = "";
// // let _token = "";
//
// const wsClient = createWSClient({
//   url: ws_url,
// });
//
// console.log("wsClient", wsClient);
//
// const getTrpcClient = (userId: string, token: string, owner: string) =>
//   trpc.createClient({
//     links: [
//       splitLink({
//         condition: (op) => {
//           return op.type === "subscription";
//         },
//         true: wsLink({ client: wsClient }),
//         false: httpBatchLink({
//           url,
//           headers() {
//             return {
//               authorization: "Basic " + btoa(userId + ":" + token),
//               "x-cadendar-owner": owner,
//             };
//           },
//         }),
//       }),
//     ],
//     transformer: superjson,
//   });
//
// // console.log("trpcClient", trpcClient);
//
// const RootComponent = () => {
//   const [trpcClient, setTrpcClient] = useState(getTrpcClient("", "", ""));
//   // const API_HOST = Meteor.settings.public.API_HOST;
//   // if (!API_HOST) {
//   //   throw new Error("API_HOST is not defined");
//   // }
//   // const setApiHost = useSetAtom(ApiConfigState);
//   // useEffect(() => {
//   //   setApiHost((obj) => ({ ...obj, host: API_HOST }));
//   // }, [API_HOST]);
//
//   const userId = useTracker(() => Meteor.userId());
//   const setUserId = useSetAtom(LoggedUserId);
//   const [token, setToken] = useAtom(TokenState);
//   const selectedDoctorId = useAtomValue(SeletectedDoctorState);
//   const user = useTracker(() => Meteor.user());
//   const owner = getOwnerFromUser(
//     user as Collections.User | null,
//     selectedDoctorId
//   );
//   console.log("owner in AppSelectorContainer", owner);
//   useEffect(() => {
//     const newToken = localStorage.getItem("Meteor.loginToken");
//     if (newToken && newToken !== token) {
//       setToken(newToken);
//       setTrpcClient(getTrpcClient(userId || "", newToken, owner || ""));
//       setUserId(userId);
//     }
//   }, [userId]);
//
//   return (
//     <trpc.Provider client={trpcClient} queryClient={queryClient}>
//       <Provider store={getStore()}>
//         <AppSelectorContainer />
//       </Provider>
//     </trpc.Provider>
//   );
// };
//
// export default () => (
//   <QueryClientProvider client={queryClient}>
//     <RootComponent />
//   </QueryClientProvider>
// );
//
// export interface ApiConfig {
//   host: string;
// }
//
// export interface ApiAuth {
//   userId: string;
//   token: string;
//   owner: string;
// }
