import { useState } from "react";
import NewRdv from "../components/NewRdv";
import useOwner from "../../users/hooks/useOwner";
import { useAtomValue } from "jotai";
import {
  aptRequestUsedAtom,
  isStrategorRunningAtom,
  RdvProposal,
  selectedWeekAtom,
  usePreferences,
  useRdvPopupState,
  useSelectedPatientIds,
} from "../../main/state/globalState";
import { useRelevantHoraires } from "../../horaires/hooks/useRelevantHoraires";
import { useThreeWeeksRdvs } from "../queries/rdvsQueries";
import sliceHM from "../processors/sliceHM";
import rdvsFromPlage from "../processors/rdvsFromPlage";
import {
  Collections,
  HMObject,
  typesMotifs,
  CadendarConsts,
  typeNumsType,
  Transforms,
} from "cadendar-shared";
import { _areDateCompatibleWithConfirmation } from "../filterTransforms/confirmationDates";
import { checkers, formaters } from "vincent-utils";
import _ from "underts";
import useToday from "../../main/hooks/useToday";
import useSelectedPatients from "../../patients/hooks/useSelectedPatients";
import useRdvProposal from "../../ficheAppel/hooks/useRdvProposal";
import { trpc } from "../../main/components/MainContainer";
import { ExistingPatient } from "cadendar-shared";
import { toast } from "react-toastify";

interface NewRdvApiProps {
  onSave: () => void;
}

const NewRdvApi = (props: NewRdvApiProps) => {
  const owner = useOwner();
  const selectedWeek = useAtomValue(selectedWeekAtom);
  const [horaire] = useRelevantHoraires(selectedWeek);
  const [rdvs, isRdvReady] = useThreeWeeksRdvs(selectedWeek);
  const patients = useSelectedPatients();
  const prefs = usePreferences();

  const { rdvProposal, clearRdvProposal } = useRdvProposal();
  console.log("rdvProposal", rdvProposal);
  // const { rdvCanvas } = useRdvCanvas();
  const { onCloseRdvPopup } = useRdvPopupState();
  // const apiConfig = useAtomValue(ApiConfigState);
  const trpcUtils = trpc.useContext();
  const closeWholeDayMutation = trpc.rdv.closeWholeDay.useMutation({
    onSuccess: () => {
      trpcUtils.rdv.invalidate();
    },
    onError: () => {
      alert(
        "Impossible de fermer la journée, est-ce qu'il ne resterait pas des rdvs présents ?"
      );
    },
  });
  const onCloseWholeDay = async (date: Date) => {
    closeWholeDayMutation.mutate({ date });
    onCloseRdvPopup();
    // const result = await closeWholeDay(apiConfig, apiAuth, date);
    // if (result) {
    //   queryClient.invalidateQueries(["rdvs"]);
    // }
  };
  const reserveForDoctoMutation = trpc.rdv.reserveForDocto.useMutation({
    onSuccess: () => {
      trpcUtils.rdv.invalidate();
    },
    onError: () => {
      alert("Il y a eu une erreur en essayant d'enregistrer");
    },
  });

  const [, { resetSelectedPatientIds }] = useSelectedPatientIds();
  const onReserveForDocto = (options: {
    date: Date;
    duree: number;
    heure: number;
    minute: number;
  }) => {
    console.log("options", JSON.stringify(options, null, 2));
    onCloseRdvPopup();
    resetSelectedPatientIds();

    reserveForDoctoMutation.mutate(options);
  };
  //const commitRdvs = useConfiguratedCommiter(createMultipleRdvs);
  const commitRdvsMutation = trpc.rdv.createMultiple.useMutation({
    onSuccess: (data) => {
      const patient = _.first(patients);
      const rdv = _.first(data);
      clearRdvProposal();
      trpcUtils.rdv.invalidate();
      trpcUtils.aptRequest.invalidate();
      if (!patient) {
        //c'est possible quand on est en train de fermer une plage
        return;
      }
      if (!rdv) {
        throw new Error("no rdv in onSaveRdvs success");
      }
      toast.success(
        formaters.formatNomPrenomForDisplay(patient).nom +
          " " +
          formaters.formatNomPrenomForDisplay(patient).prenom +
          " , nous vous confirmons votre rdv le " +
          formaters.formatDateForDisplayWithDay(rdv && rdv.date) +
          " à " +
          formaters.formatHMObjectForDisplay(rdv) +
          " "
      );
    },
  });
  const onSaveRdvs = async (options: {
    rdvs: Omit<Collections.Rdv, "_id" | "log" | "owner">[];
    createReminders: boolean;
    askForConfirmation: boolean;
    mode: "manuel" | "strategor";
    aptRequestId?: string;
  }) => {
    const type = _.first(options.rdvs)?.type;
    if (patients.length === 0 && type !== CadendarConsts.typeConsts.FERME) {
      alert("Vous devez sélectionner un patient ou fermer la plage");
      return;
    }
    onCloseRdvPopup();
    props.onSave();
    commitRdvsMutation.mutate(options);
  };
  if (prefs && horaire && rdvProposal && owner && isRdvReady) {
    return (
      <NewRdvLogic
        proposal={rdvProposal}
        patients={patients}
        rdvs={rdvs}
        horaire={horaire}
        prefs={prefs}
        owner={owner}
        onSaveRdvs={onSaveRdvs}
        onClosePopup={onCloseRdvPopup}
        onCloseWholeDay={onCloseWholeDay}
        onReserveForDocto={onReserveForDocto}
      />
    );
  }
  return null;
};

const rdvsCreator = (
  rdv: {
    heure: number;
    minute: number;
    date: Date;
    objet: string;
    type: typeNumsType;
    motifs?: CadendarConsts.MotifsKeys[];
  },
  patients: ExistingPatient[],
  duree: number
) => {
  console.log("duree in rdvsCreator", duree);
  const step = duree / (patients.length || 1); //patients.length peut être 0 si on ferme la plage
  const HMs = sliceHM({ start: rdv, end: new HMObject(rdv).add(duree) }, step);
  if (patients.length > 0 && HMs.length !== patients.length) {
    throw new Error("HMs.length !== patients.length in rdvsCreator");
  }
  if (!rdv.date) {
    throw new Error("no rdv.date in rdvsCreator");
  }
  //le log et l'_id vont être créés par le serveur
  const rdvsToCreate: Omit<Collections.Rdv, "_id" | "log" | "owner">[] =
    HMs.map((hm, index) => {
      const patient = patients[index] || null;
      return {
        date: rdv.date!,
        ...hm,
        objet: rdv.objet,
        type: rdv.type,
        ...(patient
          ? {
              nom: patient.nom,
              prenom: patient.prenom,
              patient_id: patient._id,
              patient_ddn: patient.ddn,
              ...(patient.famille_id
                ? { famille_id: patient.famille_id }
                : null),
            }
          : {}),
        ...(patient?.patient_flags
          ? { patient_flags: patient.patient_flags }
          : {}),
        motif: rdv.motifs?.map((key) => CadendarConsts.motifConsts[key]) || [],
        //TODO: adresseur_id adressedBy
        duree: duree / (patients.length || 1),
      };
    });
  return rdvsToCreate;
};

const getHeuresPossibles = (
  rdvs: Collections.Rdv[],
  dureeMiniRdv: number,
  date: Date,
  horaire: Collections.HoraireInDB,
  currentRdv?: Collections.Rdv
) => {
  const bornes = Transforms.horaires.bornesForDay(horaire, date);
  const dayRdvs = rdvs.filter((rdv) => rdv.date.getDate() === date.getDate());
  const rdvsGroupedByPlage = bornes.map((plage: Collections.Plage) =>
    rdvsFromPlage(dayRdvs, plage)
  );
  const emptyCasesGroupedByPlage = rdvsGroupedByPlage.map((plageRdvs, index) =>
    Transforms.rdvs.emptyCases(
      plageRdvs,
      bornes[index],
      dureeMiniRdv,
      currentRdv
    )
  );
  return emptyCasesGroupedByPlage.flat(1);
};

const getTypeFromMotif = (motifs: number[], patients: ExistingPatient[]) => {
  if (motifs.length === 0 || patients.length === 0) {
    return 1;
  }
  const patient = _.first(patients) as ExistingPatient;
  return typesMotifs.getTypeAndPotentielFromMotifs(motifs, patient).type;
};

const autoSetDuree = (actualDuree: number, dureesPossibles: number[]) => {
  if (dureesPossibles.includes(actualDuree)) {
    return actualDuree;
  } else {
    return _.last(dureesPossibles) || 15;
  }
};

const useConfirmAndReminderAreAuthorized = (
  rdv: { date: Date },
  patients: ExistingPatient[]
) => {
  const today = useToday();
  const patient = _.first(patients);
  const hasReminderTarget =
    !!patient && (checkers.isEmail(patient.email) || !!patient.portableE164);
  const hasConfirmationTarget = !!patient && !!patient.portableE164;
  const prefs = usePreferences();
  const isSmsTextSet = !!prefs && !!prefs.smsName && !!prefs.smsTxt;
  const isEmailTextSet = !!prefs && !!prefs.emailExpediteur && !!prefs.emailTxt;
  const areDateCompatible = _areDateCompatibleWithConfirmation(today, rdv);
  const isReminderAuthorized =
    hasReminderTarget && isSmsTextSet && isEmailTextSet;
  const isConfirmationAuthorized =
    hasConfirmationTarget && isSmsTextSet && areDateCompatible;
  return { isReminderAuthorized, isConfirmationAuthorized };
};

interface NewRdvLogicProps {
  proposal: RdvProposal;
  patients: ExistingPatient[];
  rdvs: Collections.Rdv[];
  horaire: Collections.HoraireInDB;
  prefs: Collections.Preferences;
  owner: string;
  onSaveRdvs: (options: {
    rdvs: Omit<Collections.Rdv, "_id" | "log" | "owner">[];
    createReminders: boolean;
    askForConfirmation: boolean;
    mode: "manuel" | "strategor";
    aptRequestId?: string;
  }) => void;
  onClosePopup: () => void;
  onCloseWholeDay: (date: Date) => void;
  onReserveForDocto: (options: {
    date: Date;
    duree: number;
    heure: number;
    minute: number;
  }) => void;
}

const useTempRdv = (
  proposal: RdvProposal,
  patients: ExistingPatient[],
  horaire: Collections.HoraireInDB,
  rdvs: Collections.Rdv[],
  prefs: Collections.Preferences
) => {
  const initialDureesPossibles =
    proposal.date && proposal.heure != null && proposal.minute != null
      ? Transforms.rdvs
          .getDurees2(
            horaire,
            rdvs,
            proposal.date,
            {
              heure: proposal.heure,
              minute: proposal.minute,
            },
            prefs.duréeMinRdv
          )
          .filter((d) => (d / (patients.length || 1)) % prefs.duréeMinRdv === 0)
      : [];

  const [tempRdv, setTempRdv] = useState({
    ...proposal,
    type:
      proposal.type ||
      (getTypeFromMotif(
        proposal.motifs
          ? proposal.motifs.map((key) => CadendarConsts.motifConsts[key])
          : [],
        patients
      ) as typeNumsType),
    reminderChecked: proposal.reminderChecked || false,
    confirmChecked: proposal.confirmChecked || false,
    objet: proposal.objet || "",
    duree: proposal.duree || _.first(initialDureesPossibles) || 0,
    heure: proposal.heure as number,
    minute: proposal.minute as number,
    date: proposal.date as Date,
  });
  const hmPossibles = proposal.date
    ? getHeuresPossibles(rdvs, prefs.duréeMinRdv, proposal.date, horaire)
    : [];

  const dureesPossibles = Transforms.rdvs
    .getDurees2(
      horaire,
      rdvs,
      tempRdv.date,
      { heure: tempRdv.heure, minute: tempRdv.minute },
      prefs.duréeMinRdv
    )
    .filter((d) => (d / (patients.length || 1)) % prefs.duréeMinRdv === 0);
  const onSetHM = (hm: { heure: number; minute: number }) => {
    setTempRdv((tempRdv) => ({
      ...tempRdv,
      heure: hm.heure,
      minute: hm.minute,
    }));
    const dureesPossibles = tempRdv.date
      ? Transforms.rdvs.getDurees2(
          horaire,
          rdvs,
          tempRdv.date,
          hm,
          prefs.duréeMinRdv
        )
      : [];
    //on modifie la durée déjà réglée si elle n'est plus possible
    setTempRdv((tempRdv) => {
      const _duree = autoSetDuree(tempRdv.duree, dureesPossibles);
      console.log("set autoSetDuree", _duree);
      return { ...tempRdv, duree: _duree };
    });
  };
  const onSetDuree = (duree: number) => {
    setTempRdv((tempRdv) => ({ ...tempRdv, duree }));
  };
  const onSetType = (type: typeNumsType) => {
    setTempRdv((tempRdv) => ({ ...tempRdv, type }));
  };
  const onSetObjet = (objet: string) => {
    setTempRdv((tempRdv) => ({ ...tempRdv, objet }));
  };
  const onToggleReminder = () => {
    setTempRdv((tempRdv) => ({
      ...tempRdv,
      reminderChecked: !tempRdv.reminderChecked,
    }));
  };
  const onToggleConfirm = () => {
    setTempRdv((tempRdv) => ({
      ...tempRdv,
      confirmChecked: !tempRdv.confirmChecked,
    }));
  };
  const [, { resetSelectedPatientIds }] = useSelectedPatientIds();
  const onClosePlage = () => {
    resetSelectedPatientIds();
    setTempRdv((tempRdv) => ({
      ...tempRdv,
      type: CadendarConsts.typeConsts.FERME,
      patient: null,
      objet: "Fermé",
      reminderChecked: false,
      confirmChecked: false,
    }));
  };
  return {
    tempRdv,
    onSetHM,
    onSetDuree,
    onSetType,
    onSetObjet,
    onToggleReminder,
    onToggleConfirm,
    onClosePlage,
    dureesPossibles,
    hmPossibles,
  };
};

//dependencies
//rdv du jour
//horaires
//prefs
const NewRdvLogic = (props: NewRdvLogicProps) => {
  const { proposal, patients, rdvs, horaire, prefs } = props;
  const { onClosePopup, onCloseWholeDay, onReserveForDocto } = props;

  const {
    tempRdv,
    onClosePlage,
    onSetObjet,
    onSetHM,
    onSetDuree,
    onSetType,
    hmPossibles,
    dureesPossibles,
    onToggleConfirm,
    onToggleReminder,
  } = useTempRdv(proposal, patients, horaire, rdvs, prefs);
  const { isReminderAuthorized, isConfirmationAuthorized } =
    useConfirmAndReminderAreAuthorized(tempRdv, patients);

  console.log("isReminderAuthorized", isReminderAuthorized);

  const isStrategorRunning = useAtomValue(isStrategorRunningAtom);

  const aptRequestUsed = useAtomValue(aptRequestUsedAtom);

  const handleReserveForDocto = () => {
    onReserveForDocto({
      date: tempRdv.date,
      duree: tempRdv.duree,
      heure: tempRdv.heure,
      minute: tempRdv.minute,
    });
  };
  const onSaveRdvs = () => {
    if (
      patients.length > 0 &&
      (tempRdv.duree! / patients.length) % prefs.duréeMinRdv !== 0
    ) {
      alert(
        `La durée du rendez-vous doit être un multiple de ${prefs.duréeMinRdv} minutes`
      );
      return;
    }
    const rdvs = rdvsCreator(tempRdv, patients, tempRdv.duree);
    props.onSaveRdvs({
      rdvs: rdvs,
      createReminders: tempRdv.reminderChecked,
      askForConfirmation: tempRdv.confirmChecked,
      mode: isStrategorRunning ? "strategor" : "manuel",
      ...(aptRequestUsed ? { aptRequestId: aptRequestUsed } : null),
    });
  };

  return (
    <NewRdv
      selectedPatients={props.patients}
      rdvCaracsState={{
        ...tempRdv,
        hmPossibles,
        dureesPossibles,
        confirmCheckBoxEnabled: isConfirmationAuthorized,
        remindersCheckBoxEnabled: isReminderAuthorized,
      }}
      onJournéeFermée={() => onCloseWholeDay(tempRdv.date)}
      onPlageFermée={onClosePlage}
      dureesPossibles={dureesPossibles}
      hmPossibles={hmPossibles}
      onNewRdvCancel={onClosePopup}
      onNewRdvSave={onSaveRdvs}
      onReserveForDocto={handleReserveForDocto}
      rdvCaracsCallbacks={{
        onDureeChange: onSetDuree,
        onHMChange: onSetHM,
        onTypeChange: onSetType,
        onObjetChange: onSetObjet,
        onReminderClick: onToggleReminder,
        onConfirmClick: onToggleConfirm,
      }}
    />
  );
};

export default NewRdvApi;
