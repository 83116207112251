import { ExistingPatient } from "cadendar-shared";
import styles from "./PatientEtatCivilDisplay.module.css";
import { formaters } from "vincent-utils";

interface PatientEtatCivilDisplayProps {
  patient: ExistingPatient;
}

const PatientEtatCivilDisplay = (props: PatientEtatCivilDisplayProps) => {
  const { patient } = props;
  return (
    <div className={styles.panel}>
      <div className={styles.label}>Nom :</div>
      <div className={styles.input}>
        {formaters.capitaliseEveryFirstLetter(patient.nom)}
      </div>
      <div className={styles.label}>Prénom :</div>
      <div className={styles.input}>
        {formaters.capitaliseEveryFirstLetter(patient.prenom)}
      </div>
      <div className={styles.label}>Date de naissance :</div>
      <div className={styles.input}>{patient.ddn}</div>
      <div className={styles.label}>Fixe :</div>
      <div className={styles.label}>Portable :</div>
      <div className={styles.input}>{patient.portableE164}</div>
      <div className={styles.label}>Email :</div>
      <div className={styles.input}>{patient.email}</div>
      <div className={styles.label}>Commentaires :</div>
      <div className={styles.input}>{patient.commentaires || ""}</div>
    </div>
  );
};

export default PatientEtatCivilDisplay;
