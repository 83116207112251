import styles from "./MiniCalendarTable.module.css";
import datesArrayBuilder from "../filterTranforms/datesArrayBuilder";

export interface MiniCalendarTableProps {
  weekSelected: Date;
  today: Date;
  month: number;
  year: number;
  onSelectDay: (day: Date) => void;
}

const MiniCalendarTable = (props: MiniCalendarTableProps) => {
  const { weekSelected, today, month, year } = props;
  const { onSelectDay } = props;
  const weeksArray = datesArrayBuilder(month, year, weekSelected, today);
  return (
    <table id="minicalendartable" className={styles.table}>
      <tbody>
        <tr>
          {["D", "L", "M", "M", "J", "V", "S"].map((day, idx) => (
            <td className={styles.jour_header} key={day + idx}>
              {day}
            </td>
          ))}
        </tr>
        {weeksArray.map((week) => (
          <tr
            key={
              week[0].year.toString() +
              week[0].month.toString() +
              week[0].day.toString()
            }
          >
            {week.map((obj) => (
              <td
                className={styles[obj.type]}
                onClick={onSelectDay.bind(
                  null,
                  new Date(obj.year, obj.month, obj.day)
                )}
                key={
                  obj.year.toString() +
                  obj.month.toString() +
                  obj.day.toString()
                }
              >
                {obj.day}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MiniCalendarTable;
