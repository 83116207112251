import MotifsCheckBoxes from "./MotifsCheckboxes";
import { useMinAptDuration } from "../../main/state/globalState";
import { checkers } from "vincent-utils";
import useRdvProposal from "../hooks/useRdvProposal";
import { CadendarConsts } from "cadendar-shared";
import IntegerInput from "../../../components/IntegerInput";
import styles from "./MotifsPanel.module.css";
import PageChooser from "./PageChooser.tsx";

interface MotifsPanelProps {
  onNextPage: () => void;
  onPrevPage: () => void;
}

const MotifsPanel = (props: MotifsPanelProps) => {
  const { rdvProposal, setDuree, deleteDuree, toggleMotif } = useRdvProposal();
  const duree = rdvProposal && rdvProposal.duree;
  const motifs = rdvProposal && rdvProposal.motifs;
  const hasMotifs = motifs && motifs.length > 0;
  const motifsToggled =
    (motifs && motifs.map((motifKey) => CadendarConsts.motifsMap[motifKey])) ||
    [];
  const minAptDuration = useMinAptDuration();
  const isValidDuration =
    !!duree && checkers.isValidDuree(duree, minAptDuration);
  const handleDureeChange2 = (value: number | null) => {
    if (value && checkers.isValidDuree(value, minAptDuration)) {
      setDuree(value);
    } else {
      deleteDuree();
    }
  };
  const showNext = !!hasMotifs;
  return (
    <div className={styles.spacer}>
      <div className="panel-heading">
        <h3 className="panel-title">Motifs de consultation</h3>
      </div>
      <div className="panel-body">
        {hasMotifs ? (
          <span></span>
        ) : (
          <div className="redconnexion">Choisir un ou plusieurs motifs</div>
        )}
        <MotifsCheckBoxes
          toggleMotif={toggleMotif}
          motifsToggled={motifsToggled}
        />
        <div>
          <div className={styles.verticalSpacer}>
            <label className={styles.title} htmlFor="rdvDureeStrategor">
              Durée spécifique du rdv (multiple de {minAptDuration}) :{""}
            </label>
            <IntegerInput
              value={duree || null}
              onValueChange={handleDureeChange2}
              forbidZero={true}
              id="rdvDureeStrategor"
              width={"100px"}
            />
            {isValidDuration ? (
              <span className="glyphicon glyphicon-ok form-control-feedback"></span>
            ) : (
              <span className="glyphicon glyphicon-remove form-control-feedback">
                {" "}
              </span>
            )}
          </div>
          <PageChooser
            onNextPage={props.onNextPage}
            onPrevPage={props.onPrevPage}
            prevPageEnabled={true}
            nextPageEnabled={showNext}
            nextPageText="Suivant"
            prevPageText="Précédent"
          />
        </div>
      </div>
    </div>
  );
};

export default MotifsPanel;
