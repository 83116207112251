import { useEffect, useState } from "react";
import CompactPatientChooser from "./CompactPatientChooser";
import useTempPatient from "../hooks/useTempPatient";
import { trpc } from "../../main/components/MainContainer";
import PageChooser from "./PageChooser.tsx";
import useRefferedResponded from "../hooks/useRefferedResponded.ts";
import Input from "../../main/components/Input.tsx";
import CheckBox from "../../main/CheckBox.tsx";

interface AdressePanelProps {
  onNextPage: () => void;
  onPrevPage: () => void;
}

const AdressePanel = (props: AdressePanelProps) => {
  const [nameLookup, setNameLookup] = useState("");
  const showNext = useRefferedResponded();
  const {
    tempPatient,
    isAutrePatientChecked,
    isFamilleChecked,
    isAnnuaireChecked,
    isAutreChecked,
    onToggleAutre,
    onToggleAutrePatient,
    onToggleAnnuaire,
    onToggleFamille,
    onOtherMeanReffereeChange,
    onSelectAdressedByAutrePatient,
    onSelectAdressedByFamillePatient,
    selectedAutrePatientAdressedBy,
    selectedFamilleAdressedBy,
    otherReffereeMeanValue,
  } = useTempPatient();

  const { nom } = tempPatient;
  console.log("nom", nom);
  useEffect(() => {
    setNameLookup(nom || "");
  }, [nom]);
  const { data: candidats } = trpc.patient.findByName.useQuery(
    { nameLookup },
    {
      enabled:
        !!nameLookup &&
        nameLookup.length > 1 &&
        (isFamilleChecked || isAutrePatientChecked),
    }
  );
  //   const[candidats] = useSuperQuery(
  //   ["patients", "byName", nameLookup],
  //   patientsByNameLookupFetch,
  //   [],
  //   {
  //     enabled:
  //       !!nameLookup &&
  //       nameLookup.length > 1 &&
  //       (isFamilleChecked || isAutrePatientChecked),
  //   },
  //   nameLookup
  // );

  return (
    <div>
      <div className="form-group">
        <label className="control-label">
          Comment avez vous connu le cabinet ?
        </label>

        <CheckBox
          checked={isAnnuaireChecked}
          onChange={onToggleAnnuaire}
          id={"annuaire_check"}
          label={"Annuaire/Internet/Journal de La ville/Pages Jaunes"}
        />
      </div>
      <CheckBox
        checked={isFamilleChecked}
        onChange={onToggleFamille}
        id={"famille_check"}
        label={"Membre de la famille déjà patient"}
      />
      {isFamilleChecked ? (
        <div id="familleAdresseurChooser">
          <CompactPatientChooser
            candidats={candidats || []}
            selected={
              selectedFamilleAdressedBy ? [selectedFamilleAdressedBy] : []
            }
            onSelectPatient={onSelectAdressedByFamillePatient}
            onChange={setNameLookup}
            lookup={nameLookup}
          />
        </div>
      ) : null}
      <CheckBox
        checked={isAutrePatientChecked}
        onChange={onToggleAutrePatient}
        id={"ami_check"}
        label={"Autre patient hors de la famille"}
      />
      {isAutrePatientChecked ? (
        <CompactPatientChooser
          candidats={candidats || []}
          selected={
            selectedAutrePatientAdressedBy
              ? [selectedAutrePatientAdressedBy]
              : []
          }
          onSelectPatient={onSelectAdressedByAutrePatient}
          onChange={setNameLookup}
          lookup={nameLookup}
        />
      ) : null}
      <CheckBox
        checked={isAutreChecked}
        onChange={onToggleAutre}
        id={"autre_check"}
        label={"Autre"}
      />

      {isAutreChecked ? (
        <Input
          value={otherReffereeMeanValue || ""}
          onChange={onOtherMeanReffereeChange}
          id="autre_adresseur"
        />
      ) : null}
      <PageChooser
        onNextPage={props.onNextPage}
        onPrevPage={props.onPrevPage}
        prevPageEnabled={true}
        nextPageEnabled={showNext}
        nextPageText="Suivant"
        prevPageText="Précédent"
      />
    </div>
  );
};

export default AdressePanel;
