import { useSelectedPatientIds } from "../../main/state/globalState";
import { trpc } from "../../main/components/MainContainer";

const useSelectedPatients = () => {
  const [selectedPatientsIds] = useSelectedPatientIds();
  const { data: patients } = trpc.patient.getByIds.useQuery(
    { _ids: selectedPatientsIds },
    { enabled: selectedPatientsIds.length > 0 }
  );
  return patients || [];
};

export default useSelectedPatients;
