import { useEffect, useState } from "react";
import { Collections, CadendarConsts, Transforms } from "cadendar-shared";
import EditRdv from "../components/EditRdv";
import { useAtomValue, useSetAtom } from "jotai";
import _ from "underts";
import {
  rdvToPostPoneIdAtom,
  selectedRdvIdAtom,
  usePreferences,
  useRdvPopupState,
  useSelectedWeek,
} from "../../main/state/globalState";
import useAllUsers from "../../users/hooks/useAllUsers";
import useSelectedPatients from "../../patients/hooks/useSelectedPatients";
import { useThreeWeeksRdvs } from "../queries/rdvsQueries";
import useRdvProposal from "../../ficheAppel/hooks/useRdvProposal";
import { useHoraire } from "../../horaires/hooks/useHoraire";
import { trpc } from "../../main/components/MainContainer";

export type EditRdvState = Collections.Rdv & {
  reminderChecked: boolean;
  confirmChecked: boolean;
};

const getInitialState = (rdv: Collections.Rdv): EditRdvState => {
  return {
    ...rdv,
    reminderChecked: !!rdv.reminders && rdv.reminders.length > 0,
    confirmChecked: !!rdv.confirmationSms && rdv.confirmationSms.length > 0,
  };
};

const useEditRdv = (rdvState: Collections.Rdv | null) => {
  const [tempRdv, setTempRdv] = useState(
    (rdvState && getInitialState(rdvState)) || null
  );

  useEffect(() => {
    if (!rdvState) return;
    setTempRdv(getInitialState(rdvState));
  }, [JSON.stringify(rdvState)]);
  const horaire = useHoraire(tempRdv && tempRdv.date);
  const { selectedWeek } = useSelectedWeek();
  const [allRdvs] = useThreeWeeksRdvs(selectedWeek);
  const prefs = usePreferences();
  const onSetHM = ({ heure, minute }: { heure: number; minute: number }) => {
    setTempRdv((tempRdv) => {
      if (!tempRdv) return null;
      const dureesPossibles =
        horaire &&
        prefs &&
        tempRdv &&
        Transforms.rdvs.getDurees2(
          horaire,
          allRdvs,
          tempRdv.date,
          { heure, minute },
          prefs.duréeMinRdv,
          tempRdv
        );
      const choosenDuree =
        (dureesPossibles &&
          (dureesPossibles.includes(tempRdv.duree)
            ? tempRdv.duree
            : dureesPossibles[0])) ||
        0;
      return { ...tempRdv, heure, minute, duree: choosenDuree };
    });
  };

  const onSetDuree = (duree: number) => {
    setTempRdv((tempRdv) => (tempRdv ? { ...tempRdv, duree } : null));
  };
  const onSetType = (type: number) => {
    setTempRdv((tempRdv) => (tempRdv ? { ...tempRdv, type } : null));
  };
  const onSetObjet = (objet: string) => {
    setTempRdv((tempRdv) => (tempRdv ? { ...tempRdv, objet } : null));
  };
  const onToggleReminder = () => {
    setTempRdv((tempRdv) =>
      tempRdv
        ? {
            ...tempRdv,
            reminderChecked: !tempRdv.reminderChecked,
          }
        : null
    );
  };
  const onToggleConfirm = () => {
    setTempRdv((tempRdv) =>
      tempRdv
        ? {
            ...tempRdv,
            confirmChecked: !tempRdv.confirmChecked,
          }
        : null
    );
  };
  return {
    tempRdv,
    onSetHM,
    onSetDuree,
    onSetType,
    onSetObjet,
    onToggleReminder,
    onToggleConfirm,
  };
};

interface EditRdvContainerProps {
  onOpenFicheAppel: () => void;
}

const EditRdvContainer = (props: EditRdvContainerProps) => {
  const rdvId = useAtomValue(selectedRdvIdAtom);
  const patients = useSelectedPatients();
  const patient = patients ? patients[0] : null;
  const { onCloseRdvPopup } = useRdvPopupState();
  const { data: oldRdv } = trpc.rdv.getById.useQuery(
    { _id: rdvId! },
    { enabled: !!rdvId }
  );
  const {
    tempRdv,
    onSetHM,
    onSetDuree,
    onSetType,
    onSetObjet,
    onToggleReminder,
    onToggleConfirm,
  } = useEditRdv(oldRdv || null);
  const horaire = useHoraire(tempRdv && tempRdv.date);
  const { selectedWeek } = useSelectedWeek();
  const [allRdvs] = useThreeWeeksRdvs(selectedWeek);
  const prefs = usePreferences();
  const hmPossibles =
    horaire &&
    prefs &&
    oldRdv &&
    Transforms.rdvs.heuresPossibles3(
      horaire,
      allRdvs,
      oldRdv.date,
      prefs.duréeMinRdv,
      oldRdv
    );
  const dureesPossibles =
    horaire &&
    prefs &&
    oldRdv &&
    tempRdv &&
    Transforms.rdvs.getDurees2(
      horaire,
      allRdvs,
      oldRdv.date,
      { heure: tempRdv.heure, minute: tempRdv.minute },
      prefs.duréeMinRdv,
      oldRdv
    );
  const remindersCheckboxEnabled =
    patient && Transforms.patients.hasRemindersTargets(patient);
  const confirmCheckboxEnabled =
    patient && Transforms.patients.hasConfirmationTarget(patient);
  const allUsers = useAllUsers();
  const onCancelRdvEdit = () => {
    onCloseRdvPopup();
  };
  // const onSave = useConfiguratedCommiter(saveEditedRdv);
  const saveMutation = trpc.rdv.commitEdited.useMutation({
    onSuccess: () => {
      onCloseRdvPopup();
      trpcUtils.rdv.invalidate();
    },
  });
  const trpcUtils = trpc.useContext();
  const cancelWithoutLoggingMutation =
    trpc.rdv.deleteWithoutLogging.useMutation({
      onSuccess: () => {
        onCloseRdvPopup();
        trpcUtils.rdv.invalidate();
      },
    });

  const cancelWithLoggingMutation = trpc.rdv.cancelWithLogging.useMutation({
    onSuccess: () => {
      onCloseRdvPopup();
      trpcUtils.rdv.invalidate();
    },
  });

  const handleSave = async () => {
    if (!tempRdv || !oldRdv) return;
    const addReminders =
      tempRdv.reminderChecked &&
      (!oldRdv.reminders || oldRdv.reminders.length === 0);
    const addConfirmations =
      tempRdv.confirmChecked &&
      (!oldRdv.confirmationSms || oldRdv.confirmationSms.length === 0);
    const removeReminders =
      !tempRdv.reminderChecked &&
      !!oldRdv.reminders &&
      oldRdv.reminders.length > 0;
    const removeConfirmations =
      !tempRdv.confirmChecked &&
      !!oldRdv.confirmationSms &&
      oldRdv.confirmationSms.length > 0;
    saveMutation.mutate({
      rdv: _.omit(tempRdv, ["reminderChecked", "confirmChecked"]),
      addReminders,
      addConfirmations,
      removeReminders,
      removeConfirmations,
    });
  };
  const handleCancelWithoutLogging = async () => {
    if (!oldRdv) return;
    cancelWithoutLoggingMutation.mutate({ _id: oldRdv._id });
  };

  const handleCancelWithLogging = async () => {
    if (!oldRdv) return;
    cancelWithLoggingMutation.mutate({ _id: oldRdv._id });
  };

  const { setDuree, assignMotifsNums, setType, setObjet } = useRdvProposal();
  const setPostponedId = useSetAtom(rdvToPostPoneIdAtom);
  const handlePostPone = async () => {
    if (!oldRdv) return;
    setPostponedId(oldRdv._id);
    setDuree(oldRdv.duree);
    setType(oldRdv.type as CadendarConsts.typeNumsType);
    oldRdv.objet && setObjet(oldRdv.objet);
    assignMotifsNums(oldRdv.motif || []); //hack pour forcer strategor à runner
    cancelWithLoggingMutation.mutate({ _id: oldRdv._id });
    props.onOpenFicheAppel();
  };
  if (tempRdv && hmPossibles && dureesPossibles) {
    return (
      <EditRdv
        rdvCaracsState={tempRdv}
        hmPossibles={hmPossibles}
        dureesPossibles={dureesPossibles}
        remindersCheckBoxEnabled={!!remindersCheckboxEnabled}
        confirmCheckBoxEnabled={!!confirmCheckboxEnabled}
        onSaveRdv={handleSave}
        onCancelRdvEdit={onCancelRdvEdit}
        onDeleteWithoutLogging={handleCancelWithoutLogging}
        onCancelWithLogging={handleCancelWithLogging}
        onPostPone={handlePostPone}
        rdvCaracsCallbacks={{
          onDureeChange: onSetDuree,
          onTypeChange: onSetType,
          onObjetChange: onSetObjet,
          onReminderClick: onToggleReminder,
          onConfirmClick: onToggleConfirm,
          onHMChange: onSetHM,
        }}
        users={allUsers}
        rdv={oldRdv}
        patient={patient}
      />
    );
  } else {
    return <></>;
  }
};

export default EditRdvContainer;
