import { formaters } from "vincent-utils";
import styles from "./NewRdv.module.css";

import RdvCaracs, { RdvCaracsCallbacks, RdvCaracsState } from "./RdvCaracs";
import PatientLookup from "../../patients/components/PatientLookup";
import { ExistingPatient } from "cadendar-shared";
import Button from "../../main/components/Button.tsx";

interface NewRdvProps {
  selectedPatients: ExistingPatient[];
  onNewRdvSave: () => void;
  onNewRdvCancel: () => void;
  onPlageFermée: () => void;
  onJournéeFermée: () => void;
  onReserveForDocto: () => void;
  rdvCaracsState: RdvCaracsState;
  hmPossibles: { heure: number; minute: number }[];
  dureesPossibles: number[];
  rdvCaracsCallbacks: RdvCaracsCallbacks;
}

const NewRdv = (props: NewRdvProps) => {
  const { rdvCaracsState, selectedPatients } = props;
  const {
    onNewRdvSave,
    onNewRdvCancel,
    onPlageFermée,
    onJournéeFermée,
    onReserveForDocto,
  } = props;
  const patientBandeaux =
    selectedPatients.length > 0 ? (
      selectedPatients
        .map(formaters.formatNomPrenomForDisplay)
        .map((patient, idx) => (
          <div className="patient_outline" key={"patientKey" + idx}>
            Patient: {patient.nom} {patient.prenom}
          </div>
        ))
    ) : (
      <div className="patient_outline" key={"patientKey" + "pasDePatient"}>
        Patient: PAS DE PATIENT SELECTIONNE
      </div>
    );
  return (
    <div data-testid="NewRdv">
      {patientBandeaux}
      <div className={styles.container}>
        <div className={styles.col}>
          <div className={styles.row}>
            <div id="patient_div" data-test="new_rdv_component">
              <div className={styles.patientDiv}>
                <PatientLookup />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.dateSpacer}>
            {formaters.formatDateForDisplay(rdvCaracsState.date)}
          </div>
          <RdvCaracs
            rdvsCaracsState={rdvCaracsState}
            rdvsCaracsCallbacks={props.rdvCaracsCallbacks}
          />
        </div>
      </div>
      <div className={styles.firstRowSpacer}>
        <div className={styles.buttonsSpacer}>
          <Button
            title="Plage fermée"
            onClick={onPlageFermée}
            id={"button_plage_fermee"}
            size={"small"}
          />
          <Button
            title="Journée fermée"
            onClick={onJournéeFermée}
            id={"button_journee_fermee"}
            size={"small"}
          />
          <Button
            title="Réserver pour Docto"
            onClick={onReserveForDocto}
            id={"button_reserve_docto"}
            size={"small"}
          />
        </div>
      </div>
      <div className={styles.lastRowSpacer}>
        <div className={styles.buttonsSpacer}>
          <Button
            title="Enregistrer RDV"
            onClick={onNewRdvSave}
            style="main"
            id="button_save_new_rdv"
          />
          <Button
            title="Abandonner modifications"
            onClick={onNewRdvCancel}
            style="secondary"
            id="button_cancel_new_rdv"
          />
        </div>
      </div>
    </div>
  );
};

export default NewRdv;
