import useUser from "../../users/hooks/useUser.ts";
import { useLocation } from "wouter";
import styles from "./MainMenu.module.css";

const MainMenu = () => {
  const user = useUser();
  const [, navigate] = useLocation();
  return (
    <div onClick={(e) => e.stopPropagation()} className={styles.menuPanel}>
      {user && user.tag === "doctor" ? (
        <>
          <div
            className={styles.choiceStyle}
            onClick={() => navigate("/fusion")}
          >
            Fusionner patients doublons
          </div>
          <div
            className={styles.choiceStyle}
            onClick={() => navigate("/prefs")}
          >
            Préférences
          </div>
          <div
            className={styles.choiceStyle}
            onClick={() => navigate("/horaires")}
          >
            Horaires
          </div>
          <div
            className={styles.choiceStyle}
            onClick={() => navigate("/stats")}
          >
            Statistiques
          </div>
        </>
      ) : null}
      {user && user.username === "admin" ? (
        <div className={styles.choiceStyle} onClick={() => navigate("/users")}>
          Users
        </div>
      ) : null}
      <div className={styles.choiceStyle} onClick={() => navigate("/logout")}>
        Se déconnecter
      </div>
    </div>
  );
};

export default MainMenu;
