import _ from "underts";
interface PartialUnderlineProps {
  string: string;
  substr: string;
}

const PartialUnderline = (props: PartialUnderlineProps) => {
  const { string, substr } = props;
  const re = new RegExp(substr, "i");
  if (!string) {
    return <span></span>;
  }
  const tempArray = string.split(re);
  const origSubstr = string.match(re) ? _.first(string.match(re)!) : null; //on récupère la substr sous sa forme originale (casse) au lieu de ce qui est recherché
  if (!origSubstr) {
    return <span>{string}</span>;
  }
  const beforeString = tempArray[0];
  const afterString = tempArray[1];
  const underString = (
    <span style={{ textDecoration: "underline" }}>{origSubstr}</span>
  );
  return (
    <span key={"PartialUnderline" + string}>
      {beforeString}
      {underString}
      {afterString}
    </span>
  );
};
export default PartialUnderline;
