import { FamilleGridKeys } from "../../patients/validators/individualValidators";
import InputIcon from "../../main/components/InputIcon.tsx";
import { FaLongArrowAltDown } from "react-icons/fa";
import Button from "../../main/components/Button.tsx";
import styles from "./FamilleGridRow.module.css";
import Input from "../../main/components/Input.tsx";
import { LocalExistingPatient, LocalNewPatient } from "./FamilleGridPanel.tsx";

interface FamilleGridRowProps {
  familyRank: number;
  isValid: { [key in FamilleGridKeys]?: boolean };
  onMembreChange: (carac: FamilleGridKeys, value: string) => void;
  onCopyDown: (carac: FamilleGridKeys) => void;
  onRemoveMembre: () => void;
}

const FamilleGridRow = (
  props: FamilleGridRowProps & {
    [K in FamilleGridKeys]?: (LocalNewPatient | LocalExistingPatient)[K];
  }
) => {
  const { isValid } = props;
  console.log("isValid", isValid);
  const { nom, prenom, ddn, portable, email } = props;
  const { onMembreChange, onCopyDown, onRemoveMembre } = props;
  return (
    <tr>
      <td className={styles.col3}></td>
      <td>
        <InputIcon
          value={nom || ""}
          onChange={(value) => onMembreChange("nom", value)}
          tooltip="Recopier vers le bas"
          onClick={() => onCopyDown("nom")}
          showIcon={!!nom}
          data-testid="nomInput"
          iconTestId="nomCopyDown"
          isValid={!!nom && nom.length > 1}
        >
          <FaLongArrowAltDown size="1.2em" />
        </InputIcon>
      </td>
      <td>
        <Input
          value={prenom || ""}
          onChange={(value) => onMembreChange("prenom", value)}
          data-testid="prenomInput"
          isValid={isValid["prenom"]}
          isTouched={!!prenom}
        />
      </td>
      <td className={styles.col10}>
        <Input
          value={ddn || ""}
          onChange={(value) => onMembreChange("ddn", value)}
          isValid={isValid["ddn"]}
          data-testid="ddnInput"
          isTouched={!!ddn}
        />
      </td>
      <td className={styles.col15}>
        <InputIcon
          value={portable || ""}
          onChange={(value) => onMembreChange("portable", value)}
          tooltip="Recopier vers le bas"
          onClick={() => onCopyDown("portable")}
          isValid={isValid["portable"]}
          showIcon={isValid["portable"]}
          data-testid={"portableInput"}
          iconTestId={"portableCopyDown"}
        >
          <FaLongArrowAltDown size="1.2em" />
        </InputIcon>
      </td>
      <td className={styles.col15}>
        <InputIcon
          value={email || ""}
          onChange={(value) => onMembreChange("email", value)}
          tooltip="Recopier vers le bas"
          onClick={() => onCopyDown("email")}
          isValid={isValid["email"]}
          showIcon={isValid["email"]}
          data-testid={"emailInput"}
          iconTestId={"emailCopyDown"}
        >
          <FaLongArrowAltDown size="1.2em" />
        </InputIcon>
      </td>
      <td>
        <Button
          title="Retirer de la famille"
          onClick={() => onRemoveMembre()}
          style="danger"
          size="small"
          data-testid={"removeMembreButton"}
        />
      </td>
    </tr>
  );
};

export default FamilleGridRow;
