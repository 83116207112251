const countCharsAccordingToWeight = function (msg: string) {
  if (!msg) {
    return 0;
  }
  return sum(msg.split("").map(weightChar));
};
const getremainingChars = function (limit: number, msg: string) {
  return limit - countCharsAccordingToWeight(msg);
};
function isBaseChar(char: string) {
  return /[a-zA-Z]/.test(char);
}
function sum(arr: number[]) {
  return arr.reduce((a, b) => a + b);
}
function weightChar(a: string) {
  return isBaseChar(a) ? 1 : 2;
}
const remainingChars = getremainingChars.bind(null, 160);
const remainingSenderChars = getremainingChars.bind(null, 11);
export { remainingChars, remainingSenderChars };
