;
import { HoraireInDB, Jours } from "cadendar-shared";
import {formaters} from "vincent-utils";

export interface HoraireColPropDisplay {
  plages: HoraireInDB["horaire"][Jours];
  jour: Jours;
}

const HoraireColDisplay = (props: HoraireColPropDisplay) => {
  const isOpen = !!props.plages?.length && props.plages.length > 0;
  return (
    <div className={"horaires_col" + (isOpen ? "_ouvert" : "_ferme")}>
      {isOpen ? (
        <>
          {props.plages!.map((plage, idx) => (
            <div
              className="plage"
              key={
                formaters
                  .getHeureEnMinutesFromHeureMinuteObject(plage.start)
                  .toString() +
                formaters
                  .getHeureEnMinutesFromHeureMinuteObject(plage.end)
                  .toString()
              }
            >
              De
              <div
                className="plageStart"
                data-test={"plageStart" + props.jour + idx}
              >
                {formaters.formatHMObjectForDisplay(plage.start)}
              </div>
              A
              <div
                className="plageEnd"
                data-test={"plageEnd" + props.jour + idx}
              >
                {formaters.formatHMObjectForDisplay(plage.end)}
              </div>
            </div>
          ))}
        </>
      ) : (
        <div>Fermé</div>
      )}
    </div>
  );
};

export default HoraireColDisplay;
