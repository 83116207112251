import FamilleGridPanel from "../components/FamilleGridPanel";
import SimpleWindow from "../../../components/SimpleWindow/SimpleWindow";

interface FamilleGridContainerInWindowProps {
  onClose: () => void;
}

const FamilleGridContainerInWindow = (
  props: FamilleGridContainerInWindowProps
) => {
  return (
    <SimpleWindow
      title="Edition Famille"
      isVisible={true}
      initialX={5}
      initialY={10}
      onClose={props.onClose}
    >
      <FamilleGridPanel isFicheAppelMode={false} onClose={props.onClose} />
    </SimpleWindow>
  );
};

export default FamilleGridContainerInWindow;
