const getPlural = function (number: number) {
  "use strict";
  number = Math.floor(number);
  if (number === 1) {
    return "";
  } else if (number > 1) {
    return "s";
  }
};

const getElapsedTimeInWords = function (diffEnSecondes: number) {
  "use strict";
  let returnString = "";
  if (diffEnSecondes < 20) {
    return "Il y a quelques secondes";
  }

  if (diffEnSecondes < 60) {
    return "Il y a moins d'une minute";
  }

  const minutes = Math.round(diffEnSecondes / 60);

  if (minutes < 60) {
    returnString =
      "Il y a " + Math.round(minutes) + " minute" + getPlural(minutes);
    return returnString;
  }

  const heures = Math.round(diffEnSecondes / 3600);

  if (heures < 24) {
    returnString =
      "Il y a " + Math.round(heures) + " heure" + getPlural(heures);
    return returnString;
  }

  const jours = Math.round(diffEnSecondes / (3600 * 24));

  if (jours < 5) {
    returnString = "Il y a " + Math.round(jours) + " jour" + getPlural(jours);
    return returnString;
  }

  const diffEnMs = diffEnSecondes * 1000;
  const date = new Date();
  date.setTime(date.getTime() - diffEnMs);
  return date.toLocaleString();
};

export default getElapsedTimeInWords;
