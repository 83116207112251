import { useAtomValue } from "jotai";
import {
  LoggedUserId,
  SeletectedDoctorState,
} from "../../main/state/globalState";
import { Collections } from "cadendar-shared";
import { trpc } from "../../main/components/MainContainer";

export default function useOwner() {
  const userId = useAtomValue(LoggedUserId);

  // const userFetchResult = useQuery(
  //   ["user", userId],
  //   () => fetchUser(host, token, userId!),
  //   { enabled: !!token && !!userId }
  // );
  const { data: user } = trpc.user.getById.useQuery(userId!, {
    enabled: !!userId,
  });
  const selectedDoctorId = useAtomValue(SeletectedDoctorState);
  return getOwnerFromUser(user || null, selectedDoctorId);
}

export const getOwnerFromUser = (
  user: Collections.User | null,
  selectedDoctorId: string | null
) => {
  if (!user) {
    return null;
  }
  if (user.tag === "secretary") {
    return selectedDoctorId;
  }
  if (user.tag === "doctor") {
    return user._id;
  }
  return null;
};
