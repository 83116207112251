import styles from "./PageChooser.module.css";
import IconButton from "../../main/components/IconButton.tsx";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

type PageChooserProps =
  | WithPrevPageProps
  | WithoutPrevPageProps
  | WithouNextPageProps;

interface WithPrevPageProps {
  onPrevPage: () => void;
  onNextPage: () => void;
  prevPageEnabled: true;
  nextPageEnabled: true;
  prevPageText: string;
  nextPageText: string;
}

interface WithoutPrevPageProps {
  onNextPage: () => void;
  prevPageEnabled: false;
  nextPageEnabled: true;
  nextPageText: string;
}

interface WithouNextPageProps {
  onPrevPage: () => void;
  prevPageEnabled: true;
  prevPageText: string;
  nextPageEnabled: false;
}
const PageChooser = (props: PageChooserProps) => {
  return (
    <div className={styles.spacer}>
      {props.prevPageEnabled ? (
        <IconButton
          onClick={props.onPrevPage}
          position="left"
          title={props.prevPageText}
        >
          <FaArrowLeft />
        </IconButton>
      ) : null}
      {props.nextPageEnabled ? (
        <IconButton
          title={props.nextPageText}
          onClick={props.onNextPage}
          position="right"
        >
          <FaArrowRight />
        </IconButton>
      ) : null}
    </div>
  );
};

export default PageChooser;
