import {formaters} from "vincent-utils";
import _ from "underts";
export default function(
  plage: {
    start: { heure: number; minute: number };
    end: { heure: number; minute: number };
  },
  dureeMiniRdv: number
) {
  const startInMinutes = formaters.getHeureEnMinutesFromHeureMinuteObject(
    plage.start
  );
  const endInMinutes = formaters.getHeureEnMinutesFromHeureMinuteObject(
    plage.end
  );
  return _.compact(
    _.range(startInMinutes, endInMinutes, dureeMiniRdv).map(
      formaters.getHeureMinuteObjectFromHeureEnMinutes
    )
  );
}
