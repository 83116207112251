import * as React from "react";
import Bold from "./Bold.tsx";
import PartialUnderline from "./PartialUnderline.tsx";
import { formaters } from "vincent-utils";

interface LookedUpPatientProps {
  patient: {
    nom: string;
    prenom: string;
    ddn: string;
  };
  lookup: string;
}

const LookedUpPatient = (props: LookedUpPatientProps) => {
  const { patient, lookup } = props;
  const bold = (comp: React.ReactElement) => <Bold component={comp} />;
  const underline = (text: string, lookup: string) => (
    <PartialUnderline string={text} substr={lookup} />
  );
  const words: [string, string, string | undefined] = [
    formaters.capitaliseFirstLetter(patient.nom),
    formaters.capitaliseFirstLetter(patient.prenom),
    patient.ddn,
  ];
  //const underlined = words.map(underline);
  const nom = bold(underline(words[0], lookup));
  const prenom = words[1];
  const ddn = words[2];
  return (
    <>
      {nom} {prenom} {ddn}
    </>
  );
};

export default LookedUpPatient;
