import styles from "./MonthChooser.module.css";
export interface MonthChooserProps {
  monthYearString: string;
  onPrevMonth: () => void;
  onNextMonth: () => void;
}

const MonthChooser = (props: MonthChooserProps) => {
  const { monthYearString, onNextMonth, onPrevMonth } = props;
  return (
    <div id="minicalendarheader" className={styles.header}>
      <div
        id="left_arrow"
        onClick={onPrevMonth}
        className={styles.cursorPointer}
      >
        &#9664;
      </div>
      <div id="minicalendarmonthyear">{monthYearString}</div>
      <span
        id="right_arrow"
        onClick={onNextMonth}
        className={styles.cursorPointer}
      >
        &#9654;
      </span>
    </div>
  );
};

export default MonthChooser;
