import AgendaWeekChooser, { AgendaWeekChooserProps } from "./AgendaWeekChooser";
import AgendaContainer from "../../agenda/containers/AgendaContainer";
import MiniCalendarContainer from "../../miniCalendar/containers/MiniCalendarContainer";
import MessagesMainComp from "../../messages/components/MessagesMainComp";
import styles from "./CentralComp.module.css";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { constrainedHeightAtom } from "../../main/state/globalState.ts";

type CentralCompProps = AgendaWeekChooserProps;

const CentralComp = (props: CentralCompProps) => {
  const { year, weekNumber, onPrevWeek, onNextWeek, onThisWeek } = props;
  const constrainedHeight = useAtomValue(constrainedHeightAtom);
  const style =
    constrainedHeight && constrainedHeight > 400
      ? { height: constrainedHeight }
      : { height: 400 };
  return (
    <>
      <AgendaWeekChooser
        year={year}
        weekNumber={weekNumber}
        onNextWeek={onNextWeek}
        onPrevWeek={onPrevWeek}
        onThisWeek={onThisWeek}
        user={props.user}
      />
      <div className={styles.agenda} draggable="false">
        <div
          className={classNames("agendacolumn", styles.leftColumn)}
          style={style}
        >
          <MessagesMainComp />
          <MiniCalendarContainer />
        </div>
        <AgendaContainer />
      </div>
    </>
  );
};

export default CentralComp;
