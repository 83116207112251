import React, { useState } from "react";
import { formaters } from "vincent-utils";
import RdvStars from "./RdvStars";
import { useSelectedPatientIds } from "../../main/state/globalState";
import { AgendaCase } from "cadendar-shared";
import { FilledRdvCase } from "cadendar-shared";
import { Transforms } from "cadendar-shared";
import styles from "./RdvCase.module.css";
import classNames from "classnames";
import RdvCaseIcons from "./RdvCaseIcons.tsx";

const getDiffInHours = (a: Date, b: Date) => {
  return (a.getTime() - b.getTime()) / 1000 / 3600;
};

const rdvColors = {
  1: "#71FF71",
  2: "yellow",
  5: "skyblue",
  10: "mediumseagreen",
  11: "red",
  12: "red",
  13: "#B870DB",
  14: "pink",
  15: "#5c5c5c",
  18: "#B870DB",
  21: "red",
  22: "red",
  25: "#d0cbff",
  26: "#71FF71",
  28: "#2d187a",
} as { [type: string]: string };

const fontColor = {
  15: "white",
  28: "white",
  // 29: "white",
} as { [type: number]: string };

const getRdvColor = (rdv: RdvCaseProps["rdv"]) => {
  if (rdv.tag === "closed") {
    return "rgb(92,92,92)";
  }
  if (rdv.tag === "empty") {
    return "#f1f1d9";
  }
  if (rdv.tag === "closedByHoraire") {
    //return "#5c5c5c";
    return "rgb(192,192,192)";
  }
  const color = rdvColors[rdv.type];
  return color || "var(--color-beige)";
};

const getBorderColor = (rdv: RdvCaseProps["rdv"]) => {
  const color = getRdvColor(rdv);
  return `color-mix(in srgb, ${color}, black 10%)`;
};

const getFontColor = (rdv: RdvCaseProps["rdv"]) => {
  return ("type" in rdv && fontColor[rdv.type]) || "#333";
};

const getConfirmationStyle = (rdv: RdvCaseProps["rdv"], now: Date) => {
  if (rdv.tag !== "rdv") {
    return "empty";
  }
  if (rdv.confirmed) {
    return "confirmed";
  }
  if (rdv.confirmationSms && !rdv.confirmationSentAt) {
    return "programmed";
  }
  if (
    rdv.confirmationSms &&
    rdv.confirmationSentAt &&
    getDiffInHours(now, rdv.confirmationSentAt) < 12
  ) {
    return "asked";
  }
  if (
    rdv.confirmationSms &&
    rdv.confirmationSentAt &&
    getDiffInHours(now, rdv.confirmationSentAt) > 12
  ) {
    return "notConfirmed";
  }
  return "empty";
};

export interface RdvCaseProps {
  rdv: AgendaCase & {
    stars?: number;
    familyColor?: string;
  };

  now: Date;
  heightMultiplier: number;
  onMouseEnter: (
    event: React.MouseEvent<HTMLDivElement>,
    rdv: FilledRdvCase | null
  ) => void;
  onMouseLeave: (event: React.MouseEvent) => void;
  onCloseCrossClick: (_id: string) => void;
  onConfirmClick: (_id: string) => void;
  onCaseClick: (rdv: AgendaCase) => void;
}

const RdvCase = (props: RdvCaseProps) => {
  //trace(true);

  const { rdv } = props;
  const { heightMultiplier } = props;

  const nom = "nom" in rdv && rdv.nom;
  const prenom = "prenom" in rdv && rdv.prenom;
  const objet = "objet" in rdv && rdv.objet;
  const { onCaseClick, onCloseCrossClick, onConfirmClick } = props;
  const { onMouseEnter, onMouseLeave } = props;
  const { stars } = rdv;
  const { now } = props;
  const hmString = formaters.formatHMObjectForDisplay(rdv);
  const isZeroMinute = (hmString: string) => hmString.split(":")[1] === "00";

  const [_, { selectPatientId }] = useSelectedPatientIds();

  //utile pour l'effet de hover sur les cases vides
  const [isHovered, setHover] = useState(false);

  const showCross =
    rdv &&
    "type" in rdv &&
    (rdv.type === 21 || rdv.type === 12 || rdv.type === 28 || rdv.type === 15);
  const handleCrossClick = () => {
    "_id" in rdv && onCloseCrossClick(rdv._id);
  };
  const confirmStyle = getConfirmationStyle(rdv, now);

  const handleConfirmClick = () => {
    "_id" in rdv && onConfirmClick(rdv._id);
  };
  const background = rdv.familyColor
    ? "linear-gradient(90deg, " +
      rdv.familyColor +
      " 2%, " +
      getRdvColor(rdv) +
      " 2%)"
    : getRdvColor(rdv);

  // const backgroundImage =
  //   rdv.tag === "closed"
  //     ? "repeating-linear-gradient(45deg,transparent,transparent 10px,white 10px,white 12px, transparent 12px)"
  //     : null;
  const color = getFontColor(rdv);

  const style = {
    height: `calc(${heightMultiplier * rdv.duree}em - 1px)`, //on retire 1px pour prendre en compte les margin-top
    background,
    // ...(backgroundImage ? { backgroundImage } : null),
    color,
    borderColor: getBorderColor(rdv),
  };

  const uniqueID = Transforms.rdvs.getUniqueId(rdv);
  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (rdv.tag === "empty") {
      //utile pour l'effet de hover sur les cases vides
      setHover(true);
      return;
    }
    //rdv fermé
    if (rdv.tag === "rdv" && !rdv.patient_id) {
      return;
    }
    if (
      rdv.tag === "fake" ||
      rdv.tag === "closed" ||
      rdv.tag === "closedByHoraire"
    ) {
      return;
    }
    event.persist();
    onMouseEnter(event, rdv);
  };
  const handleMouseLeave = (event: React.MouseEvent) => {
    // if (rdv.patient === null && !rdv.fake) {
    //   return;
    // }
    if (
      event.relatedTarget &&
      "id" in event.relatedTarget &&
      (event.relatedTarget as HTMLElement).id === "rdvinfopopup"
    ) {
      return;
    }
    setHover(false);
    onMouseLeave(event);
  };

  const handleClick = () => {
    if (Transforms.rdvs.isFakeCase(rdv)) {
      //est-ce que ça ne devrait pas plutôt être géré au niveau du container ?
      return;
    }
    if (Transforms.rdvs.isClosedRdvCase(rdv)) {
      return;
    }
    if (rdv.tag === "rdv" && rdv.patient_id) {
      selectPatientId(rdv.patient_id);
    }
    onCaseClick(rdv);
  };
  return (
    <div
      style={style}
      className={classNames({
        [styles.case_rdv_closed]: rdv.tag === "closed",
        [styles.case_rdv_]: rdv.tag !== "empty" && rdv.tag !== "closed",
        [styles.cursor_pointer]: rdv.tag !== "closed",
        [styles.spacer]: true,
      })}
      id={uniqueID}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid={"RdvCase"}
      data-type={"type" in rdv && rdv.type}
    >
      {nom || objet ? (
        <div>
          {nom ? (
            <div
              className={classNames(
                "case_rdv_nom_patient",
                styles.case_rdv_nom_patient
              )}
            >
              {nom && nom.toUpperCase()}{" "}
              {formaters.capitaliseFirstLetter(prenom || "")}
            </div>
          ) : null}

          <div className="case_rdv_objet">{objet}</div>
        </div>
      ) : (
        <RdvStars stars={stars} />
      )}
      <RdvCaseIcons
        showCross={showCross}
        handleCrossClick={handleCrossClick}
        handleConfirmClick={handleConfirmClick}
        confirmationIconStyle={confirmStyle}
      />
    </div>
  );
};

export default RdvCase;
