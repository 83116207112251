import FirstPage from "./FirstPage.tsx";
import { useEffect, useState } from "react";
import useFicheAppel from "../hooks/useFicheAppel.tsx";
import AdressePanel from "./AdressePanel.tsx";
import _ from "underts";
import MotifsPanel from "./MotifsPanel.tsx";
import RdvCandidatsPanel from "./RdvCandidatsPanel.tsx";
import useRdvProposal from "../hooks/useRdvProposal.ts";
import EditPatient from "../../patients/components/EditPatient.tsx";
import FicheAppelNewPatientCoords from "./FicheAppelNewPatientCoords.tsx";
import FamilleGridPanel from "../../famille/components/FamilleGridPanel.tsx";
import useRefferedResponded from "../hooks/useRefferedResponded.ts";
import useTempPatient from "../hooks/useTempPatient.ts";

type PageType =
  | 1
  | "adresseur"
  | "motif"
  | "listCandidats"
  | "forcedListCandidats"
  | "newPatientCoords"
  | "editFamily";

interface FichePageLoaderProps {
  onAptFilterClick: () => void;
}

const FichePageLoader = (props: FichePageLoaderProps) => {
  const [pageStack, setPageStack] = useState<PageType[]>([1]);
  const { hasMotifs } = useRdvProposal();
  const page = _.last(pageStack);
  const { isFamilleChecked } = useTempPatient();
  const addToStack = (page: PageType) => {
    setPageStack((pageStack) => [...pageStack, page]);
  };
  const popFromStack = () => {
    setPageStack((pageStack) => pageStack.slice(0, -1));
  };
  const { dejaVenu } = useFicheAppel();
  console.log("dejaVenu", dejaVenu);
  useEffect(() => {
    if (hasMotifs && pageStack.length === 1) {
      addToStack("forcedListCandidats");
    }
  }, []);

  const handleNextPage = () => {
    if (dejaVenu) {
      addToStack("motif");
      return;
    }
    addToStack("adresseur");
  };
  switch (page) {
    case undefined:
      return null;
    case 1:
      return <FirstPage onNextPage={handleNextPage} />;
    case "adresseur":
      return (
        <AdressePanel
          onNextPage={() => {
            if (isFamilleChecked) {
              addToStack("editFamily");
              return;
            }
            addToStack("newPatientCoords");
          }}
          onPrevPage={popFromStack}
        />
      );
    case "newPatientCoords":
      return (
        <FicheAppelNewPatientCoords
          onNextPage={() => addToStack("motif")}
          onPrevPage={popFromStack}
        />
      );
    case "motif":
      return (
        <MotifsPanel
          onNextPage={() => addToStack("listCandidats")}
          onPrevPage={popFromStack}
        />
      );
    case "listCandidats":
      return (
        <RdvCandidatsPanel
          onPrevPage={popFromStack}
          showPrevPageButton={true}
          onFilterClick={props.onAptFilterClick}
        />
      );
    case "editFamily":
      return (
        <FamilleGridPanel
          isFicheAppelMode={true}
          onPrevPage={popFromStack}
          onNextPage={() => addToStack("motif")}
          onClose={() => addToStack("motif")}
        />
      );
    case "forcedListCandidats":
      return (
        <RdvCandidatsPanel
          showPrevPageButton={false}
          onFilterClick={props.onAptFilterClick}
        />
      );
  }
  return null;
};

export default FichePageLoader;
