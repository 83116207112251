import React, { useLayoutEffect, useState } from "react";
import AgendaColumn, { AgendaColumnProps } from "./AgendaColumn";
import RdvInfoPopup from "./RdvInfoPopup";
import ColumnInfoPopup, { ColumnInfoPopupProps } from "./ColumnInfoPopup";
import { DayRdvsProps } from "./DayRdvs";
import { processPopupCaracs } from "../filterTransforms/processPopupCaracs";
import { StrategorFilters } from "cadendar-shared";
import { FilledRdvCase } from "cadendar-shared";
import { useSetAtom } from "jotai";
import { constrainedHeightAtom } from "../../main/state/globalState.ts";

type AgendaColMainCompProps = {
  weekRdvsArray: DayRdvsProps["rdvs"][];
  débutHeureAffichage: number;
  finHeureAffichage: number;
  duréeMiniRdv: number;
} & Omit<
  AgendaColumnProps,
  "rdvs" | "columnNumber" | "onMouseEnter" | "onMouseLeave" | "heightMultiplier"
>;

const isHTMLElement = (target: unknown): target is HTMLElement => {
  return target instanceof HTMLElement;
};

const handleRdvCaseMouseEnter = (target: HTMLElement, rdv: FilledRdvCase) => {
  const caracs = {
    rdv,
    left: target.getBoundingClientRect().left,
    top: target.getBoundingClientRect().top + window.pageYOffset,
    height: target.offsetHeight,
    width: target.offsetWidth,
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    scrollY: window.scrollY,
  };
  if (
    typeof caracs.rdv === "object" &&
    caracs.rdv &&
    "_id" in caracs.rdv &&
    "nom" in caracs.rdv
  ) {
    return processPopupCaracs(caracs);
  }
};

const handleColumnHeaderMouseEnter = (
  event: React.MouseEvent<HTMLDivElement>,
  target: HTMLElement,
  column: number
) => {
  return {
    left: event.pageX,
    top: event.pageY,
    height: target.offsetHeight,
    width: target.offsetWidth,
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    scrollY: window.scrollY,
    column,
  };
};

const AgendaColMainComp = (props: AgendaColMainCompProps) => {
  const [rdvInfoPopupCaracs, setRdvInfoPopupCaracs] = useState<ReturnType<
    typeof handleRdvCaseMouseEnter
  > | null>(null);
  const [columnInfoPopupCaracs, setColumnInfoPopupCaracs] = useState<ReturnType<
    typeof handleColumnHeaderMouseEnter
  > | null>(null);

  const { weekRdvsArray, ...rest } = props;
  const setConstrainedHeight = useSetAtom(constrainedHeightAtom);
  useLayoutEffect(() => {
    const heightHeader = document
      .querySelector(".headerCol")
      ?.getBoundingClientRect().height;
    const heightSpacer = document.querySelector(".spacerCol")?.clientHeight;
    if (heightHeader && heightSpacer) {
      const height = heightHeader + heightSpacer;
      setConstrainedHeight(height);
    }
  });

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement>,
    rdv: FilledRdvCase | null,
    column?: number
  ) => {
    const target = event.target;
    if (!isHTMLElement(target)) {
      return;
    }
    if (rdv) {
      const caracs = handleRdvCaseMouseEnter(target, rdv);
      if (caracs) {
        setRdvInfoPopupCaracs(caracs);
      }
      return;
    }
    if (column) {
      const caracs = handleColumnHeaderMouseEnter(event, target, column);
      setColumnInfoPopupCaracs(caracs);
      return;
    }
  };

  const handleMouseLeave = () => {
    setRdvInfoPopupCaracs(null);
    setColumnInfoPopupCaracs(null);
  };

  const dayComps = weekRdvsArray.map((rdvs, idx) => (
    <AgendaColumn
      rdvs={rdvs}
      columnNumber={idx}
      {...rest}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={"agendaColKey_" + idx}
    />
  ));

  const showColumnInfoPopup = !!columnInfoPopupCaracs;
  const showRdvInfoPopup = !!rdvInfoPopupCaracs;
  const fillRatios = weekRdvsArray.map(StrategorFilters.getDayFillRatio);
  const rentabilityRatios = weekRdvsArray.map(
    StrategorFilters.getRentableRatio
  );
  return (
    <>
      {dayComps}
      {showRdvInfoPopup ? <RdvInfoPopup {...rdvInfoPopupCaracs} /> : null}
    </>
  );
};

export default AgendaColMainComp;
