;
import Select from "../../../components/Select";

interface ContrainteOpenLineProps<TPeriodTypes extends readonly string[]> {
  type: "open";
  isChecked: boolean;
  textComp: JSX.Element;
  periodTypes: TPeriodTypes;
  firstTimes: string[];
  secondTimes: string[];
  showTimeContrainteTypeSelector?: boolean;
  showFirstTimeSelector?: boolean;
  showSecondTimeSelector?: boolean;
  timeContrainteSelected: string;
  firstTimeSelectorSelected: string;
  secondTimeSelectorSelected: string;
  onContrainteTypeSelect: (type: TPeriodTypes[number]) => void;
  onFirstTimeSelect: (value: string) => void;
  onSecondTimeSelect: (value: string) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ContrainteClosedLineProps {
  type: "closed";
  isChecked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  textComp: JSX.Element;
  key: string;
}

type ContrainteLineProps<TPeriodTypes extends readonly string[]> =
  | ContrainteOpenLineProps<TPeriodTypes>
  | ContrainteClosedLineProps;

const ContrainteLine = <TPeriodTypes extends readonly string[]>(
  props: ContrainteLineProps<TPeriodTypes>
) => {
  const { isChecked } = props;
  const { textComp } = props;
  const { onChange } = props;
  const timeContrainteComp =
    props.type === "open" && props.showTimeContrainteTypeSelector ? (
      <Select
        labels={props.periodTypes}
        onSelect={props.onContrainteTypeSelect}
        selected={props.timeContrainteSelected}
        className="form-control"
      />
    ) : (
      <span></span>
    );
  const firstTimeSelectorComp =
    props.type === "open" && props.showFirstTimeSelector ? (
      <Select
        labels={props.firstTimes}
        onSelect={props.onFirstTimeSelect}
        selected={props.firstTimeSelectorSelected}
        className="form-control"
      />
    ) : (
      <span></span>
    );
  const secondTimeSelectorComp =
    props.type === "open" && props.showSecondTimeSelector ? (
      <span>
        et
        <Select
          labels={props.secondTimes}
          onSelect={props.onSecondTimeSelect}
          selected={props.secondTimeSelectorSelected}
          className="form-control"
        />
      </span>
    ) : (
      <span></span>
    );
  return (
    <li className="list-group-item">
      <div className="row">
        <form className="form-inline" role="form">
          <div className="checkbox">
            <label className="possible">
              <input type="checkbox" checked={isChecked} onChange={onChange} />
              {textComp}
            </label>
          </div>
          {timeContrainteComp} {firstTimeSelectorComp} {secondTimeSelectorComp}
        </form>
      </div>
    </li>
  );
};

export default ContrainteLine;
