import MiniCalendar from "../components/MiniCalendar";
import { selectedWeekAtom } from "../../main/state/globalState";
import { useAtom } from "jotai";
import {formaters} from "vincent-utils";

const MiniCalendarContainer2 = () => {
  const [weekSelected, setWeekSelected] = useAtom(selectedWeekAtom);
  const today = formaters.filterDateTimeForPureDate(new Date());
  const onSelectDay = (day: Date) =>
    setWeekSelected(formaters.getDateDuLundiFromDateTime(day));
  return (
    <MiniCalendar
      weekSelected={weekSelected}
      today={today}
      onSelectDay={onSelectDay}
    />
  );
};

export default () => <MiniCalendarContainer2 />;
