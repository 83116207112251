class Beep {
  sound;
  constructor() {
    this.sound = new Audio("sonar.mp3");
    console.log("this sound", this.sound);
  }
  play() {
    console.log("play");
    this.sound.play();
  }
  doublePlay() {
    console.log("play");
    this.sound.play();
    setTimeout(() => this.play(), 1200);
  }
}

export default new Beep();
