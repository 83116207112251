import moment from "moment";
import _ from "underts";

const getNumberOfDaysForFebruary = function (year: number) {
  const date = new Date(year, 1, 28);
  date.setDate(date.getDate() + 1);
  if (date.getMonth() === 2) {
    return 28;
  } else {
    return 29;
  }
};

const getFirstDaysOfNextMonth = function (daysToGet: number) {
  const daysArray = [];
  for (let i = 1; i < daysToGet + 1; i++) {
    daysArray.push(i);
  }
  return daysArray;
};

const getNumberOfDays = function (monthNumber: number, year: number) {
  "use strict";
  const NUMBEROFDAYSARRAY = [
    31,
    getNumberOfDaysForFebruary(year),
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  return NUMBEROFDAYSARRAY[monthNumber];
};

const getFirstDayOfTheMonthDay = function (month: number, year: number) {
  return new Date(year, month, 1).getDay();
};

const getPreviousMonthNumberOfDays = function (
  currentMonthNumber: number,
  year: number
) {
  const date = new Date(year, currentMonthNumber, 1);
  date.setMonth(date.getMonth() - 1);
  const previousMonthNumber = date.getMonth();
  const previousMonthYear = date.getFullYear();
  return getNumberOfDays(previousMonthNumber, previousMonthYear);
};

const getPreviousMonthLastDays = function (
  currentMonthNumber: number,
  currentYear: number
) {
  "use strict";
  const firstDay = getFirstDayOfTheMonthDay(currentMonthNumber, currentYear);
  const previousMonthNumberOfDays = getPreviousMonthNumberOfDays(
    currentMonthNumber,
    currentYear
  );
  const lastDaysArray = [];
  let j = previousMonthNumberOfDays;
  for (let i = 0; i < firstDay; i++) {
    lastDaysArray.unshift(j);
    j = j - 1;
  }

  return lastDaysArray;
};

export default function (
  month: number,
  year: number,
  selectedWeek: Date,
  today: Date
) {
  const numberOfDays = getNumberOfDays(month, year),
    arrayOfArrays = [],
    prevMonth = moment({ year: year, month: month })
      .subtract(1, "months")
      .get("month"),
    nextMonth = moment({ year: year, month: month })
      .add(1, "months")
      .get("month");

  if (!selectedWeek) {
    return [];
  }

  const normalDaysArray = _.range(1, numberOfDays + 1).reduce(
    function (resultArray, dayNumber) {
      resultArray.push({
        day: dayNumber,
        type: "normal_day",
        month: month,
        year: year,
      });
      return resultArray;
    },
    [] as {
      day: number;
      type: "normal_day" | "other_month_day" | "displayed_week" | "today_day";
      month: number;
      year: number;
    }[]
  );
  const previousMonthLastDaysArray = getPreviousMonthLastDays(month, year)
    .reverse()
    .map(function (value) {
      return {
        day: value,
        type: "other_month_day" as const,
        month: prevMonth,
        year: year,
      };
    });
  const uniqueArray = previousMonthLastDaysArray.reduce(function (
    resultArray,
    value
  ) {
    resultArray.unshift(value);
    return resultArray;
  },
  normalDaysArray);
  while (uniqueArray.length > 0) {
    arrayOfArrays.push(uniqueArray.splice(0, 7));
  }
  const lastWeek = _.last(arrayOfArrays)!;
  const numberOfMissingDays = 7 - lastWeek.length;
  const lastWeek2 = getFirstDaysOfNextMonth(numberOfMissingDays).reduce(
    function (resultArray, value) {
      return [
        ...resultArray,
        {
          day: value,
          type: "other_month_day" as const,
          month: nextMonth,
          year: year,
        },
      ];
    },
    lastWeek
  );
  arrayOfArrays.pop();
  arrayOfArrays.push(lastWeek2);
  const dayOfToday = today.getDate();
  const monthOfToday = today.getMonth();
  const yearOfToday = today.getFullYear();

  return arrayOfArrays.map(function (tableau) {
    const premierJourDeLaSemaine = tableau[1],
      week = new Date(
        premierJourDeLaSemaine.year,
        premierJourDeLaSemaine.month,
        premierJourDeLaSemaine.day
      );
    if (week.getTime() === selectedWeek.getTime()) {
      tableau = tableau.map(function (obj) {
        return { ...obj, type: "displayed_week" as const };
      });
    }

    return tableau.map(function (obj) {
      if (
        obj.day === dayOfToday &&
        obj.month === monthOfToday &&
        obj.year === yearOfToday
      ) {
        return { ...obj, type: "today_day" as const };
      }
      return obj;
    });
  });
}
