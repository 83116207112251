import React, { useEffect, useState } from "react";
import styles from "./SimpleWindow.module.css";
import { RxCross2 } from "react-icons/rx";

interface SimpleWindowProps {
  isVisible: boolean;
  title: string;
  initialX?: number;
  initialY?: number;
  children: React.ReactNode;
  onClose?: () => void;
}
const SimpleWindow = (props: SimpleWindowProps) => {
  const windowWidth = document.documentElement.clientWidth;
  const windowHeight = document.documentElement.clientHeight;
  const windowWidthHalf = windowWidth / 2 - 500;
  const windowHeightHalf = windowHeight / 2 - 300;
  const initialX = props.initialX != null ? props.initialX : windowWidthHalf;
  const initialY = props.initialY != null ? props.initialY : windowHeightHalf;
  const [top, setTop] = useState(initialY);
  const [left, setLeft] = useState(initialX);
  const [isDragging, setIsDragging] = useState(false);
  const [shiftX, setShiftX] = useState(0);
  const [shiftY, setShiftY] = useState(0);
  const show = props.isVisible;
  useEffect(() => {
    if (!show) {
      setTop(initialY);
      setLeft(initialX);
      setIsDragging(false);
    }
  }, [show]);

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseDown = (e: React.MouseEvent) => {
    setShiftX(left - e.screenX);
    setShiftY(top - e.screenY);
    setIsDragging(true);
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      setLeft(e.screenX + shiftX);
      setTop(e.screenY + shiftY);
    }
  };

  const onMouseLeave = () => {
    setIsDragging(false);
  };
  return (
    <div
      id="popup_outer"
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      className={styles.popup_outer}
      style={{
        position: "fixed",
        top: top + "px",
        left: left + "px",
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        id="popup_visible"
        className={styles.popup_visible}
        style={{ display: props.isVisible ? "inherit" : "none" }}
      >
        <div className={styles.popupTitle}>
          {props.title}
          <div
            className={styles.icon}
            onClick={props.onClose}
            data-testid="simpleWindowCloseCross"
          >
            <RxCross2 size="1.5rem" />
          </div>
        </div>

        <div id="popup_inner" className={styles.popup_inner}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default SimpleWindow;
