import { checkers } from "vincent-utils";
import * as zod from "zod";
import { CadendarConsts, E164Schema } from "cadendar-shared";
import { z } from "zod";
import { NewPatient, newPatientSchema } from "cadendar-shared";

const { adrKeys, assurancesKeys } = CadendarConsts;

const isValid = (key: keyof typeof patientShape, value: unknown) => {
  const schema = patientShape[key];
  // if (!schema.safeParse(value).success) {
  //   console.log("validation error", schema.safeParse(value).error);
  // }
  return schema.safeParse(value).success;
};

const patientShape = {
  _id: zod.string().optional(), //pas d'id quand ça n'a pas encore été créé
  owner: zod.string(),
  nom: zod.string().refine(checkers.isNomOrPrenom),
  prenom: zod.string().refine(checkers.isNomOrPrenom),
  portable: E164Schema,
  email: zod.string().email(),
  ddn: zod.string().refine(checkers.isValidDdn).optional(), //ddn validator
  patient_flags: zod.enum(["pp", "vip", "bl", "pv"]).optional(),
  adressé: zod.enum([...adrKeys]).optional(),
  famille_id: zod.string().optional(),
  fixe: zod.string().refine(checkers.isFixe),
  commentaires: zod.string().optional(),
  plusagenda: zod.boolean().optional(),
  adresseur_id: zod.string().optional(),
  autre_adresseur: zod.string().optional(),
  familyRank: zod.number().optional(),
  seen: zod.boolean().optional(),
  ancien_patient: z.literal(true).optional(),
  RAF: zod.boolean().optional(),
  // sms: zod
  //   .array(
  //     zod.object({
  //       body: zod.string(),
  //       dateTime: zod.date()
  //     })
  //   )
  //   .optional(),
  assurance: zod.enum([...assurancesKeys]).optional(),
} as const;

const isValidPatient = (patient: NewPatient) => {
  const result = newPatientSchema.safeParse(patient);
  if (!result.success) {
    console.log(result.error);
  }
  return result.success;
};

export { isValid, isValidPatient };
