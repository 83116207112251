import { formaters } from "vincent-utils";

function convertTextForDisplay(
  str: string,
  date: Date,
  hmo: { heure: number; minute: number }
) {
  if (!str) {
    return;
  }
  const dateStr =
    formaters.getJourEnLettresFromDayNumber(date.getDay()) +
    " " +
    String(date.getDate()) +
    "/" +
    String(date.getMonth() + 1);
  const hmStr =
    String(hmo.heure) +
    "h" +
    String(formaters.convertZeroToDoubleZero(hmo.minute));
  let output = str.replace(/\{d\}/, dateStr);
  output = output.replace(/\{h\}/, hmStr);
  return output;
}

export default convertTextForDisplay;
