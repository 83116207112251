import HInputGroup from "../../main/components/HInputGroup.tsx";

interface NomPrenomDDNProps {
  ddn: string;
  nom: string;
  prenom: string;
  showPrenom: boolean;
  onDDNChange: (value: string) => void;
  isValidDdn: boolean;
  onNomChange: (value: string) => void;
  onPrenomChange: (value: string) => void;
}

const scroll2 = () => {
  document.querySelector("#ddn_lookup")?.scrollIntoView({ behavior: "smooth" });
  // try {
  //   $("html, body").animate(
  //     {
  //       scrollTop: $("#ddn_lookup").offset().top
  //     },
  //     500
  //   );
  // } catch (e) {}
};

export default (props: NomPrenomDDNProps) => {
  const ddn = props.ddn;
  const nom = props.nom;
  const prenom = props.prenom;
  const { showPrenom } = props;
  const { onDDNChange } = props;
  const { isValidDdn } = props;
  const isValidPrenom = prenom.length > 1;
  const { onNomChange } = props;
  const { onPrenomChange } = props;
  const prenomComp = () => {
    return (
      <div className={"form-group"}>
        <HInputGroup
          label="Prénom"
          value={prenom}
          onChange={onPrenomChange}
          id="prenom_input_fiche_appel"
          labelWidth="100px"
          inputWidth="300px"
          isValid={isValidPrenom}
          isTouched={!!prenom}
        />
      </div>
    );
  };
  const variableComp = showPrenom ? prenomComp() : <span></span>;
  return (
    <div>
      <HInputGroup
        label="Date de Naissance"
        value={ddn}
        onChange={onDDNChange}
        id="ddn_lookup"
        labelWidth="100px"
        inputWidth="300px"
        isValid={isValidDdn}
        isTouched={!!ddn}
      />

      <HInputGroup
        label="Nom"
        value={nom}
        onChange={onNomChange}
        id="nom_lookup"
        labelWidth="100px"
        inputWidth="300px"
        isTouched={!!nom}
        isValid={nom.length > 1}
      />
      {variableComp}
    </div>
  );
};
