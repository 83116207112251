import styles from "./Button.module.css";
import classNames from "classnames";

type ButtonStyles = "main" | "secondary" | "danger";

interface ButtonProps {
  title: string;
  onClick: (e: React.MouseEvent) => void;
  style?: ButtonStyles;
  id?: string;
  size?: "small" | "medium" | "large";
  ["data-testid"]?: string;
  disabled?: boolean;
}

const Button = (props: ButtonProps) => {
  return (
    <button
      className={classNames(styles.button, {
        [styles.main]: props.style === "main",
        [styles.secondary]: props.style === "secondary",
        [styles.danger]: props.style === "danger",
        [styles.small]: props.size === "small",
      })}
      onClick={props.onClick}
      id={props.id}
      data-testid={props["data-testid"]}
      disabled={props.disabled}
    >
      {props.title}
    </button>
  );
};

export default Button;
