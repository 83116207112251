interface MenuPopUpProps {
  top: number;
  onDisconnect: () => void;
}

const MenuPopUp = (props: MenuPopUpProps) => {
  const style = {
    position: "fixed" as const,
    top: props.top,
    right: 0,
    zIndex: 10,
    backgroundColor: "white",
    boxShadow: "3px 3px 10px",
    width: 180,
    transition: "all 0.5s ease",
  };

  return (
    <div id="MenuPopUp" style={style}>
      <ul
        style={{
          listStyleType: "none",
          display: "flex",
          alignItems: "center",
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <li
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 0,
            marginBottom: 0,
            cursor: "pointer",
          }}
          onClick={props.onDisconnect}
        >
          Se déconnecter
        </li>
      </ul>
    </div>
  );
};

export default MenuPopUp;
