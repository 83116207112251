import { useEffect } from "react";
import useOwner from "../modules/users/hooks/useOwner";
import _ from "underts";
import { trpc } from "../modules/main/components/MainContainer";
import { SeletectedDoctorState } from "../modules/main/state/globalState";
import { useAtom } from "jotai";
import styles from "./DoctorChoose.module.css";

const DoctorChooser = () => {
  const [selectedDoctor, onSelectDoctor] = useAtom(SeletectedDoctorState);
  const owner = useOwner();
  const { data: users } = trpc.user.getAll.useQuery(undefined, {
    enabled: !!owner,
  });

  const doctors = users ? users.filter((u) => u.tag === "doctor") : [];
  useEffect(() => {
    if (!selectedDoctor && doctors.length > 0) {
      onSelectDoctor(_.first(doctors)?._id || null);
    }
  }, [JSON.stringify(doctors)]);
  return (
    <div className={styles.spacer}>
      <select
        value={selectedDoctor || ""}
        id="doctor_chooser"
        onChange={(e) => onSelectDoctor(e.currentTarget.value)}
      >
        {[
          <option key="initialValueDoctorChooserSelectKey" value="">
            Choisir Docteur
          </option>,
        ].concat(
          doctors.map((doctor) => (
            <option key={doctor._id} value={doctor._id}>
              {doctor.fullname}
            </option>
          ))
        )}
      </select>
    </div>
  );
};

export default DoctorChooser;
