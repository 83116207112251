// import { useSuperQuery } from "../../../utils/useSuperQuery";
// import fetchHorairesByDate from "../data/fetchHorairesByDate";
import useOwner from "../../users/hooks/useOwner";
import { trpc } from "../../main/components/MainContainer";

export function useRelevantHoraires(selectedWeek: Date) {
  const owner = useOwner();
  const { data } = trpc.horaire.byDate.useQuery(
    { date: selectedWeek },
    { enabled: !!selectedWeek && !!owner, keepPreviousData: true }
  );
  return data ? [data] : [];
}
