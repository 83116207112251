import styles from "./AgendaWeekChooser.module.css";
import Settings02Icon from "../../main/icons/settings-02-stroke-rounded.tsx";
import MainMenu from "../../main/components/MainMenu.tsx";
import { useEffect, useState } from "react";
import RdvsLoading from "../../../components/RdvsLoading.tsx";
import ArrowRight01Icon from "../../main/icons/arrow-right-01-stroke-rounded.tsx";
import ArrowLeft01Icon from "../../main/icons/arrow-left-01-stroke-rounded.tsx";
import { User } from "cadendar-shared";
import DoctorChooser from "../../../components/DoctorChooser.tsx";

export interface AgendaWeekChooserProps {
  year: number;
  weekNumber: number;
  onPrevWeek: () => void;
  onNextWeek: () => void;
  onThisWeek: () => void;
  user: User | null;
}

const AgendaWeekChooser = (props: AgendaWeekChooserProps) => {
  const { year, weekNumber, onNextWeek, onPrevWeek, onThisWeek } = props;
  const [showMenuPopup, setShowMenuPopup] = useState(false);
  const [menuPopupTop, setMenuPopupTop] = useState(0);
  const menuPopupRight = 0;
  const handleShowMenuPopup = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const rect = e.currentTarget.getBoundingClientRect();
    setMenuPopupTop(rect.top + rect.height);
    setShowMenuPopup((val) => !val);
  };
  useEffect(() => {
    document.addEventListener("globalClick", () => {
      setShowMenuPopup(false);
    });
    return () => {
      document.removeEventListener("globalClick", () => {
        setShowMenuPopup(false);
      });
    };
  }, []);
  return (
    <>
      <div className={styles.mainSpacerStyle}>
        <div className={styles.spacer}>
          <div
            id="button_prev_week"
            className={styles.icon}
            onClick={onPrevWeek}
          >
            <ArrowLeft01Icon color="white" />
          </div>
          <div
            id="button_next_week"
            className={styles.icon}
            onClick={onNextWeek}
          >
            <ArrowRight01Icon color="white" />
          </div>
          <div id="button_today" className={styles.button} onClick={onThisWeek}>
            Cette Semaine
          </div>
          <div className={styles.weekNumberStyle}>
            <span id="year_display">{year} </span>
            Semaine
            <span id="week_number_display"> {weekNumber}</span>
          </div>
          {props.user?.tag === "secretary" ? <DoctorChooser /> : null}
          <RdvsLoading />
        </div>
        <div className={styles.spacer}>
          <div className={styles.userStyle} data-testid="userDisplay">
            {props.user?.fullname}
          </div>
          <div
            className={styles.buttonStyle}
            data-testid="mainSettingsButton"
            onClick={handleShowMenuPopup}
          >
            <Settings02Icon color="white" />
          </div>
        </div>
      </div>
      {showMenuPopup ? (
        <div
          className={styles.popupStyle}
          style={{ top: menuPopupTop, right: menuPopupRight }}
        >
          <MainMenu />
        </div>
      ) : null}
    </>
  );
};

export default AgendaWeekChooser;
