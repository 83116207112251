import HoraireColDisplay from "./HoraireColDisplay.tsx";
import {HoraireInDB, CadendarConsts} from "cadendar-shared";
const {jours} = CadendarConsts;

interface HoraireDisplayProps {
  horaire: HoraireInDB;
}

const HoraireDisplay = (props: HoraireDisplayProps) => {
  const {horaire} = props;
  return (
    <div className="col-lg-7" key={horaire._id}>
      <div>
        {jours.map(jour => (
          <div className="horaires_col" key={jour + "horaireKey"}>
            {jour}
            <HoraireColDisplay plages={horaire.horaire[jour]} jour={jour} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default HoraireDisplay;
