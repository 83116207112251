import useAllUsers from "./useAllUsers";
import { useAtomValue } from "jotai";
import { LoggedUserId } from "../../main/state/globalState";

const useUser = () => {
  const allUsers = useAllUsers();
  const loggedUserId = useAtomValue(LoggedUserId);
  return allUsers.find((user) => user._id === loggedUserId);
};

export default useUser;
