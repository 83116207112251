import React, { useEffect } from "react";
import Input from "../modules/main/components/Input.tsx";

type IntegerInputProps = IntegerDisabledInputProps | IntegerEnabledInputProps;

interface IntegerDisabledInputProps {
  value: number | null;
  id?: string;
  disabled: true;
  width?: string;
}

interface IntegerEnabledInputProps {
  onValueChange: (value: number | null) => void;
  value: number | null;
  id?: string;
  forbidZero?: boolean;
  width?: string;
}

const IntegerInput = (props: IntegerInputProps) => {
  const [integerString, setIntegerString] = React.useState(
    (typeof props.value === "number" && props.value.toString()) || ""
  );
  useEffect(() => {
    setIntegerString(
      (typeof props.value === "number" && props.value.toString()) || ""
    );
  }, [props.value]);
  const handleChange = (value: string) => {
    if ("disabled" in props) {
      return;
    }
    if (
      value === "" ||
      (props.forbidZero && value === "0") ||
      Number.isNaN(parseInt(value))
    ) {
      setIntegerString("");
      return;
    }

    const parsedValue = parseInt(value);
    setIntegerString(parsedValue.toString());
  };
  const handleBlur = () => {
    if ("disabled" in props) {
      return;
    }
    if (integerString === "") {
      props.onValueChange(null);
    }
    const value = parseInt(integerString);
    if (Number.isNaN(value)) {
      return;
    }
    props.onValueChange(value);
    // setIntegerString(value.toString()); //on remet la valeur sanitized
  };
  const isValidValue = Number.isInteger(parseInt(integerString));
  const isTouched = integerString !== "";
  return (
    <Input
      value={integerString}
      onChange={handleChange}
      onBlur={handleBlur}
      id={props.id}
      disabled={"disabled" in props && props.disabled}
      isValid={isValidValue}
      isTouched={isTouched}
      width={props.width}
    />
  );
};

export default IntegerInput;
