const processLeftValue = (
  left: number,
  width: number,
  windowInnerWidth: number
) => {
  if (left + width > windowInnerWidth - 50) {
    return left - width - 100;
  } else {
    return left + width + 10;
  }
};

const processTopValue = (
  height: number,
  top: number,
  windonwInnerHeight: number,
  scrollY: number
) => {
  const adjustedTop = top - scrollY;
  if (adjustedTop + height > windonwInnerHeight - 100) {
    return adjustedTop - 50;
  } else {
    return adjustedTop + 20;
  }
};

const processPopupCaracs = <TRdv>({
  rdv,
  top,
  left,
  height,
  width,
  innerHeight,
  innerWidth,
  scrollY,
  ...rest
}: {
  rdv: TRdv;
  top: number;
  left: number;
  height: number;
  width: number;
  innerHeight: number;
  innerWidth: number;
  scrollY: number;
}) => ({
  left: processLeftValue(left, width, innerWidth),
  top: processTopValue(height, top, innerHeight, scrollY),
  rdv,
  ...rest,
});

export { processLeftValue, processTopValue, processPopupCaracs };
