import useOwner from "../../users/hooks/useOwner";
import { trpc } from "../../main/components/MainContainer";

export const useHoraire = (date: Date | null) => {
  const owner = useOwner();
  const { data: horaire } = trpc.horaire.byDate.useQuery(
    { date: date! },
    { enabled: !!date && !!owner }
  );
  return horaire;
};
