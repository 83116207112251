import { isEmpty } from "./predicates/casePredicates";
import _ from "underts";
import { Collections } from "cadendar-shared";
import getFakePatient from "../../patients/filtersTransforms/getFakePatient.ts";
import {FakeRdvCase, EmptyRdvCase} from "cadendar-shared";

const set = _.without(_.range(1, 25), 9, 11, 12, 15, 21, 22);


type WithDureeWithDateWithHM = {
  duree: Collections.Rdv["duree"];
  heure: Collections.Rdv["heure"];
  minute: Collections.Rdv["minute"];
  date: Collections.Rdv["date"];
};

const doReplacement = (rdv: WithDureeWithDateWithHM): FakeRdvCase => {
  const fakePatient = getFakePatient();
  return {
    duree: rdv.duree,
    heure: rdv.heure,
    minute: rdv.minute,
    date: rdv.date,
    type: set[Math.floor(Math.random() * set.length)],
    fake: true as const,
    nom: fakePatient.nom,
    prenom: fakePatient.prenom,
    patient_ddn: fakePatient.ddn,
    tag : "fake"
  };
};

const replaceWithFake = <T>(rdv: T | EmptyRdvCase) =>
  isEmpty(rdv) ? doReplacement(rdv) : rdv;

export default replaceWithFake;
