import { trpc } from "../../main/components/MainContainer.tsx";
import _ from "underts";

interface PatientSettingsPopupProps {
  patientId: string;
}

const PatientSettingsPopup = (props: PatientSettingsPopupProps) => {
  const { patientId } = props;
  const { data: patients } = trpc.patient.getByIds.useQuery({
    _ids: [patientId],
  });
  const patient = patients && _.first(patients);

  const isAncientPatient = patient && !!patient.ancien_patient;
  const utils = trpc.useContext();
  const toggleAncientPatientMutation = trpc.patient.saveEdited.useMutation({
    onSuccess: () => {
      utils.patient.getByIds.invalidate();
    },
  });

  const handleToggleAncientPatient = () => {
    console.log("handleToggleAncientPatient");
    console.log("patient", patient);
    console.log("ancien_patient", patient?.ancien_patient);
    if (!patient) return;
    if (patient.ancien_patient) {
      const { ancien_patient, ...rest } = patient;
      toggleAncientPatientMutation.mutate({
        ...rest,
      });
    } else {
      toggleAncientPatientMutation.mutate({
        ...patient,
        ancien_patient: true,
      });
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <input
        type="checkbox"
        id="settingsPopup"
        checked={isAncientPatient}
        onChange={handleToggleAncientPatient}
      />
      <label htmlFor="settingsPopup">Ancien patient avec rdvs non listés</label>
    </div>
  );
};

export default PatientSettingsPopup;
