import SimpleWindow from "../../../components/SimpleWindow/SimpleWindow";
import EditRdvContainer from "../containers/EditRdvContainer";
import NewRdvContainer from "../containers/NewRdvContainer";
import AptRequestComp from "./AptRequestComp";
import { useRdvPopupState } from "../../main/state/globalState";
import NewRdvApi from "../containers/NewRdvContainer";

interface RdvPopupProps {
  onRdvSave: () => void;
  onOpenFicheAppel: () => void;
}

const RdvPopup = (props: RdvPopupProps) => {
  const { isOpen, mode, onCloseRdvPopup } = useRdvPopupState();
  const comp =
    mode === "rdvEdit" ? (
      <EditRdvContainer onOpenFicheAppel={props.onOpenFicheAppel} />
    ) : mode === "rdvCreate" ? (
      <NewRdvApi onSave={props.onRdvSave} />
    ) : mode === "aptRequestCreate" || mode === "aptRequestEdit" ? (
      <AptRequestComp />
    ) : null;
  const title =
    mode === "rdvEdit"
      ? "Modifier un rendez-vous"
      : mode === "rdvCreate"
      ? "Créer un rendez-vous"
      : mode === "aptRequestCreate" || mode === "aptRequestEdit"
      ? "Rdv à prendre"
      : "";
  const positionX = document.documentElement.clientWidth / 2 - 200;
  const positionY = 0;
  //getObserverTree(mainUiStore.rdvPopupState);
  return (
    <SimpleWindow
      title={title}
      isVisible={isOpen}
      initialX={positionX}
      initialY={positionY}
      onClose={onCloseRdvPopup}
    >
      {comp}
    </SimpleWindow>
  );
};

export default RdvPopup;
