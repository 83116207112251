import useTempPatient from "./useTempPatient";

export default () => {
  const {
    isAutrePatientChecked,
    isFamilleChecked,
    isAutreChecked,
    isAnnuaireChecked,
    selectedAutrePatientAdressedBy,
    selectedFamilleAdressedBy,
    otherReffereeMeanValue,
  } = useTempPatient();

  return (
    isAnnuaireChecked ||
    (isAutreChecked && !!otherReffereeMeanValue) ||
    (isFamilleChecked && !!selectedFamilleAdressedBy) ||
    (isAutrePatientChecked && !!selectedAutrePatientAdressedBy)
  );
};
