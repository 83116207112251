;

export interface StatsLinesProps {
  stats: {
    labelStat: string;
    value: string;
  }[];
}

const StatsLines = (props: StatsLinesProps) => {
  return (
    <>
      {props.stats.map(stat => (
        <div key={stat.labelStat + "key"}>
          {stat.labelStat} : {stat.value}
        </div>
      ))}
    </>
  );
};

export default StatsLines;
