import ArrowLeft01Icon from "../../main/icons/arrow-left-01-stroke-rounded.tsx";
import ArrowRight01Icon from "../../main/icons/arrow-right-01-stroke-rounded.tsx";
import styles from "./CandidatsWeekChooser.module.css";

export interface CandidatsWeekChooserProps {
  onPrevWeekCandidates: () => void;
  onNextWeekCandidates: () => void;
  semaineEnCours: string;
}

const CandidatsWeekChooser = (props: CandidatsWeekChooserProps) => {
  const { onPrevWeekCandidates, onNextWeekCandidates, semaineEnCours } = props;
  return (
    <div>
      <div className={styles.row}>
        <div
          className={styles.icon}
          onClick={onPrevWeekCandidates}
          id="button_prev_week_candidates"
        >
          <ArrowLeft01Icon />
        </div>
        <div className={styles.week}>{semaineEnCours}</div>
        <div
          className={styles.icon}
          onClick={onNextWeekCandidates}
          id="button_next_week_candidates"
        >
          <ArrowRight01Icon />
        </div>
      </div>
    </div>
  );
};

export default CandidatsWeekChooser;
