import RdvCandidat, { Candidat } from "./RdvCandidat";
import styles from "./RdvCandidatsList.module.css";
import classNames from "classnames";

export type RdvCandidatsListProps = {
  isStrategorReady: boolean;
  rdvCandidats: Candidat[];
  isPatientSelected: boolean;
  onCreatePatient: () => void;
  onCreateRdv: (candidat: Candidat) => void;
};

const RdvCandidatsList = (props: RdvCandidatsListProps) => {
  const {
    isStrategorReady,
    rdvCandidats,
    onCreateRdv,
    onCreatePatient,
    isPatientSelected,
  } = props;
  const candidatsSortedByScore = rdvCandidats.sort((a, b) => b.score - a.score);
  const candidatsComps = candidatsSortedByScore.map((candidat) => (
    <RdvCandidat
      candidat={candidat}
      onCreatePatient={onCreatePatient}
      onCreateRdv={onCreateRdv}
      isPatientSelected={isPatientSelected}
      key={"rdvCandidat" + candidat.date + candidat.heure + candidat.minute}
    />
  ));

  const compToShow =
    rdvCandidats.length > 0 ? (
      candidatsComps
    ) : (
      <div className="alert alert-danger" role="alert">
        Pas de rdvs disponibles cette semaine
      </div>
    );
  return (
    <div
      id="candidatsList"
      className={classNames({ [styles.table]: rdvCandidats.length > 0 })}
    >
      {isStrategorReady ? (
        compToShow
      ) : (
        <div className="alert alert-warning">Calcul...</div>
      )}
    </div>
  );
};

export default RdvCandidatsList;
