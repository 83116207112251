import classNames from "classnames";
import styles from "./Badge.module.css";

interface BadgeProps {
  text: string;
  color: "red" | "green" | "blue" | "yellow" | "orange";
}

const Badge = (props: BadgeProps) => {
  return (
    <span
      className={classNames(styles.base, {
        [styles.colorRed]: props.color === "red",
        [styles.green]: props.color === "green",
        [styles.blue]: props.color === "blue",
        [styles.yellow]: props.color === "yellow",
        [styles.orange]: props.color === "orange",
      })}
    >
      {props.text}
    </span>
  );
};

export default Badge;
