import { InputProps } from "./Input.tsx";
import styles from "./InputIcon.module.css";
import classNames from "classnames";
import { useState } from "react";

interface InputIconProps extends InputProps {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  id?: string;
  tooltip?: string;
  showIcon?: boolean;
  iconTestId?: string;
}

const InputIcon = (props: InputIconProps) => {
  const [isTouched, setIsTouched] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTouched(true);
    props.onChange(e.target.value);
  };
  return (
    <div className={styles.inputContainer}>
      <input
        value={props.value}
        onChange={handleChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        className={classNames(
          styles.input,
          styles.inputWithIconRight,
          props.className,
          {
            [styles.invalid]: (props.isTouched || isTouched) && !props.isValid,
            [styles.valid]: (props.isTouched || isTouched) && props.isValid,
          }
        )}
        id={props.id}
        readOnly={props.readonly}
        onBlur={props.onBlur}
        data-testid={props["data-testid"]}
      />
      <div
        className={classNames(styles.icon, styles.tooltip, {
          [styles.iconHidden]: "showIcon" in props ? !props.showIcon : false,
        })}
      >
        <div
          onClick={props.onClick}
          id={props.id}
          data-testid={props.iconTestId}
        >
          {props.children}
        </div>
        {props.tooltip && (
          <span className={styles.tooltipText}>{props.tooltip}</span>
        )}
      </div>
    </div>
  );
};

export default InputIcon;
