import { useAtom } from "jotai";
import { ReffererState, TempPatientState } from "../../main/state/globalState";
import { formaters } from "vincent-utils";
import _ from "underts";
import { trpc } from "../../main/components/MainContainer";

export default () => {
  const [tempPatient, setTempPatient] = useAtom(TempPatientState);
  const [reffererState, setRefferState] = useAtom(ReffererState);
  const reffererId =
    reffererState?.type === "OTHER_PATIENT" ||
    reffererState?.type === "FAMILY_PATIENT"
      ? reffererState.adresseur_id
      : null;
  const { data: adresseurPatientArray } = trpc.patient.getByIds.useQuery(
    { _ids: [reffererId!] },
    { enabled: !!reffererId }
  );
  const onSelectAdressedByFamillePatient = (_id: string) => {
    setRefferState({
      type: "FAMILY_PATIENT",
      adresseur_id: _id,
    });
  };
  const onSelectAdressedByAutrePatient = (_id: string) => {
    setRefferState({
      type: "OTHER_PATIENT",
      adresseur_id: _id,
    });
  };
  const onOtherMeanReffereeChange = (value: string) => {
    setRefferState({
      type: "OTHER_MEAN",
      value,
    });
  };
  const isAnnuaireChecked = reffererState?.type === "ANNU";
  const isFamilleChecked = reffererState?.type === "FAMILY_PATIENT";
  const isAutrePatientChecked = reffererState?.type === "OTHER_PATIENT";
  const isAutreChecked = reffererState?.type === "OTHER_MEAN";
  const onToggleAnnuaire = () => {
    setRefferState((state) => {
      if (!state) {
        return {
          type: "ANNU",
        };
      }
      return null;
    });
  };
  const onToggleFamille = () => {
    setRefferState((state) => {
      if (!state) {
        return {
          type: "FAMILY_PATIENT",
          adresseur_id: "",
        };
      }
      return null;
    });
  };
  const onToggleAutrePatient = () => {
    setRefferState((state) => {
      if (!state) {
        return {
          type: "OTHER_PATIENT",
          adresseur_id: "",
        };
      }
      return null;
    });
  };
  const onToggleAutre = () => {
    setRefferState((state) => {
      if (!state) {
        return {
          type: "OTHER_MEAN",
          value: "",
        };
      }
      return null;
    });
  };
  const selectedAutrePatientAdressedBy =
    reffererState?.type === "OTHER_PATIENT" && reffererState.adresseur_id;
  const selectedFamilleAdressedBy =
    reffererState?.type === "FAMILY_PATIENT" && reffererState.adresseur_id;
  const otherReffereeMeanValue =
    reffererState?.type === "OTHER_MEAN" && reffererState.value;
  const onSetNom = (nom: string) => {
    setTempPatient((patient) => ({
      ...patient,
      nom: nom.toLowerCase(),
    }));
  };
  const onSetPrenom = (prenom: string) => {
    setTempPatient((patient) => ({
      ...patient,
      prenom: prenom.toLowerCase(),
    }));
  };
  const onSetDdn = (ddn: string) => {
    const formatedDDN = formaters.formatDDNForStoring(ddn);
    if (!formatedDDN) throw new Error("Invalid DDN in onSetDdn");
    setTempPatient((patient) => ({
      ...patient,
      ddn: formatedDDN,
    }));
  };
  const onSetPortable = (portable: string) => {
    const formatedPortable = formaters.formatTelNumberForStoring(portable);
    if (!formatedPortable) throw new Error("Invalid portable in onSetPortable");
    setTempPatient((patient) => ({
      ...patient,
      portable: formatedPortable,
    }));
  };

  const onSetEmail = (email: string) => {
    setTempPatient((patient) => ({
      ...patient,
      email,
    }));
  };

  const onSetCommentaires = (commentaires: string) => {
    setTempPatient((patient) => ({
      ...patient,
      commentaires,
    }));
  };

  const onReset = () => {
    setTempPatient({
      nom: "",
      prenom: "",
      ddn: "",
      portable: "",
      email: "",
      famille_id: "",
    });
    setRefferState(null);
  };
  return {
    tempPatient,
    adresseurPatient: _.first(adresseurPatientArray || []),
    onSetNom,
    onSetPrenom,
    onSetDdn,
    onSetPortable,
    onSetEmail,
    onSetCommentaires,
    onSelectAdressedByFamillePatient,
    onSelectAdressedByAutrePatient,
    isAnnuaireChecked,
    isAutreChecked,
    isAutrePatientChecked,
    isFamilleChecked,
    onReset,
    onToggleAnnuaire,
    onToggleFamille,
    onToggleAutrePatient,
    onToggleAutre,
    onOtherMeanReffereeChange,
    selectedAutrePatientAdressedBy,
    selectedFamilleAdressedBy,
    reffererType: reffererState?.type,
    reffererId,

    otherReffereeMeanValue,
  };
};
