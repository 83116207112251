import { DateTime } from "luxon";
import styles from "./RdvCandidatsPanel.module.css";

import CandidatsWeekChooser from "./CandidatsWeekChooser";
import RdvCandidatsList from "./RdvCandidatsList";
import ReasonsList from "./ReasonsList";
import {
  etatCivilModeAtom,
  FamilleGridOpenState,
  isStrategorRunningAtom,
  useRdvPopupState,
  useSelectedPatientIds,
  useSelectedWeek,
} from "../../main/state/globalState";
import useRdvCandidats from "../hooks/useRdvCandidats";
import { Candidat } from "./RdvCandidat";
import { useSetAtom } from "jotai";
import _ from "underts";
import useRdvProposal from "../hooks/useRdvProposal";
import { useEffect } from "react";
import PageChooser from "./PageChooser.tsx";
import useTempPatient from "../hooks/useTempPatient.ts";
import IconButton from "../../main/components/IconButton.tsx";
import { LuFilter } from "react-icons/lu";

type RdvCandidatsPanelProps =
  | RdvCandidatsPanelWithPrevPageProps
  | RdvCandidatsPanelWithoutPrevPageProps;

interface RdvCandidatsPanelWithPrevPageProps {
  showPrevPageButton: true;
  onFilterClick: () => void;
  onPrevPage: () => void;
}

interface RdvCandidatsPanelWithoutPrevPageProps {
  showPrevPageButton: false;
  onFilterClick: () => void;
}

const RdvCandidatsPanel = (props: RdvCandidatsPanelProps) => {
  const { selectedWeek, setNextWeek, setPrevWeek } = useSelectedWeek();
  const [selectedPatientIds] = useSelectedPatientIds();
  const setFamilleOpenState = useSetAtom(FamilleGridOpenState);

  const semaineToDisplay =
    "Semaine du " +
    DateTime.fromJSDate(selectedWeek).setLocale("fr").toFormat("d LLLL");
  const { onOpenRdvPopup } = useRdvPopupState();
  const { mergeProposal } = useRdvProposal();
  const { isFamilleChecked, tempPatient, adresseurPatient } = useTempPatient();
  const handleCreateRdv = (candidat: Candidat) => {
    mergeProposal(candidat);
    onOpenRdvPopup("rdvCreate");
  };
  const handleNewFamilleClick = () => {
    setFamilleOpenState(true);
  };
  const etatCivilSetMode = useSetAtom(etatCivilModeAtom);
  const onCreatePatient = () => {
    if (isFamilleChecked) {
      handleNewFamilleClick();
    } else {
      etatCivilSetMode("newPatient");
    }
  };

  const [rdvCandidats, isStrategorReady] = useRdvCandidats();

  //TODO: vraiment nécessaire ?
  const setStrategorRunning = useSetAtom(isStrategorRunningAtom);
  useEffect(() => {
    setStrategorRunning(true);
  }, []);
  return (
    <div className={styles.container} id="rdvCandidats">
      <div className={styles.horizontalHeaderSpacer}>
        <CandidatsWeekChooser
          onNextWeekCandidates={setNextWeek}
          onPrevWeekCandidates={setPrevWeek}
          semaineEnCours={semaineToDisplay}
        />
        <div className={styles.buttonRightPositioner} id="filterButton">
          <IconButton onClick={props.onFilterClick}>
            <LuFilter size={"1rem"} />
          </IconButton>
        </div>
      </div>
      <div className={styles.overflowScroll}>
        <RdvCandidatsList
          rdvCandidats={rdvCandidats}
          isStrategorReady={isStrategorReady}
          isPatientSelected={selectedPatientIds.length > 0}
          onCreateRdv={handleCreateRdv}
          onCreatePatient={onCreatePatient}
        />
      </div>
      <ReasonsList reasons={[]} />
      {props.showPrevPageButton ? (
        <PageChooser
          prevPageEnabled={true}
          nextPageEnabled={false}
          prevPageText="Précédent"
          onPrevPage={props.onPrevPage}
        />
      ) : null}
    </div>
  );
};

export default RdvCandidatsPanel;
