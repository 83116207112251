import { ExistingPatient } from "cadendar-shared";

import PatientCandidatsList from "../../patients/components/PatientCandidatsList";
import {
  PatientByNameLookupState,
  useSelectedPatientIds,
} from "../../main/state/globalState";
import { useAtom } from "jotai";
import CheckBox from "../../main/CheckBox.tsx";

import styles from "./PatientsCandidatsWidget.module.css";

interface PatientsCandidatsWidgetProps {
  isNewPatientMode: boolean;
  candidats: ExistingPatient[];
}

const PatientsCandidatsWidget = (props: PatientsCandidatsWidgetProps) => {
  const { isNewPatientMode } = props;
  const { candidats } = props;

  const [
    selectedPatientsIds,
    {
      selectPatientId,
      togglePatientId,
      isMultiSelectionEnabled,
      toggleMultiSelection,
    },
  ] = useSelectedPatientIds();
  const onSelectPatient = (_id: string) => {
    selectPatientId(_id);
  };

  const [nameLookup] = useAtom(PatientByNameLookupState);

  return (
    <div>
      <div className={styles.question}>Choix du patient</div>
      <div className="row">
        <CheckBox
          checked={isMultiSelectionEnabled}
          onChange={() => {}}
          onToggle={toggleMultiSelection}
          id="patientsMultiplesCheck"
          label="Rdv pour plusieurs patients"
        />
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-offset-2">
          <div id="candidats_frame" className={styles.candidatsFrame}>
            <PatientCandidatsList
              {...{ candidats }}
              selected={selectedPatientsIds}
              onSelectPatient={onSelectPatient}
              lookup={nameLookup}
              showCheckBoxes={isMultiSelectionEnabled}
              onToggleSelectedPatient={togglePatientId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientsCandidatsWidget;
