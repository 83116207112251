import { patientValidationState } from "../../patients/validators/individualValidators";
import {
  LocalExistingPatient,
  LocalNewPatient,
} from "../components/FamilleGridPanel";
const patientsValidStates = (
  patients: Omit<LocalNewPatient | LocalExistingPatient, "owner">[]
) => {
  return patients.map((patient) => patientValidationState(patient));
};

export { patientsValidStates };
