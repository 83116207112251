import styles from "./Flag.module.css";
import classNames from "classnames";

interface FlagProps {
  onFlagClicked?: (event: React.MouseEvent) => void;
  flag: string;
  label?: string;
  light?: boolean;
}

const Flag = (props: FlagProps) => {
  return (
    <div
      onClick={(e) => props.onFlagClicked && props.onFlagClicked(e)}
      className={classNames(styles.flagStyle, {
        [styles.pp]: props.flag === "pp",
        [styles.pv]: props.flag === "pv",
        [styles.vip]: props.flag === "vip",
        [styles.bl]: props.flag === "bl",
        [styles.adaptativeWidth]: props.label,
        [styles.lightStyle]: props.light,
        [styles.extraLightStyle]: props.light && props.flag === "bl",
        [styles.pointerStyle]: !!props.onFlagClicked,
      })}
    >
      {props.label || props.flag}
    </div>
  );
};

export default Flag;
