import "moment/locale/fr";
import styles from "./Message.module.css";
import classNames from "classnames";

export interface MessageProps {
  posterName: string;
  seenByNames: string[];
  message: string;
  onMessageClick: () => void;
  displayAge: string;
  notSeenByMe: boolean;
  emergency: boolean;
}

const Message = (props: MessageProps) => {
  const {
    posterName,
    seenByNames,
    message,
    onMessageClick,
    displayAge,
    notSeenByMe,
    emergency,
  } = props;
  const seenComps =
    seenByNames && seenByNames.length > 0 ? (
      <span className={styles.message_age}>
        (vu par {seenByNames.reduce((str, name) => str + " " + name)})
      </span>
    ) : null;
  return (
    <div
      className={classNames({
        [styles.message_not_seen]: notSeenByMe,
        [styles.emergency]: emergency,
      })}
      onClick={onMessageClick}
      data-testid={notSeenByMe ? "not_seen_message" : "seen_message"}
    >
      <span className={styles.user}>{posterName} : </span>
      <span> {message}</span>
      <span className={styles.message_age}> {displayAge}</span>
      <span> {seenComps}</span>
    </div>
  );
};

export default Message;
