import { useEffect } from "react";
import styles from "./MainContainer.module.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  createTRPCReact,
  createWSClient,
  httpBatchLink,
  splitLink,
  wsLink,
  TRPCLink,
  TRPCClientError,
} from "@trpc/react-query";
import superjson from "superjson";
import { useAtomValue, useSetAtom } from "jotai";
import { LoggedUserId, SeletectedDoctorState } from "../state/globalState";
import { getOwnerFromUser } from "../../users/hooks/useOwner";
import { Collections } from "cadendar-shared";
import type { AppRouter } from "cadendar-trpc-server";
import AppSelectorContainer from "../../centralComp/containers/AppSelectorContainer";
import { observable } from "@trpc/server/observable";
import { ToastContainer } from "react-toastify";
import classNames from "classnames";
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: (error) => {
        if (error instanceof TRPCClientError) {
          if (error.message === "UNAUTHORIZED") {
            window.location.reload();
          }
        }
        return false;
      },
    },
  },
});

const hostDirty = import.meta.env.VITE_DEV_HOST || window.location.host;
const clientBuildNumber = import.meta.env.VITE_BUILD_NUMBER;
const mode = import.meta.env.MODE;
const prefix = mode === "development" ? "http://" : "https://";
const wsPrefix = mode === "development" ? "ws://" : "wss://";
const host = hostDirty.replace("http://", "");
const url = `${prefix}${host}/cadendarApi`;
const ws_url = `${wsPrefix}${host}/cadendarApi`;

const wsClient = createWSClient({
  url: ws_url,
  retryDelayMs: () => 2000,
});
export const trpc = createTRPCReact<AppRouter>();

let _owner = "";

function getBuildNumberFromResponse(response: Response) {
  const headers = response.headers;
  const build = headers.get("x-build-number");
  console.log("build", build);
  return build;
}

const customLink: TRPCLink<AppRouter> = () => {
  return ({ next, op }) => {
    // this is when passing the result to the next link
    // each link needs to return an observable which propagates results
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          const response = value.context?.response as Response | undefined;
          const serverBuildNumber = response
            ? getBuildNumberFromResponse(response)
            : null;
          if (serverBuildNumber && serverBuildNumber !== clientBuildNumber) {
            console.log("force reload");
            console.log("serverBuildNumber", serverBuildNumber);
            console.log("clientBuildNumber", clientBuildNumber);
            location.reload();
          }
          response && getBuildNumberFromResponse(response);
          observer.next(value);
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
  };
};

const trpcClient = trpc.createClient({
  links: [
    customLink,
    splitLink({
      condition: (op) => {
        return op.type === "subscription";
      },
      true: wsLink({ client: wsClient }),
      false: httpBatchLink({
        url,
        headers() {
          return {
            "x-cadendar-owner": _owner,
          };
        },
        fetch(url, options) {
          return fetch(url, {
            ...options,
            credentials: "include",
          });
        },
      }),
    }),
  ],
  transformer: superjson,
});

interface MainContainerProps {
  user: Collections.User | null;
}

const MainContainer = (props: MainContainerProps) => {
  const setUserId = useSetAtom(LoggedUserId);
  const selectedDoctorId = useAtomValue(SeletectedDoctorState);
  const user = props.user;
  const userId = user?._id;

  const owner = getOwnerFromUser(user, selectedDoctorId);
  useEffect(() => {
    _owner = owner || "";
  }, [owner]);
  useEffect(() => {
    setUserId(userId || "");
  }, [userId]);
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <div className={classNames(styles.back, styles.main)}>
        <ToastContainer />
        <AppSelectorContainer />
      </div>
    </trpc.Provider>
  );
};

export default (props: MainContainerProps) => (
  <QueryClientProvider client={queryClient}>
    <MainContainer {...props} />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
