import { formaters } from "vincent-utils";
import _ from "underts";
import styles from "./GrilleHeure.module.css";

interface GrilleHeureProps {
  start: number;
  end: number;
  step: number;
  height: string;
}

const GrilleHeure = (props: GrilleHeureProps) => {
  const { start, end, step } = props;
  const { height } = props;
  const hmObjs = _.compact(
    _.range(start, end, step).map(
      formaters.getHeureMinuteObjectFromHeureEnMinutes
    )
  );
  const strings = hmObjs.map(formaters.formatHMObjectForDisplay);
  const isZeroMinute = (hmString: string) => hmString.split(":")[1] === "00";
  const style = { height };
  const divs = strings.map((str) =>
    isZeroMinute(str as string) ? (
      <div
        style={style}
        className={styles.case_grille_heure}
        data-testid="CaseGrilleHeure"
        key={str + "grillekey"}
      >
        {str}
      </div>
    ) : (
      <div
        style={style}
        className={styles.case_grille}
        data-testid="CaseGrille"
        key={str + "grillekey"}
      >
        {str}
      </div>
    )
  );

  return (
    <div className={styles.grille} data-testid="GrilleHeure" id="GrilleHeureId">
      {divs}
    </div>
  );
};

export default GrilleHeure;
