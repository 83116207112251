import { useEffect, useState } from "react";
import AgendaColMainComp from "../components/AgendaColMainComp";
import { ClosedRdvCase, EmptyRdvCase, FilledRdvCase } from "cadendar-shared";
import { useAtomValue, useSetAtom } from "jotai";
import {
  isStrategorRunningAtom,
  RdvProposalAtom,
  selectedRdvIdAtom,
  selectedWeekAtom,
  usePreferences,
  useRdvPopupState,
} from "../../main/state/globalState";
import _ from "underts";
import moment from "moment";
import rdvByDate from "../../rdvs/filterTransforms/rdvsByDate";
import replaceWithFake from "../../strategor/filterTransforms/replaceWithFake";
import { useRelevantHoraires } from "../../horaires/hooks/useRelevantHoraires";
import { useThreeWeeksRdvs } from "../../rdvs/queries/rdvsQueries";
import useRdvCandidats from "../../ficheAppel/hooks/useRdvCandidats";
import useRdvProposal from "../../ficheAppel/hooks/useRdvProposal";
import isCancelledRdv from "../../rdvs/filterTransforms/isCancelledRdv";
import { trpc } from "../../main/components/MainContainer";
import { ClosedByHoraireRdv } from "cadendar-shared";
import { AgendaCase, Transforms } from "cadendar-shared";

const fakeAccordingToStrategor = (
  weekRdvsToDisplay: (
    | ClosedByHoraireRdv
    | FilledRdvCase
    | ClosedRdvCase
    | EmptyRdvCase
  )[][],
  rdvCandidates: {
    heure: number;
    minute: number;
    date: Date;
    score: number;
  }[],
  duree: number,
  dureeMiniRdv: number
) => {
  //on remplace toutes les cases vides par des fake
  const emptyCasesReplacedWithFake = weekRdvsToDisplay.map((dayRdvs) =>
    dayRdvs.map((rdv) => replaceWithFake(rdv))
  );
  const candidatesWithDuree = rdvCandidates.map((c) => ({ ...c, duree }));
  const candidatesGroupedByDate = Object.values(
    _.groupBy(candidatesWithDuree, "date")
  );
  const result = Transforms.rdvs.openCandidateCasesForWeeks(
    emptyCasesReplacedWithFake,
    candidatesGroupedByDate,
    dureeMiniRdv
  );
  return result;
};

// const AgendaContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(AgendaColMainComp);

const AgendaContainer2 = () => {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000 * 30);
    return () => clearInterval(interval);
  });
  const selectedWeek = useAtomValue(selectedWeekAtom);
  const selectedWeekDates = _.range(0, 6, 1).map((d) =>
    moment(selectedWeek).add(d, "days").toDate()
  );
  const [relevantHoraire] = useRelevantHoraires(selectedWeek);
  const horairesExtremes = (relevantHoraire &&
    Transforms.horaires.horairesExtremesForWeek(relevantHoraire)) || {
    start: { heure: 0, minute: 0 },
    end: { heure: 23, minute: 59 },
  };
  const débutHeureAffichage =
    Transforms.horaires.getDebutHeureAffichage(horairesExtremes);
  const finHeureAffichage =
    Transforms.horaires.getFinHeureAffichage(horairesExtremes);
  const [threeWeeksRdvs] = useThreeWeeksRdvs(selectedWeek);
  const prefs = usePreferences();
  const duréeMinRdv = prefs?.duréeMinRdv || 15;
  const isStrategorRunning = useAtomValue(isStrategorRunningAtom);
  const regularWeekToDisplay =
    (relevantHoraire &&
      selectedWeekDates.map((date) =>
        Transforms.rdvs.dayClosedReplacer(
          Transforms.rdvs.getRdvsWithEmpties(
            Transforms.horaires.bornesForDay(relevantHoraire, date),
            isStrategorRunning
              ? rdvByDate(threeWeeksRdvs, date).filter(
                  (rdv) => !isCancelledRdv(rdv)
                )
              : rdvByDate(threeWeeksRdvs, date),
            duréeMinRdv,
            débutHeureAffichage,
            finHeureAffichage,
            date
          ),
          date,
          débutHeureAffichage,
          finHeureAffichage
        )
      )) ||
    [];
  const [rdvCandidates] = useRdvCandidats();
  const setRdvProposal = useSetAtom(RdvProposalAtom);
  const rdvCandidatesFiltered = rdvCandidates.filter((r) => r.score >= 0);
  //const duree = _.first(rdvCandidatesFiltered)?.duree;
  const { rdvProposal } = useRdvProposal();
  const { duree } = rdvProposal;
  const deductedDuree = duree || _.first(rdvCandidates)?.duree;

  const weekRdvsToDisplay = isStrategorRunning
    ? deductedDuree
      ? fakeAccordingToStrategor(
          regularWeekToDisplay,
          rdvCandidatesFiltered,
          deductedDuree,
          duréeMinRdv
        )
      : regularWeekToDisplay
    : regularWeekToDisplay;

  const { onOpenRdvPopup } = useRdvPopupState();
  const trpcUtils = trpc.useContext();
  const archiveMutation = trpc.rdv.archive.useMutation({
    onSuccess: () => {
      trpcUtils.rdv.invalidate();
    },
  });
  const onArchiveRdv = async (_id: string) => {
    archiveMutation.mutate({ _id });
  };
  const confirmMutation = trpc.rdv.confirm.useMutation({
    onSuccess: () => {
      trpcUtils.rdv.invalidate();
    },
  });
  const onConfirmRdv = async (_id: string) => {
    console.log("confirmMutation", _id);
    confirmMutation.mutate({ _id });
  };
  const setRdvIdSelected = useSetAtom(selectedRdvIdAtom);
  const onCaseClick = (rdvCase: AgendaCase) => {
    if (rdvCase.tag === "empty") {
      setRdvProposal((proposal) => ({
        ...(proposal ? proposal : null),
        date: rdvCase.date,
        heure: rdvCase.heure,
        minute: rdvCase.minute,
      }));
      onOpenRdvPopup("rdvCreate");
      return;
    }

    if (rdvCase.tag === "rdv") {
      setRdvIdSelected(rdvCase._id);
      // setOldRdvFromRdv(rdvCase);
      onOpenRdvPopup("rdvEdit");
      return;
    }
    if (rdvCase.tag === "closedByHoraire") {
      return;
    }
  };

  return (
    <AgendaColMainComp
      weekRdvsArray={weekRdvsToDisplay}
      now={now}
      débutHeureAffichage={débutHeureAffichage}
      finHeureAffichage={finHeureAffichage}
      duréeMiniRdv={
        prefs?.duréeMinRdv ||
        weekRdvsToDisplay.flat().find((rdv) => !rdv)?.duree || //quand ça n'a pas encore laadé les préférences mais qu'on a déjà des rdvs TODO: faire mieux
        15
      }
      onCloseCrossClick={onArchiveRdv}
      onConfirmClick={onConfirmRdv}
      onCaseClick={onCaseClick}
    />
  );
};

export default AgendaContainer2;
