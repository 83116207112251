import LoginSwitcher from "./modules/main/components/LoginSwitcher.tsx";

const Root = () => {
  return (
    <div>
      <LoginSwitcher />
    </div>
  );
};

export default Root;
