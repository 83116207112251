import { produce } from "immer";
import { Jours } from "cadendar-shared";

export interface HoraireLineEditProps {
  plages: { start: string; end: string }[];
  addPlage: () => void;
  removePlage: () => void;
  onSetPlages: (plages: { start: string; end: string }[]) => void;
  validationMap: { start: string[]; end: string[] }[];
  jour: Jours;
}

const HoraireLineEdit = (props: HoraireLineEditProps) => {
  const handleSetPlage = (
    startOrEnd: "start" | "end",
    position: number,
    value: string
  ) => {
    props.onSetPlages(
      produce(props.plages, (draft) => {
        draft[position][startOrEnd] = value;
      })
    );
  };
  return (
    <div className="panel">
      <div className="panel-body">
        <form
          className="form-inline"
          onSubmit={(event) => event.preventDefault()}
        >
          {props.plages.map((plage, idx) => (
            <span key={props.jour + idx + "key"}>
              <div
                className={
                  "form-group " +
                  (props.validationMap[idx].start.length === 0
                    ? "has-success"
                    : "has-error")
                }
              >
                <label htmlFor={"fromPlage" + idx}> De </label>
                <input
                  type="text"
                  className="form-control "
                  value={plage.start}
                  placeholder="HH:MM"
                  onChange={(event) =>
                    handleSetPlage("start", idx, event.currentTarget.value)
                  }
                  id={"fromPlage" + idx}
                  data-test={"horaireInputFrom" + props.jour + idx}
                />
              </div>
              <div
                className={
                  "form-group " +
                  (props.validationMap[idx].end.length === 0
                    ? "has-success"
                    : "has-error")
                }
              >
                <label>A </label>
                <input
                  type="text"
                  className="form-control"
                  value={plage.end}
                  placeholder="HH:MM"
                  onChange={(event) =>
                    handleSetPlage("end", idx, event.currentTarget.value)
                  }
                  data-test={"horaireInputTo" + props.jour + idx}
                />
              </div>
            </span>
          ))}
          <button
            className="btn btn-default addBornes"
            onClick={props.addPlage}
            data-test={"addBornesButton" + props.jour}
          >
            Ajouter plage
          </button>
          {props.plages.length > 0 ? (
            <button
              className="btn btn-default removeBornes"
              onClick={props.removePlage}
              data-test={"removeBornesButton" + props.jour}
            >
              Retirer plage
            </button>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default HoraireLineEdit;
