export default function <TRdv extends { date: Date }>(
  rdvs: TRdv[],
  date: Date
) {
  return rdvs.filter((rdv) => {
    const time1 = rdv.date.getTime();
    const time2 = date.getTime();
    return time1 === time2;
  });
}

const daysRdvs = <TRdv extends { date: Date }>(
  days: Date[],
  allRdvs: TRdv[]
) => {
  return days
    .map((day) => allRdvs.filter((rdv) => rdv.date.getTime() === day.getTime()))
    .flat();
};

export { daysRdvs };
