import PatientCandidatsList from "../../patients/components/PatientCandidatsList";
import { ExistingPatient } from "cadendar-shared";
import styles from "./CompactPatientChooser.module.css";
import Input from "../../main/components/Input.tsx";

interface CompactPatientChooserProps {
  onChange: (value: string) => void;
  onSelectPatient: (_id: string) => void;
  candidats: ExistingPatient[];
  selected: string[];
  lookup: string;
}

const CompactPatientChooser = (props: CompactPatientChooserProps) => {
  const { onChange } = props;
  const { onSelectPatient } = props;
  const { candidats } = props;
  const { selected } = props;
  const { lookup } = props;
  return (
    <div className="form-group">
      <Input value={lookup} onChange={onChange} id={"compactPatientLookup"} />
      <div id="candidats_frame" className={styles.container}>
        <PatientCandidatsList
          candidats={candidats}
          selected={selected}
          lookup={lookup}
          onSelectPatient={onSelectPatient}
          showCheckBoxes={false}
        />
      </div>
    </div>
  );
};

export default CompactPatientChooser;
