import { CadendarConsts } from "cadendar-shared";
import { useAtom } from "jotai";
import { RdvProposalAtom } from "../../main/state/globalState";
import _ from "underts";
export default () => {
  const [rdvProposal, setRdvProposal] = useAtom(RdvProposalAtom);
  const setDuree = (duree: number) => {
    setRdvProposal((rdvProposal) => ({ ...rdvProposal, duree }));
  };
  const deleteDuree = () => {
    setRdvProposal((rdvProposal) => {
      return _.omit(rdvProposal, ["duree"]);
    });
  };
  const toggleMotif = (motif: CadendarConsts.MotifsNames) => {
    return setRdvProposal((rdvProposal) => {
      const motifKey = CadendarConsts.getMotifConstFromMotifName(motif);
      if (!rdvProposal.motifs) return { ...rdvProposal, motifs: [motifKey] };
      if (rdvProposal.motifs.includes(motifKey)) {
        const newMotifs = rdvProposal.motifs.filter((m) => m !== motifKey);
        const { motifs, ...rest } = rdvProposal;
        return {
          ...rest,
          ...(newMotifs.length > 0 ? { motifs: newMotifs } : {}),
        };
      } else {
        return {
          ...rdvProposal,
          motifs: [...rdvProposal.motifs, motifKey],
        };
      }
    });
  };
  //quand on n'a pas de motifs on retire l'array motifs
  //on peut avoir un array motifs vide dans le cas d'un postpone sans motifs
  //façon de forcer strategor à marcher quand même lors du postpone
  const hasMotifs = !!rdvProposal && !!rdvProposal.motifs;
  const getMotifsNums = () => {
    if (!hasMotifs) return [];
    return (
      rdvProposal.motifs?.map((motif) => CadendarConsts.motifConsts[motif]) ||
      []
    );
  };
  const assignMotifsNums = (motifsNums: number[]) => {
    setRdvProposal((rdvProposal) => {
      return {
        ...rdvProposal,
        motifs:
          motifsNums.map(
            (motifNum) =>
              Object.entries(CadendarConsts.motifConsts).find(
                ([_key, value]) => value === motifNum
              )?.[0] as keyof typeof CadendarConsts.motifConsts
          ) || [],
      };
    });
  };
  const setObjet = (objet: string) => {
    setRdvProposal((rdvProposal) => ({ ...rdvProposal, objet }));
  };
  const setType = (type: CadendarConsts.typeNumsType) => {
    setRdvProposal((rdvProposal) => ({ ...rdvProposal, type }));
  };
  const setReminderChecked = (reminderChecked: boolean) => {
    setRdvProposal((rdvProposal) => ({ ...rdvProposal, reminderChecked }));
  };
  const setConfirmChecked = (confirmChecked: boolean) => {
    setRdvProposal((rdvProposal) => ({ ...rdvProposal, confirmChecked }));
  };
  const clearRdvProposal = () => {
    setRdvProposal({});
  };
  const mergeProposal = (proposal: Partial<typeof rdvProposal>) => {
    setRdvProposal((rdvProposal) => ({ ...rdvProposal, ...proposal }));
  };
  return {
    rdvProposal,
    mergeProposal,
    clearRdvProposal,
    setDuree,
    deleteDuree,
    toggleMotif,
    hasMotifs,
    getMotifsNums,
    assignMotifsNums,
    setObjet,
    setType,
    setReminderChecked,
    setConfirmChecked,
  };
};
