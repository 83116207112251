import { useState } from "react";
import poster from "../../../utils/poster.ts";
import style from "./LoginScreen.module.css";

import { z } from "zod";

//TODO : à améliorer
const prefix = "cadendarRest";

interface LoginScreenProps {
  callBack: () => void;
}
const LoginScreen = (props: LoginScreenProps) => {
  // const [email, setEmail] = useState("");
  // const [username, setUsername] = useState("");
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasErrors, _setHasError] = useState(false);
  const onSubmit = () => {
    if (
      z.string().email().safeParse(emailOrUsername).success &&
      z.string().min(6).safeParse(password).success
    ) {
      poster(`${prefix}/loginUser`, { email: emailOrUsername, password }).then(
        (response) => {
          if (response) {
            props.callBack();
          }
        }
      );
      return;
    }
    if (
      z.string().min(4).safeParse(emailOrUsername).success &&
      z.string().min(6).safeParse(password).success
    ) {
      poster(`${prefix}/loginUser`, {
        username: emailOrUsername,
        password,
      }).then((response) => {
        if (response) {
          props.callBack();
        }
      });
      return;
    }

    // Meteor.loginWithPassword(email, password, (error) => {
    //   if (error) {
    //     setHasError(true);
    //   }
    // });
  };
  // const isLoggingIn = useTracker(() => Meteor.loggingIn());
  const isLoggingIn = false;
  return (
    <div className={style.background}>
      <div className={""}>
        <div className={style.cardWidth}>
          <div className={style.cardHeader}>Login CADENDAR</div>
          <div className={style.cardBody}>
            {hasErrors ? (
              <div className="alert alert-danger" role="alert">
                Mauvaise combinaison email/mot de passe
              </div>
            ) : null}
            <div className={style.inputGroup}>
              <label htmlFor="emailInput" className={style.inputLabel}>
                Email ou Nom d'utilisateur
              </label>
              <input
                type="email"
                className={style.input}
                id="emailInput"
                value={emailOrUsername}
                onChange={(e) => setEmailOrUsername(e.target.value)}
              />
            </div>
            <div className={style.inputGroup}>
              <label htmlFor="passwordInput" className={style.inputLabel}>
                Mot de passe
              </label>
              <input
                type="password"
                className={`${style.input}`}
                id="passwordInput"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
            </div>
          </div>
          <button
            type="button"
            className={style.button}
            disabled={!emailOrUsername || !password || isLoggingIn}
            onClick={onSubmit}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
