import NomPrenomDDN from "./NomPrenomDDN";
import styles from "./PatientEntry.module.css";

interface PatientEntryProps {
  ddn: string;
  nom: string;
  prenom: string;
  onNomChange: (value: string) => void;
  onPrenomChange: (value: string) => void;
  onDDNChange: (value: string) => void;
  isValidDdn: boolean;
  isNewPatientMode: boolean;
}

const PatientEntry = (props: PatientEntryProps) => {
  const { isNewPatientMode } = props;
  const phrase = isNewPatientMode
    ? "Caractéristiques du nouveau patient"
    : "Recherche du patient";
  return (
    <div className={styles.row}>
      <span className={styles.question}>{phrase}</span>
      <div className={styles.tab}>
        <NomPrenomDDN {...props} showPrenom={isNewPatientMode} />
      </div>
    </div>
  );
};

export default PatientEntry;
