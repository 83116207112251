import CheckBox from "../../main/CheckBox.tsx";
import styles from "./DejaVenu.module.css";

interface DejaVenuProps {
  dejaVenuOui: boolean;
  dejaVenuNon: boolean;
  setDejaVenu: (value: boolean) => void;
}

const DejaVenu = (props: DejaVenuProps) => {
  const { dejaVenuOui, dejaVenuNon } = props;
  const setDejaVenu = () => props.setDejaVenu(true);
  const setNotDejaVenu = () => props.setDejaVenu(false);
  return (
    <div className={styles.questionSpacing}>
      <label htmlFor="deja_venu_oui" className={styles.label}>
        Etes vous déjà venu au cabinet ?{" "}
      </label>

      <div className={styles.choices}>
        <CheckBox
          checked={dejaVenuOui}
          id="deja_venu_oui"
          onChange={setDejaVenu}
          label="OUI"
        />
        <CheckBox
          checked={dejaVenuNon}
          id="deja_venu_non"
          onChange={setNotDejaVenu}
          label="NON"
        />
      </div>
    </div>
  );
};

export default DejaVenu;
