import { FaRegStar, FaStar } from "react-icons/fa";
import styles from "./RdvStars.module.css";

interface RdvStarsProps {
  stars?: number;
}

const RdvStars = ({ stars }: RdvStarsProps) => {
  // const hue = Math.floor((121 * stars) / 8);
  // const saturation = "97%";
  // const light = "51%";
  //const style = {color : {h : hue, s : saturation, l: light}};
  //const style = {color : {h : 5, s : 100, l : 50}};
  //const style = {color : "hsl("+hue+","+saturation+","+light+")"};
  if (!stars) {
    return <span />;
  }

  const vert = "hsl(120, 97%, 51%)";
  const jaune = "hsl(50, 97%, 51%)";
  const orange = "hsl(30, 97%, 51%)";
  const rouge = "hsl(15, 97%, 51%)";

  const colors = [rouge, orange, jaune, vert];
  const color = colors[Math.ceil(stars / 2) - 1];
  return (
    <div className={styles.spacer}>
      {(stars &&
        new Array(stars).fill(1).map((_, idx) => (
          <div data-testid="starIcon" key={"star" + idx}>
            <FaStar color={color} size={"1rem"} z={1000} />
          </div>
        ))) ||
        null}
      {stars === 0 ? (
        <span data-testid="starIcon" key={"star0"}>
          <FaRegStar color={color} />
        </span>
      ) : null}
    </div>
  );
};

export default RdvStars;
