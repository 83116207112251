
interface CancelRdvButtonsProps {
  onSimpleDelete: () => void;
  onCancel: () => void;
  onPostPone: () => void;
}

const CancelRdvButtons = ({
  onSimpleDelete,
  onCancel,
  onPostPone
}: CancelRdvButtonsProps) => {
  return (
    <div id="choix_motif_delete_rdv">
      <button
        type="button"
        id="rdv_delete"
        className="btn btn-success btn-sm"
        onClick={onSimpleDelete}
      >
        Suppression simple
      </button>
      <button
        type="button"
        id="rdv_canceled"
        className="btn btn-warning btn-sm"
        onClick={onCancel}
      >
        Annulation
      </button>
      <button
        type="button"
        id="rdv_postponed"
        className="btn btn-warning btn-sm"
        onClick={onPostPone}
      >
        Report
      </button>
    </div>
  );
};
export default CancelRdvButtons;
