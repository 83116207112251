import * as React from "react";
import PatientCandidatsList from "./PatientCandidatsList";
import {
  PatientByNameLookupState,
  useSelectedPatientIds,
} from "../../main/state/globalState";
import { useAtom } from "jotai";
import { trpc } from "../../main/components/MainContainer";
import { Transforms } from "cadendar-shared";
import styles from "./PatientLookup.module.css";

const PatientLookup = () => {
  const [lookup, setLookup] = useAtom(PatientByNameLookupState);
  const [selectedPatientsIds, { selectPatientId }] = useSelectedPatientIds();
  const { data: candidates } = trpc.patient.findByName.useQuery(
    { nameLookup: lookup },
    { enabled: !!lookup && lookup.length > 1 }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if (props.autoScroll) {
    //   window.scroll({ left: 0, top: 200, behavior: "smooth" });
    // }
    setLookup(event.target.value);
  };
  const sorter = Transforms.patients.curriedSpecialSort(lookup);
  const sortedCandidates = [...(candidates || [])].sort(sorter);
  return (
    <>
      <div>
        <div className={styles.inputSpacer}>
          <label htmlFor="patient_nom_lookup" className={styles.label}>
            Nom
          </label>

          <input
            type="text"
            className={styles.input}
            id="patient_nom_lookup"
            data-test="patient_nom_lookup"
            placeholder="Nom"
            onChange={handleChange}
            value={lookup}
            size={20}
          />
        </div>
        <div className="">
          <div className="">
            <div id="candidats_frame" className={styles.candidats_frame}>
              <PatientCandidatsList
                showCheckBoxes={false}
                candidats={sortedCandidates}
                selected={selectedPatientsIds}
                onSelectPatient={selectPatientId}
                lookup={lookup}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientLookup;
