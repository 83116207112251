import _ from "underts";
import moment from "moment";
import z from "zod";
import { functionnal } from "vincent-utils";
function cleanObject<O extends { [key: string]: any }>(
  obj: O
): { [Key in keyof O]: NonNullable<O[Key]> } {
  "use strict";
  return Object.entries(obj).reduce(
    (result, [key, value]) => ({
      ...result,
      ...(functionnal.existy(value) ? { [key]: value } : null),
    }),
    {} as { [Key in keyof O]: NonNullable<O[Key]> }
  );
}
function removeEmptyStrings<O extends { [key: string]: any }>(
  obj: O
): {
  [Key in keyof O]: Exclude<O[Key], "">;
} {
  return Object.entries(obj).reduce(
    (result, [key, value]) => ({
      ...result,
      ...(value !== "" ? { [key]: value } : null),
    }),
    {} as { [Key in keyof O]: Exclude<O[Key], ""> }
  );
}
function frenchSort(a: string, b: string) {
  "use strict";
  if (a === b) return 0;
  const alphabet =
    "._!*@()[]{}#^&%-=+01234567989aāáǎàâãąbcćčçdďðeēéěèêëęfgğhıiīíǐìîïjklłmnńňñoōóǒòôpqrřsśšştťuūúǔùůûüǖǘǚǜvwxyýzźżžþæœøõåäö";
  let i = -1;
  let aIndex, bIndex;
  a = a.toLowerCase();
  b = b.toLowerCase();
  do {
    i++;
    aIndex = alphabet.indexOf(a[i]);
    bIndex = alphabet.indexOf(b[i]);
  } while (aIndex === bIndex);
  return aIndex - bIndex;
}
const removeDuplicateId = <O extends { _id: string }>(objs: O[]) => {
  return _.uniq(objs, false, (obj) => obj["_id"]);
};
const addUniqueObjToArray = <O extends { _id: string }>(arr: O[], obj: O) => {
  if (isObjectInArray(arr, obj)) {
    const idx = arr.findIndex((elem) => elem._id === obj._id);
    if (idx !== -1) {
      return arr
        .slice(0, idx)
        .concat(obj)
        .concat(arr.slice(idx + 1));
    } else {
      throw new Error("elem not found in addUniqueObjToArray");
    }
  } else {
    return [...arr, obj];
  }
};
const isObjectInArray = <O extends { _id: string }>(arr: O[], obj: O) => {
  return arr.some((objet) => objet._id === obj._id);
};
const addUniqueStringToArray = (arr: string[], str: string) => {
  if (arr.indexOf(str) === -1) {
    return [...arr, str];
  } else {
    return arr;
  }
};
const replaceItemAtIndex = <U>(arr: U[], item: U, idx: number) =>
  arr
    .slice(0, idx)
    .concat(item)
    .concat(arr.slice(idx + 1));
const getWeekDays = (startDate: Date) => {
  return _.range(0, 7).map((step) =>
    moment(startDate).add(step, "days").toDate()
  );
};
const getHmObjFromMinutes = (minutes: number) => {
  const heure = Math.floor(minutes / 60);
  const minute = minutes - heure * 60;
  return { heure, minute };
};

interface HMObj {
  heure: number;
  minute: number;
}

const addToHmObj = (hmObj: HMObj, minutesOrHMObj: number | HMObj) => {
  const initialValue = hmObj.heure * 60 + hmObj.minute;
  if (typeof minutesOrHMObj === "number") {
    return getHmObjFromMinutes(initialValue + minutesOrHMObj);
  } else {
    const valueToAdd = minutesOrHMObj.heure * 60 + minutesOrHMObj.minute;
    return getHmObjFromMinutes(initialValue + valueToAdd);
  }
};

const substractFromHmObj = (hmObj: HMObj, minutesOrHMObj: number | HMObj) => {
  const initialValue = hmObj.heure * 60 + hmObj.minute;
  if (typeof minutesOrHMObj === "number") {
    return getHmObjFromMinutes(initialValue - minutesOrHMObj);
  } else {
    const valueToSubstract = minutesOrHMObj.heure * 60 + minutesOrHMObj.minute;
    return getHmObjFromMinutes(initialValue - valueToSubstract);
  }
};

const isBeforeOrEqual = (hmObj: HMObj, hmObjOrNumber: HMObj | number) => {
  const value = hmObj.heure * 60 + hmObj.minute;
  if (typeof hmObjOrNumber === "number") {
    return value - hmObjOrNumber <= 0;
  } else {
    return value - (hmObjOrNumber.heure * 60 + hmObjOrNumber.minute) <= 0;
  }
};

const schemaForType =
  <T>() =>
  <S extends z.ZodType<T, any, any>>(arg: S) => {
    return arg;
  };

export {
  cleanObject,
  frenchSort,
  removeDuplicateId,
  addUniqueObjToArray,
  addUniqueStringToArray,
  replaceItemAtIndex,
  getWeekDays,
  getHmObjFromMinutes,
  addToHmObj,
  substractFromHmObj,
  isBeforeOrEqual,
  removeEmptyStrings,
  schemaForType,
};
