import { useState } from "react";
import {
  converters,
  getPatientDTOFromTempInput,
  patientToTempInput,
  validate,
  validatorMap,
} from "../../patients/components/EditPatient.tsx";
import PageChooser from "./PageChooser.tsx";
import styles from "./FicheAppelNewPatientCoords.module.css";
import EtatCivilField from "../../patients/components/EtatCivilField.tsx";
import { CadendarConsts } from "cadendar-shared";
import useTempPatient from "../hooks/useTempPatient.ts";
import useOwner from "../../users/hooks/useOwner.ts";
import { newPatientSchema } from "cadendar-shared";
import { trpc } from "../../main/components/MainContainer.tsx";
import { useSelectedPatientIds } from "../../main/state/globalState.ts";
import { convertLocalNewPatientToNewPatient } from "../../famille/components/FamilleGridPanel.tsx";

interface FicheAppelNewPatientCoordsProps {
  onNextPage: () => void;
  onPrevPage: () => void;
}

type keys = keyof typeof etatCivilStrings;

const { etatCivilStrings } = CadendarConsts;

const newPatientFields = Object.keys(etatCivilStrings) as keys[];

const initialTouchedState = Object.keys(etatCivilStrings).reduce(
  (result, key) => ({ ...result, [key]: false }),
  {} as { [K in keys]: boolean }
);

const FicheAppelNewPatientCoords = (props: FicheAppelNewPatientCoordsProps) => {
  const { tempPatient } = useTempPatient();
  const [tempInput, setTempInput] = useState(patientToTempInput(tempPatient));

  const [touchedState, setTouchedState] = useState(initialTouchedState);

  const errors = validate(tempInput);

  const handleTempInput = (key: keys, value: string) => {
    setTempInput((tempInput) => ({ ...tempInput, [key]: value }));
    setTouchedState((state) => ({ ...state, [key]: true }));
  };

  const handleBlur = (key: keys) => {
    const value = tempInput[key];
    if (validatorMap[key](value)) {
      const converted = converters[key](value) || "";
      setTempInput((state) => ({ ...state, [key]: converted }));
    }
  };
  const utils = trpc.useContext();
  const [, { selectPatientId }] = useSelectedPatientIds();
  const commitNewPatientMutation = trpc.patient.create.useMutation({
    onSuccess: (value: string) => {
      utils.patient.invalidate();
      utils.rdv.invalidate(); //on invalide aussi les rdvs car si le nom du patient est modifié ça modifie le rdv
      selectPatientId(value);
      props.onNextPage();
    },
  });
  const owner = useOwner();
  const handleNextPage = () => {
    if (!owner) throw new Error("owner is null in onSubmit");
    console.log("tempInput", tempInput);
    console.log("tempPatient", tempPatient);
    const newPatientDTO = {
      ...convertLocalNewPatientToNewPatient(tempPatient),
      ...getPatientDTOFromTempInput(tempInput),
    };
    commitNewPatientMutation.mutate(newPatientSchema.parse(newPatientDTO));
  };
  return (
    <div className={styles.width}>
      <div>
        {newPatientFields.map((key) => (
          <EtatCivilField
            errors={errors[key]}
            label={etatCivilStrings[key]}
            id={key}
            name={key}
            value={tempInput[key] || ""}
            onChange={(value) => handleTempInput(key, value)}
            onBlur={() => {
              handleBlur(key);
            }}
            touched={touchedState[key]}
            isEditing={true}
            key={"EtatCivilFieldKey" + key}
            isValid={validatorMap[key](tempInput[key])}
          />
        ))}
      </div>
      <PageChooser
        prevPageEnabled={true}
        nextPageEnabled={Object.keys(errors).length === 0}
        onPrevPage={props.onPrevPage}
        onNextPage={handleNextPage}
        prevPageText="Précédent"
        nextPageText="Suivant"
      />
    </div>
  );
};

export default FicheAppelNewPatientCoords;
