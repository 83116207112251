import { CadendarConsts, Collections } from "cadendar-shared";

export type CancelledRdvCase = {
  type:
    | typeof CadendarConsts.typeConsts.ANN
    | typeof CadendarConsts.typeConsts.ANNTARD;
  duree: Collections.Rdv["duree"];
  heure: Collections.Rdv["heure"];
  minute: Collections.Rdv["minute"];
  date: Collections.Rdv["date"];
};

const isCancelledRdv = (rdv: unknown): rdv is CancelledRdvCase => {
  return (
    typeof rdv === "object" &&
    rdv !== null &&
    "type" in rdv &&
    (rdv.type === CadendarConsts.typeConsts.ANN ||
      rdv.type === CadendarConsts.typeConsts.ANNTARD)
  );
};

export default isCancelledRdv;
