import styles from "./Select2.module.css";
import classNames from "classnames";

interface SelectComponentProps<TLabel extends string> {
  labels: readonly TLabel[];
  onSelect: (value: TLabel) => void;
  selected: string;
  className: string;
  id?: string;
}

export const SelectComponent = <TLabel extends string>(
  props: SelectComponentProps<TLabel>
) => {
  const arr = ["-----"].concat(props.labels);
  const optionsComps = arr.map(function (label) {
    return (
      <option key={"optionKey" + label + Math.random()} value={label}>
        {" "}
        {label}{" "}
      </option>
    );
  });
  return (
    <select
      onChange={(event) => props.onSelect(event.target.value as TLabel)}
      value={props.selected}
      className={classNames(styles.selectStyle, props.className)}
      id={props.id}
    >
      {optionsComps}
    </select>
  );
};

export default SelectComponent;
