import _ from "lodash";
import styles from "./Select2.module.css";
import classNames from "classnames";

export type Select2Props<
  Keys extends keyof M,
  M extends { [key: string]: string }
> = Select2WithEmptyString<Keys, M> | Select2WithDefaultKey<Keys, M>;

interface Select2WithEmptyString<
  Keys extends keyof M,
  M extends { [key: string]: string }
> {
  keyMap: M;
  onSelect: (key: Keys | undefined) => void;
  defaultString: string;
  selected: Keys | undefined;
  className: string;
  disabled?: boolean;
  testid?: string;
  id?: string;
  width?: string;
  smallHeight?: boolean;
}

interface Select2WithDefaultKey<
  Keys extends keyof M,
  M extends { [key: string]: string }
> {
  keyMap: M;
  onSelect: (key: Keys) => void;
  defaultKey: string;
  selected: Keys;
  className: string;
  disabled?: boolean;
  testid?: string;
  id?: string;
  sort?: boolean;
  width?: string;
  smallHeight?: boolean;
}

const Select2 = <Keys extends keyof M, M extends { [key: string]: string }>(
  props: Select2Props<Keys, M>
) => {
  const style = props.width ? { width: props.width } : {};
  const handleChange = (key: string) => {
    if ("defaultString" in props && key === undefined) {
      props.onSelect(undefined);
    } else {
      props.onSelect(key as Keys);
    }
  };

  const keyLabels =
    "sort" in props && props.sort
      ? _.sortBy(Object.entries(props.keyMap), ([_key, label]) => label)
      : Object.entries(props.keyMap);

  return (
    <select
      onChange={(event) => handleChange(event.target.value)}
      className={classNames(props.className, styles.selectStyle, {
        [styles.small]: props.smallHeight,
      })}
      value={
        props.selected
          ? (props.selected as string)
          : "defaultKey" in props
          ? props.defaultKey
          : undefined
      }
      disabled={props.disabled}
      data-testid={props.testid || ""}
      id={props.id || ""}
      style={style}
    >
      {"defaultString" in props ? (
        <option value={undefined} key={"emptyKey"}>
          {props.defaultString}
        </option>
      ) : null}
      {keyLabels.map(([key, label]) => (
        <option value={key} key={key}>
          {label}
        </option>
      ))}
    </select>
  );
};

export default Select2;
