import PatientLookup from "../../patients/components/PatientLookup";
import { ExistingPatient } from "cadendar-shared";
import _ from "underts";
import useSelectedPatients from "../../patients/hooks/useSelectedPatients";
import styles from "./FamilleExistingPatientAdder.module.css";
import Button from "../../main/components/Button.tsx";
import IconButton from "../../main/components/IconButton.tsx";
import { FaLongArrowAltUp, FaRegArrowAltCircleUp } from "react-icons/fa";
import HInputGroup from "../../main/components/HInputGroup.tsx";
import { trpc } from "../../main/components/MainContainer.tsx";
import { useState } from "react";
import * as React from "react";
import Bold from "../../patients/components/Bold.tsx";
import PartialUnderline from "../../patients/components/PartialUnderline.tsx";
import { formaters } from "vincent-utils";
import LookedUpPatient from "../../patients/components/LookedUpPatient.tsx";
import { Transforms } from "cadendar-shared";
import classNames from "classnames";

interface FamilleExistingPatientAdderProps {
  onAddPatient: (patient: ExistingPatient) => void;
}

const FamilleExistingPatientAdder = (
  props: FamilleExistingPatientAdderProps
) => {
  const [nom, setNom] = useState("");
  const { data: candidates } = trpc.patient.findByName.useQuery(
    { nameLookup: nom },
    { enabled: !!nom && nom.length > 1 }
  );
  const candidatesSorted = candidates
    ? candidates.toSorted(Transforms.patients.curriedSpecialSort(nom))
    : [];
  const { onAddPatient } = props;
  //const underlined = words.map(underline);

  return (
    <div className={styles.mainSpacer}>
      <div className={styles.center}>
        <div className={styles.container}>
          <HInputGroup
            label="Nom"
            value={nom}
            onChange={setNom}
            labelWidth={"20%"}
            inputTestId={"familleAdderNomInput"}
            placeholder="Patient à rechercher"
          />
          {candidatesSorted.map((candidat) => (
            <div
              className={classNames(styles.candidate, styles.tooltip)}
              onClick={() => onAddPatient(candidat)}
              data-testid="familleAdderCandidate"
            >
              <FaRegArrowAltCircleUp size="1.5em" />
              <LookedUpPatient patient={candidat} lookup={nom} />
              <div className={styles.tooltiptext}>
                Ajouter patient à la famille
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FamilleExistingPatientAdder;
