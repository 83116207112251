;

export interface ReasonsListProps {
  reasons: string[];
}

const ReasonsList = (props: ReasonsListProps) => {
  const { reasons } = props;
  const reasonsComps = reasons.map(reason => (
    <li
      className="list-group-item list-group-item-info"
      key={"reason" + Math.random()}
    >
      {reason}
    </li>
  ));
  return (
    <ul className="list-group" id="reasonsList">
      {reasonsComps}
    </ul>
  );
};

export default ReasonsList;
