import styles from "./Input.module.css";
import classNames from "classnames";
import { FaCheck } from "react-icons/fa";

export interface InputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  className?: string;
  width?: string;
  isTouched?: boolean;
  isValid?: boolean;
  readonly?: boolean;
  onBlur?: () => void;
  ["data-testid"]?: string;
}

const Input = (props: InputProps) => {
  const style = props.width ? { width: props.width } : {};
  return (
    <div style={style} className={styles.inputContainer}>
      <input
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder}
        disabled={props.disabled}
        className={classNames(styles.input, props.className, {
          [styles.invalid]: props.isTouched && !props.isValid,
          [styles.valid]:
            "isTouched" in props
              ? props.isTouched && props.isValid
              : props.isValid,
        })}
        id={props.id}
        readOnly={props.readonly}
        onBlur={props.onBlur}
        data-testid={props["data-testid"]}
      />
      <div className={styles.icon}>
        {props.isTouched ? (
          <FaCheck color={props.isValid ? "green" : "red"} />
        ) : null}
      </div>
    </div>
  );
};

export default Input;
