import * as React from "react";
import PartialUnderline from "./PartialUnderline";
import Bold from "./Bold";
import { formaters } from "vincent-utils";
import { ExistingPatient } from "cadendar-shared";
import styles from "./PatientCandidatsList.module.css";
import classNames from "classnames";
import { useLayoutEffect } from "react";

interface PatientCandidatsListBaseProps {
  candidats: ExistingPatient[];
  selected: string[];
  onSelectPatient: (_id: string) => void;
  lookup: string;
  ddnLookup?: string;
}

interface MultipleSelectDisabled {
  showCheckBoxes: false;
}

interface MultipleSelectEnabled {
  showCheckBoxes: true;
  onToggleSelectedPatient: (_id: string) => void;
}

type PatientCandidatsListProps = PatientCandidatsListBaseProps &
  (MultipleSelectDisabled | MultipleSelectEnabled);

//attention selected est un array
const PatientCandidatsList = (props: PatientCandidatsListProps) => {
  const { showCheckBoxes, candidats, selected, onSelectPatient, lookup } =
    props;
  const ref = React.useRef<HTMLDivElement>(null);
  // useLayoutEffect(() => {
  //   if (ref.current) {
  //     ref.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "nearest",
  //       inline: "start",
  //     });
  //   }
  // }, [JSON.stringify(candidats)]);

  if (candidats && Array.isArray(candidats)) {
    const comps = candidats.map((candidat) => {
      const isSelected =
        Array.isArray(selected) && selected.includes(candidat._id);
      const CheckComp = showCheckBoxes ? (
        <input
          type="checkbox"
          id="foo"
          data-testid="CandidatPatientCheck"
          checked={isSelected}
          key={"checkBoxPat" + candidat._id}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={() => {
            props.onToggleSelectedPatient(candidat._id);
          }}
        />
      ) : (
        ""
      );
      const bold = (comp: React.ReactElement) => <Bold component={comp} />;
      const underline = (text: string, lookup: string) => (
        <PartialUnderline string={text} substr={lookup} />
      );
      const words: [string, string, string | undefined] = [
        formaters.capitaliseFirstLetter(candidat.nom),
        formaters.capitaliseFirstLetter(candidat.prenom),
        candidat.ddn,
      ];
      //const underlined = words.map(underline);
      const nom = bold(underline(words[0], lookup));
      const prenom = words[1];
      let ddn;
      if (
        words[2] &&
        "ddnLookup" in props &&
        typeof props.ddnLookup === "string"
      ) {
        ddn = words[2] ? underline(words[2], props.ddnLookup) : "";
      }

      return (
        <div
          key={candidat._id}
          data-testid="CandidatPatientLine"
          id={"candidat_" + candidat._id}
          className={classNames({
            [styles.candidate]: true,
            [styles.selected]: isSelected,
          })}
          onClick={() => {
            onSelectPatient(candidat._id);
          }}
          ref={isSelected ? ref : undefined}
        >
          {CheckComp}
          <span className={styles.wordSpacing}>
            {nom} {prenom} {ddn}
          </span>
        </div>
      );
    });
    return <>{comps}</>;
  } else {
    return <></>;
  }
};
export default PatientCandidatsList;
