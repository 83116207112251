import { useEffect, useState } from "react";
import SelectComponent from "../../../components/Select";
import RemindersParams from "./RemindersParams";
import BlocsSettings from "./BlocsSettings";
import { usePreferences } from "../../main/state/globalState";
import { Collections } from "cadendar-shared";
import { trpc } from "../../main/components/MainContainer";
import type { WithPartial } from "underts";
import _ from "underts";

const itemNames = {
  débutHeureAffichage:
    "Heure à partir de laquelle les rendez vous sont affichés",
  finHeureAffichage: "Heure de fin d'affichage des rendez vous",
  duréeMinRdv: "Durée minimum d'un RDV",
  duréeDefautRdv: "Durée par défaut d'un RDV",
};

const duréeLabels = _.range(10, 65, 5).map((n) => n.toString());

const PréférencesMainPanel = () => {
  const [tempPrefs, setTempPrefs] = useState<
    WithPartial<Collections.Preferences, "_id" | "owner">
  >({
    duréeMinRdv: 15,
    duréeDefautRdv: 30,
    divisionDuTemps: 15,
    deuxiemeDivisionDuTemps: 15,
  });
  const prefs = usePreferences();
  useEffect(() => {
    if (prefs) {
      setTempPrefs(prefs);
    }
  }, [JSON.stringify(prefs)]);

  const onDuréeMinSelect = (value: string) => {
    setTempPrefs((prev) => {
      if (prev) {
        prev.duréeMinRdv = parseInt(value);
        prev.duréeDefautRdv = parseInt(value);
        return prev;
      }
      return prev;
    });
  };

  const onSetUnlockedDays = (value: string) => {
    setTempPrefs((prev) => {
      if (prev) {
        prev.blocs
          ? (prev.blocs.unlockedDays = parseInt(value))
          : (prev.blocs = { unlockedDays: parseInt(value), ratio: 0 });
        return prev;
      }
      return prev;
    });
  };

  const onSetRatio = (value: string) => {
    setTempPrefs((prev) => {
      if (prev) {
        prev.blocs
          ? (prev.blocs.ratio = parseInt(value))
          : (prev.blocs = { unlockedDays: 0, ratio: parseInt(value) });
        return prev;
      }
      return prev;
    });
  };

  const isTempPrefsDirty = () => {
    return tempPrefs && JSON.stringify(tempPrefs) !== JSON.stringify(prefs);
  };

  const isCompleteForm =
    Collections.newPreferencesSchema.safeParse(tempPrefs).success;

  const onDuréeDefautSelect = (value: string) => {
    setTempPrefs((prev) => {
      if (prev) {
        prev.duréeDefautRdv = parseInt(value);
        return prev;
      }
      return prev;
    });
  };
  const trpcUtils = trpc.useContext();
  const newPreferencesSaveMutation = trpc.preference.saveNewPrefs.useMutation({
    onSettled: () => {
      trpcUtils.preference.invalidate();
    },
  });
  const editPreferencesSaveMutation =
    trpc.preference.saveEditedPrefs.useMutation({
      onSettled: () => {
        trpcUtils.preference.invalidate();
      },
    });
  const onTimePrefSave = () => {
    if ("_id" in tempPrefs) {
      editPreferencesSaveMutation.mutate(
        Collections.existingPreferencesSchema.parse(tempPrefs)
      );
    } else {
      newPreferencesSaveMutation.mutate(
        Collections.newPreferencesSchema.parse(tempPrefs)
      );
    }
  };
  return (
    <div>
      <div>
        {itemNames["duréeMinRdv"]}:{" "}
        <span>
          {tempPrefs && tempPrefs["duréeMinRdv"]} minutes (réglage actuel)
        </span>
        <SelectComponent
          labels={duréeLabels}
          onSelect={onDuréeMinSelect}
          selected={(tempPrefs && tempPrefs.duréeMinRdv.toString()) || ""}
          id="minRdvLengthChooser"
          className=""
        />
      </div>

      <div>
        {itemNames["duréeDefautRdv"]}:{" "}
        <span>
          {tempPrefs && tempPrefs["duréeDefautRdv"]} minutes (réglage actuel)
        </span>
        <SelectComponent
          labels={duréeLabels}
          onSelect={onDuréeDefautSelect}
          selected={(tempPrefs && tempPrefs.duréeMinRdv.toString()) || ""}
          id="rdvDefLengthChooser"
          className=""
        />
      </div>

      <input
        className="btn btn-default"
        type="button"
        id="savePrefButton"
        disabled={!isTempPrefsDirty || !isCompleteForm}
        onClick={onTimePrefSave}
        value="Enregistrer"
      />
      <RemindersParams />
      <BlocsSettings
        onSetUnlockedDays={onSetUnlockedDays}
        unlockedDays={prefs?.blocs?.unlockedDays.toString() || ""}
        onSetRatio={onSetRatio}
        ratio={prefs?.blocs?.ratio.toString() || ""}
      />
    </div>
  );
};

export default PréférencesMainPanel;
