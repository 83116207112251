import _ from "lodash";
import { checkers } from "vincent-utils";
import { removeDuplicateId } from "../../../utils/utils.ts";
import { ExistingPatient } from "cadendar-shared";
import { Transforms } from "cadendar-shared";
const uniqPatients = (patients: ExistingPatient[]) =>
  removeDuplicateId(patients);
const sortedPatients = (nomLookup: string) => (patients: ExistingPatient[]) =>
  patients.sort(Transforms.patients.curriedSpecialSort(nomLookup));
//on ne montre les résultats que si il y a une recherche valide
const showPatient =
  (nomLookup: string, ddnLookup: string) => (patients: ExistingPatient[]) =>
    (nomLookup && nomLookup.length > 1) || checkers.isValidDdn(ddnLookup)
      ? patients
      : [];
export default (
  allPatients: ExistingPatient[],
  nomLookup: string,
  ddnLookup: string
) =>
  _.flow(
    sortedPatients(nomLookup),
    uniqPatients,
    showPatient(nomLookup, ddnLookup)
  )(allPatients);
export { showPatient };
