import VInputGroup from "../../main/components/VInputGroup.tsx";

interface EtatCivilFieldProps {
  isValid: boolean;
  errors: string | undefined;
  touched: boolean;
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  id: string; //pour formik
  name: string; //pour formik
  isEditing: boolean;
}

const EtatCivilField = (props: EtatCivilFieldProps) => {
  const { isValid, label, value, onChange, onBlur, isEditing } = props;
  return (
    <div className="">
      <VInputGroup
        label={label}
        value={value}
        onChange={onChange}
        readonly={!isEditing}
        placeholder={label}
        id={"new_patient_" + label.split(" ").join("_") + "_input_field"}
        onBlur={onBlur}
        isValid={isEditing ? isValid : undefined}
      />
    </div>
  );
};

export default EtatCivilField;
