import DejaVenu from "./DejaVenu.tsx";
import useFicheAppel from "../hooks/useFicheAppel.tsx";
import PatientEntry from "./PatientEntry.tsx";
import PageChooser from "./PageChooser.tsx";
import PatientsCandidatsWidget from "./PatientsCandidatsWidget.tsx";
import Button from "../../main/components/Button.tsx";

interface FirstPageProps {
  onNextPage: () => void;
}

const FirstPage = (props: FirstPageProps) => {
  const {
    dejaVenu,
    ddn,
    ddnLookup,
    nom,
    prenom,
    onPrenomChange,
    onDDNChange,
    onNomChange,
    isValidDdn,
    setDejaVenu,
    isNewPatientMode,
    candidates,
    showPatientEntry,
    onResetFicheAppel,
  } = useFicheAppel();
  const showNext = isValidDdn && prenom && ddn;
  console.log("isNewPatientMode", isNewPatientMode);
  return (
    <div>
      <Button
        title="Nouvelle Fiche"
        onClick={onResetFicheAppel}
        style="danger"
      />
      <DejaVenu
        dejaVenuOui={dejaVenu === true}
        dejaVenuNon={dejaVenu === false}
        setDejaVenu={setDejaVenu}
      />
      {dejaVenu !== null ? (
        <PatientEntry
          ddn={ddnLookup}
          nom={nom}
          prenom={prenom}
          onNomChange={onNomChange}
          onPrenomChange={onPrenomChange}
          onDDNChange={onDDNChange}
          isValidDdn={isValidDdn}
          isNewPatientMode={isNewPatientMode}
        />
      ) : null}
      <PatientsCandidatsWidget
        isNewPatientMode={isNewPatientMode}
        candidats={candidates}
      />
      {showNext ? (
        <PageChooser
          onNextPage={props.onNextPage}
          prevPageEnabled={false}
          nextPageEnabled={true}
          nextPageText="Suivant"
        />
      ) : null}
    </div>
  );
};

export default FirstPage;
