import { useEffect } from "react";
import { atom, useAtom, useSetAtom } from "jotai";
import {
  aptRequestUsedAtom,
  isStrategorRunningAtom,
  PatientByDDNLookupState,
  PatientByNameLookupState,
  useSelectedPatientIds,
} from "../../main/state/globalState";
import filterAndSortCandidates from "../../patients/containers/integration";
import useRdvProposal from "./useRdvProposal";
import { trpc } from "../../main/components/MainContainer";
import useTempPatient from "./useTempPatient.ts";
import useSelectedPatients from "../../patients/hooks/useSelectedPatients.ts";
import { DDNSchema } from "cadendar-shared";

const dejaVenuAtom = atom<null | boolean>(null);
const prenomAtom = atom<string>("");

export default () => {
  const [dejaVenu, setDejaVenu] = useAtom(dejaVenuAtom);
  const [selectedIds, { resetSelectedPatientIds }] = useSelectedPatientIds();

  const [nomLookup, setNomLookup] = useAtom(PatientByNameLookupState);
  const [prenom, setPrenom] = useAtom(prenomAtom);
  const [ddnLookup, setDDNLookup] = useAtom(PatientByDDNLookupState);

  const selectedPatients = useSelectedPatients();
  useEffect(() => {
    if (selectedPatients.length > 0) {
      setDejaVenu(true);
      setNomLookup(selectedPatients[0].nom);
      setPrenom(selectedPatients[0].prenom);
      setDDNLookup(selectedPatients[0].ddn);
    }
  }, [JSON.stringify(selectedPatients)]);

  const nom = nomLookup;
  const {
    onSetPrenom: onSetTempPatientPrenom,
    onSetNom: onSetTempPatientNom,
    onSetDdn: onSetTempPatientDdn,
    onReset: onResetTempPatient,
  } = useTempPatient();
  const setIsStrategorRunning = useSetAtom(isStrategorRunningAtom);

  const onNomChange = (nom: string) => {
    setNomLookup(nom);
    onSetTempPatientNom(nom);
  };
  const onPrenomChange = (prenom: string) => {
    setPrenom(prenom);
    onSetTempPatientPrenom(prenom);
  };
  const onDDNChange = (ddn: string) => {
    setDDNLookup(ddn);
    onSetTempPatientDdn(ddn);
  };
  const onSetDejaVenu = (dejaVenu: boolean) => {
    if (dejaVenu === true) {
      setDejaVenu(true);
      return;
    }
    if (dejaVenu === false) {
      setDejaVenu(false);
      resetSelectedPatientIds();
      return;
    }
  };
  const { data: candidatesByName } = trpc.patient.findByName.useQuery(
    { nameLookup: nomLookup },
    {
      enabled: !!nomLookup && nomLookup.length > 1,
    }
  );
  // const [candidatesByName] = useSuperQuery(
  //   ["patients", "byName", nomLookup],
  //   patientsByNameLookupFetch,
  //   [],
  //   { enabled: nomLookup.length > 1 },
  //   nomLookup
  // );
  const parsedDDNLookupResult = DDNSchema.safeParse(ddnLookup);
  const isValidDdn = parsedDDNLookupResult.success;
  const { data: candidatesByDDN } = trpc.patient.findByDDN.useQuery(
    {
      ddnLookup:
        "data" in parsedDDNLookupResult ? parsedDDNLookupResult.data : "",
    },
    { enabled: isValidDdn && parsedDDNLookupResult.success }
  );
  // const [candidatesByDDN] = useSuperQuery(
  //   ["patients", "byDDN", parsedDDNLookup],
  //   patientsByDDNLookupFetch,
  //   [],
  //   { enabled: isValidDdn && !!parsedDDNLookup },
  //   parsedDDNLookup
  // );

  const ddn = parsedDDNLookupResult.success ? parsedDDNLookupResult.data : null;
  const candidates = filterAndSortCandidates(
    [...(candidatesByName || []), ...(candidatesByDDN ? candidatesByDDN : [])],
    nomLookup,
    ddnLookup
  );
  const isDejaVenu = dejaVenu || selectedIds.length > 0;
  const dejaVenuResponded = dejaVenu !== null;
  const isNewPatientMode = dejaVenuResponded && !isDejaVenu;
  const showPatientEntry = dejaVenuResponded && isDejaVenu;
  const showAdresseursPanel = isNewPatientMode;
  const { clearRdvProposal } = useRdvProposal();
  const setAptRequestUsed = useSetAtom(aptRequestUsedAtom);
  const onResetFicheAppel = () => {
    resetSelectedPatientIds();
    setDejaVenu(null);
    setNomLookup("");
    setPrenom("");
    setDDNLookup("");
    setAptRequestUsed(null);
    onResetTempPatient();
    setIsStrategorRunning(false);
    clearRdvProposal();
  };
  return {
    nom,
    prenom,
    ddn,
    ddnLookup,
    dejaVenu,
    setDejaVenu: onSetDejaVenu,
    onPrenomChange,
    onDDNChange,
    onNomChange,
    onResetFicheAppel,
    showPatientEntry,
    showAdresseursPanel,
    isValidDdn,
    isNewPatientMode,
    candidates,
  };
};
