import RdvCase, { RdvCaseProps } from "./RdvCase";
import _ from "underts";
import { AgendaCase } from "cadendar-shared";
import styles from "./DayRdvs.module.css";
import classNames from "classnames";
import EmptyCase from "./EmptyCase.tsx";

export type DayRdvsProps = Omit<RdvCaseProps, "rdv"> & {
  rdvs: AgendaCase[];
  heightMultiplier: number;
};

const familyColorsFromRdvs = <
  T extends { heure: number; minute: number; famille_id?: string }
>(
  rdvs: T[]
): (T & { familyColor?: string })[] => {
  const cssColors = [
    "blue",
    "skyblue",
    "aqua",
    "yellow",
    "lightsalmon",
    "fuchsia",
    "slategray",
    "goldenrod",
    "purple",
  ];
  const familyGrouped = _.groupBy(rdvs, "famille_id") as { [key: string]: T[] };
  const colorsApplied = Object.keys(familyGrouped).reduce(
    (result, key, idx) => {
      return {
        ...result,
        [key]:
          key !== "undefined" && familyGrouped[key].length > 1
            ? familyGrouped[key].map((rdv) => ({
                ...rdv,
                familyColor: cssColors[idx],
              }))
            : [...familyGrouped[key]],
      };
    },
    {} as { [key: string]: (T & { familyColor?: string })[] }
  );
  return Object.values(colorsApplied)
    .flat()
    .sort(
      (rdv1, rdv2) =>
        rdv1.heure * 60 + rdv1.minute - (rdv2.heure * 60 + rdv2.minute)
    );
};

const DayRdvs = (props: DayRdvsProps) => {
  const { rdvs, heightMultiplier } = props;
  const { onCaseClick, onCloseCrossClick, onConfirmClick } = props;
  const { onMouseEnter, onMouseLeave } = props;
  const { now } = props;
  const rdvComps = familyColorsFromRdvs(rdvs).map((rdv) => {
    if (rdv.tag === "empty") {
      return (
        <EmptyCase
          key={"DaysRdvsKey_" + (rdv.date.getTime() + rdv.heure + rdv.minute)}
          rdv={rdv}
          heightMultiplier={heightMultiplier}
          onCaseClick={onCaseClick}
        />
      );
    } else {
      return (
        <RdvCase
          rdv={rdv}
          onCaseClick={onCaseClick}
          onCloseCrossClick={onCloseCrossClick}
          onConfirmClick={onConfirmClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          key={
            "_id" in rdv
              ? rdv._id
              : "DaysRdvsKey_" + (rdv.date.getTime() + rdv.heure + rdv.minute)
          }
          now={now}
          heightMultiplier={heightMultiplier}
        />
      );
    }
  });
  return (
    <div
      className={classNames("rdvsdujour", styles.paddingTop, styles.aptCol)}
      data-testid={"DayRdvs"}
    >
      {rdvComps}
    </div>
  );
};

export default DayRdvs;
