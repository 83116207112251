import {DateTime} from "luxon";

interface HoraireDisplayChooserProps {
  onChooseNextHoraire: () => void;
  onChoosePrevHoraire: () => void;
  nextEnabled: boolean;
  prevEnabled: boolean;
  dateChoosen: Date;
}

const HoraireDisplayChooser = (props:HoraireDisplayChooserProps) => {
  return (
    <div className="row">
      Horaires depuis la Semaine du{" "}
      <div className="btn-group">
        <button
          type="button"
          id="button_prev_week"
          className="btn btn-default "
          onClick={props.onChoosePrevHoraire}
          disabled={!props.prevEnabled}
        >
          <span className="glyphicon glyphicon-arrow-left"></span>
        </button>
        <button
          type="button"
          id="button_today"
          className="btn btn-default "
          disabled={true}
        >
          {DateTime.fromJSDate(props.dateChoosen).toLocaleString(DateTime.DATE_MED)}
        </button>
        <button
          type="button"
          id="button_next_week"
          className="btn btn-default "
          onClick={props.onChooseNextHoraire}
          disabled={!props.nextEnabled}
        >
          <span className="glyphicon glyphicon-arrow-right"></span>
        </button>

      </div>
    </div>
  )
}

export default HoraireDisplayChooser;
