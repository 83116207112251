import { Collections } from "cadendar-shared";
import { SmsCountMap } from "../containers/UsersManagerContainer";
import Select2 from "../../../components/Select2";
import BluredInput from "../../../components/BluredInput";

interface UsersManagerProps {
  users: Collections.User[];
  smsCountMap: SmsCountMap;
  onUpdateFullName: (userId: string, fullName: string) => void;
  onUpdateMessageName: (userId: string, messageName: string) => void;
  onUpdateActiveStatus: (userId: string, active: boolean) => void;
  onAddUser: (
    receptorId: string,
    index: number,
    userIdToAdd: string | null
  ) => void;
  onAssignRole: (
    userId: string,
    role: "docteur" | "secrétaire" | undefined
  ) => void;
}

const UsersManager = (props: UsersManagerProps) => {
  const doctorsKeyMap = props.users
    .filter((u) => u.tag === "doctor")
    .map((u) => u._id)
    .reduce(
      (result, id) => ({
        ...result,
        [id]: props.users.find((u) => u._id === id)!.fullname || "",
      }),
      {} as { [key: string]: string }
    );
  const secretariesKeyMap = props.users
    .filter((u) => u.tag === "secretary")
    .map((u) => u._id)
    .reduce(
      (result, id) => ({
        ...result,
        [id]: props.users.find((u) => u._id === id)!.fullname || "",
      }),
      {} as { [key: string]: string }
    );
  return (
    <table>
      <thead>
        <tr>
          <td>Login</td>
          <td>Actif</td>
          <td>Nom Complet</td>
          <td>Nom sur les messages</td>
          <td>Sms</td>
          <td>Rôle</td>
        </tr>
      </thead>
      <tbody>
        {props.users.map((user) => (
          <tr key={user._id + "userKey"}>
            <td>{user.username}</td>
            <td>
              <input
                type="checkbox"
                checked={user.active}
                onChange={() =>
                  props.onUpdateActiveStatus(user._id, !user.active)
                }
              />
            </td>
            <td>
              <BluredInput
                initialValue={user.fullname || ""}
                onBlur={(value) => props.onUpdateFullName(user._id, value)}
                className={""}
              />
            </td>
            <td>
              <BluredInput
                initialValue={user.messagename || ""}
                className={""}
                onBlur={(value) => props.onUpdateMessageName(user._id, value)}
              />
            </td>
            <td>{props.smsCountMap[user._id]}</td>
            <td>
              <Select2
                keyMap={{ docteur: "Docteur", secrétaire: "Secrétaire" }}
                defaultString="Pas de rôle"
                onSelect={(role: "docteur" | "secrétaire" | undefined) =>
                  props.onAssignRole(user._id, role)
                }
                selected={
                  user.tag === "doctor"
                    ? "docteur"
                    : user.tag === "secretary"
                    ? "secrétaire"
                    : undefined
                }
                className={""}
              />
            </td>
            {user.tag === "secretary" ? (
              <>
                {user.doctor_id ? (
                  user.doctor_id.map((id, idx) => (
                    <td key={id + "doctorSelectKey"}>
                      <Select2
                        keyMap={doctorsKeyMap}
                        defaultString="vide"
                        onSelect={(_id: string | undefined) => {
                          if (!_id) return;
                          props.onAddUser(user._id, idx, _id);
                        }}
                        selected={id}
                        className={""}
                      />
                    </td>
                  ))
                ) : (
                  <></>
                )}
                <td
                  key={
                    (user.doctor_id &&
                      user.doctor_id.length + "doctorSelectKey") ||
                    0 + "doctorSelectKey"
                  }
                >
                  <Select2
                    keyMap={doctorsKeyMap}
                    defaultString="vide"
                    onSelect={(_id: string | undefined) => {
                      _id && props.onAddUser(user._id, 0, _id);
                    }}
                    selected={undefined}
                    className={""}
                  />
                </td>
              </>
            ) : (
              <>
                {user.tag === "doctor" ? (
                  user.secretaries?.map((id, idx) => (
                    <td key={id + "secrétaireSelectKey"}>
                      <Select2
                        keyMap={secretariesKeyMap}
                        defaultString="vide"
                        onSelect={(_id: string | undefined) => {
                          if (!_id) return;
                          props.onAddUser(user._id, idx, _id);
                        }}
                        selected={id}
                        className={""}
                      />
                    </td>
                  ))
                ) : (
                  <></>
                )}
                <td
                  key={
                    (user.tag === "doctor" &&
                      user.secretaries &&
                      user.secretaries.length + "secrétaireSelectKey") ||
                    0 + "secrétaireSelectKey"
                  }
                >
                  <Select2
                    keyMap={secretariesKeyMap}
                    defaultString="vide"
                    onSelect={(_id: string | undefined) => {
                      _id && props.onAddUser(user._id, 0, _id);
                    }}
                    selected={undefined}
                    className={""}
                  />
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UsersManager;
