import { formaters } from "vincent-utils";
import Button from "../../main/components/Button.tsx";
import styles from "./RdvCandidat.module.css";

export interface Candidat {
  score: number;
  date: Date;
  heure: number;
  minute: number;
}

export interface RdvCandidatProps {
  isPatientSelected: boolean;
  candidat: Candidat;
  onCreatePatient: () => void;
  onCreateRdv: (candidat: Candidat) => void;
}

const RdvCandidat = (props: RdvCandidatProps) => {
  const { isPatientSelected, onCreatePatient, onCreateRdv, candidat } = props;
  const button = isPatientSelected ? (
    <Button
      title="Créer RDV"
      onClick={() => onCreateRdv(candidat)}
      size="small"
      style="main"
    />
  ) : (
    <Button title="Créer Patient" onClick={onCreatePatient} size="small" />
  );
  return (
    <>
      <div className={styles.date}>
        {formaters.formatDateForDisplayWithDay(candidat.date)}{" "}
        {formaters.formatHMObjectForDisplay(candidat)}
      </div>{" "}
      <div className={styles.score}>score:{candidat.score}</div>
      <div className={styles.button}>{button}</div>
    </>
  );
};

export default RdvCandidat;
