import CentralComp from "../components/CentralComp";
import { useAtom } from "jotai";
import moment from "moment";
import { selectedWeekAtom } from "../../main/state/globalState";
import { formaters } from "vincent-utils";
import useUser from "../../users/hooks/useUser.ts";

const CentralCompContainer2 = () => {
  const [weekSelected, setWeekSelected] = useAtom(selectedWeekAtom);
  const user = useUser();
  const setPrevWeek = () =>
    setWeekSelected((weekSelected) =>
      moment(weekSelected).subtract(7, "days").toDate()
    );
  const setNextWeek = () =>
    setWeekSelected((weekSelected) =>
      moment(weekSelected).add(7, "days").toDate()
    );
  const setThisWeek = () =>
    setWeekSelected(formaters.getDateDuLundiFromDateTime(new Date()));
  const year = weekSelected.getFullYear();
  const weekNumber = moment(weekSelected).week();
  return (
    <CentralComp
      onNextWeek={setNextWeek}
      onPrevWeek={setPrevWeek}
      onThisWeek={setThisWeek}
      year={year}
      weekNumber={weekNumber}
      user={user || null}
    />
  );
};

export default CentralCompContainer2;
