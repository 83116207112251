import { useEffect, useState } from "react";
import _ from "underts";
import React from "react";
export const useLocalEditState = <U>(
  initialValue: U | undefined,
  defaultValue: U
) => {
  let init = typeof initialValue === "undefined" ? defaultValue : initialValue;
  const [localState, setLocalState] = useState<U>(init);
  const changed = localState !== init;
  return [localState, setLocalState, changed] as const;
};

//hook de state qui s'initialise quand init est prêt
//mettre un blankDefault en attendant d'avoir la vraie valeur
export const useInitialized = <U>(init: U | undefined | null, def: U) => {
  const initializer = init || def;
  const [state, setState] = useState(initializer);
  const [revertState, setRevertState] = useState<null | U>(null);
  //si on a un initializer et que le state est encore le défaut, on le remplace par l'initializer
  useEffect(() => {
    if (init && _.isEqual(state, def)) {
      setState(init);
      setRevertState(init);
    }
  }, [JSON.stringify(init)]);

  const resetState = () => {
    if (revertState) {
      setState(revertState);
    } else {
      setState(def);
    }
  };
  return [state, setState, resetState] as const;
};

export const useToggle = (initialValue: boolean) => {
  const [state, setState] = useState(initialValue);
  const toggle = () => setState(!state);
  return [state, toggle] as const;
};

export const useResetable = <U>(initialValue: U) => {
  const [state, setState] = useState(initialValue);
  const reset = () => setState(initialValue);
  return [state, setState, reset] as const;
};
