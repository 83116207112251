import styles from "./HSelectGroup.module.css";

interface HSelectGroupProps {
  label: string;
  id?: string;
  labelWidth?: string;
  defaultKey?: string;
  className?: string;
  children: React.ReactNode;
}

const HSelectGroup = (props: HSelectGroupProps) => {
  const labelStyle = props.labelWidth ? { width: props.labelWidth } : {};

  return (
    <div className={styles.spacer}>
      <label htmlFor={props.label + "_chooser"} style={labelStyle}>
        {props.label}
      </label>
      {props.children}
    </div>
  );
};

export default HSelectGroup;
