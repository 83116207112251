import InputSetting from "../../../components/InputSetting";
import { z } from "zod";

interface BlocsSettingsProps {
  unlockedDays: string;
  onSetUnlockedDays: (value: string) => void;
  ratio: string;
  onSetRatio: (value: string) => void;
}
const BlocsSettings = (props: BlocsSettingsProps) => {
  const { unlockedDays, onSetUnlockedDays } = props;
  const { ratio, onSetRatio } = props;
  const unlockedDaysValidator = (value: string) => {
    if (!z.coerce.number().int().gte(0).safeParse(value).success) {
      return "la valeur doit être un nombre positif";
    }
    return false;
  };
  const ratioValidator = (value: string) => {
    if (!z.coerce.number().int().gte(0).lte(100).safeParse(value).success) {
      return "la valeur doit être un nombre entre 0 et 100";
    }
    return false;
  };
  return (
    <div>
      <div className="panel panel-info">
        <div className="panel-heading">Réglages des blocs</div>
        <div className="panel-body">
          <div className="form-horizontal">
            <InputSetting
              label="Déblocage des blocs les X derniers jours (jours
                fermés exclus)"
              name="unlockedDaysInput"
              initialValue={unlockedDays}
              onValueSubmitted={onSetUnlockedDays}
              validate={unlockedDaysValidator}
            />
            <div>
              <InputSetting
                label="Pourcentage cible de rdvs rentables"
                name="ratioInput"
                initialValue={ratio}
                onValueSubmitted={onSetRatio}
                validate={ratioValidator}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlocsSettings;
