import React, { useEffect, useState } from "react";
import { formaters } from "vincent-utils";
import {
  CadendarConsts,
  ExistingAptRequest,
  NewAptRequest,
} from "cadendar-shared";
import _ from "underts";
import {
  useMinAptDuration,
  useRdvPopupState,
  useSelectedAptRequest,
  useSelectedWeek,
} from "../../main/state/globalState";
import useSelectedPatients from "../../patients/hooks/useSelectedPatients";
import useOwner from "../../users/hooks/useOwner";
import { useHoraire } from "../../horaires/hooks/useHoraire";
import { trpc } from "../../main/components/MainContainer";
import { Transforms } from "cadendar-shared";
import HSelectGroup from "../../main/components/HSelectGroup.tsx";
import Select2 from "../../../components/Select2.tsx";
import Select from "../../../components/Select.tsx";
import VInputGroup from "../../main/components/VInputGroup.tsx";
import CheckBox from "../../main/CheckBox.tsx";
import Button from "../../main/components/Button.tsx";
import styles from "./AptRequestComp.module.css";
import { FaRegCopy } from "react-icons/fa";
import InputIcon from "../../main/components/InputIcon.tsx";
import classNames from "classnames";

const idToLink = (_id: string) => {
  return `https://patients.cadendar.com/aptRequestLink?id=${_id}`;
};

const getDefaultAptRequest = (duréeMiniRdv: number, patientId: string) => {
  return {
    patientId,
    length: duréeMiniRdv,
    object: "",
    publicObject: "",
    reminderChecked: false,
    confirmChecked: false,
    taken: false,
    canceled: false,
    type: 1,
    rdvId: null,
  };
};

const AptRequestComp = () => {
  //data from selectors
  //const patientSelected = patientsStore.selectedPatient;
  const patientsSelected = useSelectedPatients();
  const patientSelected = _.first(patientsSelected);
  const npDisplay = (patientSelected?.nom &&
    formaters.formatNomPrenomForDisplay(patientSelected)) || {
    nom: "PAS DE PATIENT SéLECTIONNé".toLocaleUpperCase(),
    prenom: "",
  };
  const { selectedWeek } = useSelectedWeek();
  const duréeMiniRdv = useMinAptDuration();
  const horaire = useHoraire(selectedWeek);
  const maxLength = horaire
    ? Transforms.horaires.longestRdvPossible(horaire)
    : 0;
  const dureesPossibles = _.range(
    duréeMiniRdv,
    maxLength + duréeMiniRdv,
    duréeMiniRdv
  );
  const typesPossibles = CadendarConsts.typesNames;
  const owner = useOwner();
  const { aptRequest, onSelectAptRequestId } = useSelectedAptRequest();

  const [tempAptRequest, setTempAptRequest] = useState<
    ExistingAptRequest | NewAptRequest
  >(getDefaultAptRequest(duréeMiniRdv, patientSelected?._id || ""));

  useEffect(() => {
    if (aptRequest) {
      setTempAptRequest(aptRequest);
    }
  }, [JSON.stringify(aptRequest)]);

  const handleDureeChange = (dureeStr: string) => {
    setTempAptRequest((aptRequest) => ({
      ...aptRequest,
      length: parseInt(dureeStr),
    }));
  };

  const handleTypeChange = (type: string) => {
    setTempAptRequest((aptRequest) => ({
      ...aptRequest,
      type: CadendarConsts.getTypeNumberFromTypeName(
        type as (typeof typesPossibles)[number]
      ),
    }));
  };

  const handleObjectChange = (objet: string) => {
    setTempAptRequest((aptRequest) => ({
      ...aptRequest,
      object: objet,
    }));
  };

  const handlePublicObjectChange = (objet: string) => {
    setTempAptRequest((aptRequest) => ({
      ...aptRequest,
      publicObject: objet,
    }));
  };

  const confirmCheckBoxEnabled =
    aptRequest?.confirmChecked ||
    (patientSelected
      ? Transforms.patients.hasConfirmationTarget(patientSelected)
      : false);

  const remindersCheckBoxEnabled =
    aptRequest?.reminderChecked ||
    (patientSelected
      ? Transforms.patients.hasConfirmationTarget(patientSelected)
      : false);
  const onToggleConfirmChecked = () => {
    setTempAptRequest((aptRequest) => ({
      ...aptRequest,
      confirmChecked: !aptRequest.confirmChecked,
    }));
  };

  const onToggleReminderChecked = () => {
    setTempAptRequest((aptRequest) => ({
      ...aptRequest,
      reminderChecked: !aptRequest.reminderChecked,
    }));
  };
  const link = aptRequest?._id ? idToLink(aptRequest._id) : "";

  const hasChanges =
    tempAptRequest.length !== aptRequest?.length ||
    tempAptRequest.type !== aptRequest?.type ||
    tempAptRequest.object !== aptRequest?.object ||
    tempAptRequest.publicObject !== aptRequest?.publicObject ||
    tempAptRequest.reminderChecked !== aptRequest?.reminderChecked ||
    tempAptRequest.confirmChecked !== aptRequest?.confirmChecked;

  const trpcUtils = trpc.useContext();
  const commitAptRequestMutation = trpc.aptRequest.commit.useMutation({
    onSuccess: (_id) => {
      if (_id) {
        onSelectAptRequestId(_id);
      }
    },
    onSettled: () => {
      trpcUtils.aptRequest.invalidate();
    },
  });
  const handleSubmit = () => {
    if (!owner) {
      throw new Error("no owner in handleSubmit in RdvRequest component");
    }
    if (!patientSelected) {
      throw new Error("no patient selected in RdvRequest handleSubmit");
    }
    commitAptRequestMutation.mutate(tempAptRequest);
  };
  const { onCloseRdvPopup } = useRdvPopupState();
  const handleCancel = () => {
    onCloseRdvPopup();
  };

  const deleteAptRequestMutation = trpc.aptRequest.delete.useMutation({
    onSettled: () => {
      trpcUtils.aptRequest.invalidate();
    },
    onSuccess: () => {
      onCloseRdvPopup();
    },
  });
  const handleDelete = () => {
    aptRequest && deleteAptRequestMutation.mutate(aptRequest._id);
  };

  const isNew = !("_id" in tempAptRequest);

  const dureeKeyMap = dureesPossibles.reduce((acc, d) => {
    acc[d.toString()] = d.toString();
    return acc;
  }, {} as { [key: string]: string });

  return (
    <div className={styles.width400}>
      <div className="patient_outline">
        {npDisplay.nom + " " + npDisplay.prenom}
      </div>
      <div>
        <HSelectGroup label="Durée" id="duree_chooser" labelWidth="20%">
          <Select2
            keyMap={dureeKeyMap}
            onSelect={handleDureeChange}
            selected={tempAptRequest.length.toString()}
            defaultKey={""}
            className={""}
            id={"duree_chooser"}
          />
        </HSelectGroup>
        <HSelectGroup label="Type" labelWidth="20%" id="type_chooser">
          <Select
            className=""
            labels={typesPossibles.sort()}
            selected={CadendarConsts.getTypeNameFromTypeNumber(
              tempAptRequest.type
            )}
            onSelect={handleTypeChange}
            id={"type_chooser"}
          />
        </HSelectGroup>
        <VInputGroup
          label={"Objet interne (non communiqué au patient)"}
          value={tempAptRequest.object}
          onChange={handleObjectChange}
          id="rdv_objet_field"
        />
        <VInputGroup
          label={"Objet public (communiqué au patient)"}
          value={tempAptRequest.publicObject}
          onChange={handlePublicObjectChange}
          id="rdv_public_object_field"
        />
        <CheckBox
          label="Envoyer MAIL/SMS"
          checked={tempAptRequest.reminderChecked}
          onChange={onToggleReminderChecked}
          disabled={!remindersCheckBoxEnabled}
          id={
            remindersCheckBoxEnabled
              ? "send_reminder"
              : "send_reminder_disabled"
          }
        />
        <CheckBox
          checked={tempAptRequest.confirmChecked}
          onChange={onToggleConfirmChecked}
          disabled={!confirmCheckBoxEnabled}
          label="Demander Confirmation"
          id={confirmCheckBoxEnabled ? "send_confirm" : "send_confirm_disabled"}
        />
        <div className={styles.verticalSpacer}>
          <div className={styles.horizontalSpacer}>
            {isNew ? (
              <Button
                title="Créer lien"
                onClick={handleSubmit}
                id="createLinkButton"
                style="main"
              />
            ) : null}
            {link ? (
              <div
                className={classNames(styles.verticalSpacer, styles.width100)}
              >
                <label className="">Lien à copier</label>
                <InputIcon
                  value={link}
                  onChange={() => navigator.clipboard.writeText(link)}
                  id="copyLinkButton"
                >
                  <FaRegCopy size="1.3rem" />
                </InputIcon>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.horizontalSpacer}>
          {hasChanges ? (
            <>
              {!isNew ? (
                <Button
                  title={"Enregistrer"}
                  onClick={handleSubmit}
                  style="main"
                  id="button_save_aptRequest"
                />
              ) : null}
              <Button
                title="Annuler"
                onClick={handleCancel}
                style="secondary"
                id="button_cancel_save_aptRequest"
              />
            </>
          ) : (
            <Button
              title="Ok"
              onClick={onCloseRdvPopup}
              style="main"
              id={"button_close_AptRequestComp"}
            />
          )}
          {aptRequest ? (
            <Button
              title={"Supprimer lien"}
              onClick={handleDelete}
              style="danger"
              id={"button_delete_AptRequest"}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AptRequestComp;
