import { useEffect } from "react";
import poster from "../../../utils/poster.ts";

const Logout = () => {
  useEffect(() => {
    poster("cadendarRest/logout", {}).then((res) => {
      if (res) {
        window.location.href = "/";
      }
    });
  }, []);
  return null;
};

export default Logout;
