import { trpc } from "../../main/components/MainContainer";

const OnlineUsers = () => {
  const { data: users } = trpc.user.getOnlineUsers.useQuery();

  return users ? (
    <div id="online_users">
      {users.map((user) => (
        <span
          className={user.tag === "doctor" ? "doctor" : "ts"}
          key={"OnlineUserKey" + user._id}
        >
          {user.messagename}
        </span>
      ))}
    </div>
  ) : null;
};

export default OnlineUsers;
