import { useEffect, useState } from "react";
import { trpc } from "../../main/components/MainContainer";
import { Collections } from "cadendar-shared";
import convertTextForDisplay from "../filtersTransforms/convertTextForDisplay";
import {
  remainingChars,
  remainingSenderChars,
} from "../filtersTransforms/countChars";
import {
  existingPreferencesSchema,
  newPreferencesSchema,
} from "cadendar-shared";

import { z } from "zod";

const RemindersParams = () => {
  const [tempPrefs, setTempPrefs] = useState<
    Collections.ExistingPreferences | Collections.NewPreferences | null
  >(null);
  const { data: existingPrefs } = trpc.preference.get.useQuery();
  useEffect(() => {
    if (existingPrefs) {
      setTempPrefs(existingPrefs);
    }
  }, [JSON.stringify(existingPrefs)]);
  const [isEditingEmailTxt, setIsEditingEmailTxt] = useState(false);
  const [isEditingSmsTxt, setIsEditingSmsTxt] = useState(false);
  const [isEditingSmsName, setIsEditingSmsName] = useState(false);
  const [isEditingEmailExpediteur, setIsEditingEmailExpediteur] =
    useState(false);
  const [isEditingConfirmText, setIsEditingConfirmText] = useState(false);
  const onSetEmailText = (value: string) => {
    setTempPrefs((prev) => {
      if (prev) {
        return { ...prev, emailTxt: value };
      }
      return prev;
    });
  };
  const onSetSmsText = (value: string) => {
    setTempPrefs((prev) => {
      if (prev) {
        return { ...prev, smsTxt: value };
      }
      return prev;
    });
  };

  const onSetSmsName = (value: string) => {
    setTempPrefs((prev) => {
      if (prev) {
        return { ...prev, smsName: value };
      }
      return prev;
    });
  };

  const onSetEmailExpediteur = (value: string) => {
    setTempPrefs((prev) => {
      if (prev) {
        return { ...prev, emailExpediteur: value };
      }
      return prev;
    });
  };

  const onSetConfirmText = (value: string) => {
    setTempPrefs((prev) => {
      if (prev) {
        return { ...prev, confirmTxt: value };
      }
      return prev;
    });
  };

  const emailTextDisplay = tempPrefs?.emailTxt
    ? convertTextForDisplay(tempPrefs.emailTxt, new Date(2020, 0, 1), {
        heure: 10,
        minute: 0,
      })
    : "";
  const smsTextDisplay = tempPrefs?.smsTxt
    ? convertTextForDisplay(tempPrefs.smsTxt, new Date(2020, 0, 1), {
        heure: 10,
        minute: 0,
      })
    : "";
  const confirmTextDisplay = tempPrefs?.confirmTxt
    ? convertTextForDisplay(tempPrefs?.confirmTxt, new Date(2020, 0, 1), {
        heure: 10,
        minute: 0,
      })
    : "";
  const smsCharsRemaining = tempPrefs?.smsTxt
    ? remainingChars(tempPrefs.smsTxt)
    : 160;
  const senderNameCharsRemaining = tempPrefs?.smsName
    ? remainingSenderChars(tempPrefs.smsName)
    : 11;
  const confirmTxtCharsRemaining = tempPrefs?.confirmTxt
    ? remainingChars(tempPrefs.confirmTxt)
    : 160;
  const isInvalidSms = smsCharsRemaining < 0;
  const isInvalidSenderName = senderNameCharsRemaining < 0;
  const isInvalidConfirmText = confirmTxtCharsRemaining < 0;
  const isValidEmailExpediteur =
    !!tempPrefs &&
    !!tempPrefs.emailExpediteur &&
    z.string().email().safeParse(tempPrefs.emailExpediteur).success;
  const onToggleEmailTxtEditing = () => {
    setIsEditingEmailTxt((prev) => !prev);
  };
  const onToggleSmsTxtEditing = () => {
    setIsEditingSmsTxt((prev) => !prev);
  };

  const closeUiTxtEditing = () => {
    setIsEditingEmailTxt(false);
    setIsEditingSmsTxt(false);
    setIsEditingConfirmText(false);
    setIsEditingEmailExpediteur(false);
    setIsEditingSmsName(false);
  };
  const onCancelUiTxtEditing = () => {
    setIsEditingEmailTxt(false);
    assignOldPrefs();
  };

  const assignOldPrefs = () => {
    if (existingPrefs) {
      setTempPrefs(existingPrefs);
    } else {
      setTempPrefs(null);
    }
  };
  const trpcUtils = trpc.useContext();
  const newPrefsMutation = trpc.preference.saveNewPrefs.useMutation({
    onMutate: () => {
      closeUiTxtEditing();
    },
    onSettled: () => {
      trpcUtils.preference.invalidate();
    },
  });
  const saveEditedPrefsMutation = trpc.preference.saveEditedPrefs.useMutation({
    onMutate: () => {
      closeUiTxtEditing();
    },
    onSettled: () => {
      trpcUtils.preference.invalidate();
    },
  });
  const onRemindersPrefsSave = () => {
    if (tempPrefs && "_id" in tempPrefs) {
      saveEditedPrefsMutation.mutate(
        existingPreferencesSchema.parse(tempPrefs)
      );
      return;
    }
    if (tempPrefs && !("_id" in tempPrefs)) {
      newPrefsMutation.mutate(newPreferencesSchema.parse(tempPrefs));
      return;
    }
  };

  const onOpenEmailExpediteurEditing = () => {
    setIsEditingEmailExpediteur(true);
  };
  const onOpenSmsExpediteurEditing = () => {
    setIsEditingSmsName(true);
  };
  const onOpenConfirmTxtEditing = () => {
    setIsEditingConfirmText(true);
  };

  return (
    <div>
      <div className="panel panel-info">
        <div className="panel-heading">Texte des rappels par E-Mail</div>
        <div className="panel-body">
          <div className="form-horizontal">
            <div className="form-group">
              {isEditingEmailTxt ? (
                <div>
                  Ecrire {"{"}d{"}"} à l'endroit où vous voulez mettre la date
                  du RDV, {"{"}h{"}"} pour l'heure
                  <textarea
                    className="form-control"
                    rows={5}
                    id="emailTextInput"
                    onChange={(event) =>
                      onSetEmailText(event.currentTarget.value)
                    }
                    value={tempPrefs?.emailTxt}
                  />
                  <button
                    className="btn btn-default"
                    id="editEmailSaveInputButton"
                    type="submit"
                    onClick={onRemindersPrefsSave}
                  >
                    Enregistrer
                  </button>
                  <button
                    className="btn btn-default"
                    id="editEmailCancelInputButton"
                    type="submit"
                    onClick={onCancelUiTxtEditing}
                  >
                    Annuler
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-default"
                  id="editEmailTextInputButton"
                  type="submit"
                  onClick={onToggleEmailTxtEditing}
                >
                  Editer
                </button>
              )}
              <textarea
                className="form-control"
                rows={5}
                id="emailTextDisplay"
                disabled
                value={emailTextDisplay}
              />
            </div>
            <div className="form-inline">
              <div className="form-group">
                <label htmlFor="emailExpediteurInput">
                  Adresse Email Expéditeur :
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="emailExpediteurInput"
                  value={tempPrefs?.emailExpediteur || ""}
                  onChange={(event) =>
                    onSetEmailExpediteur(event.currentTarget.value)
                  }
                  disabled={!isEditingEmailExpediteur}
                />
                <button
                  className="btn btn-default"
                  id="editEmailExpediteurButton"
                  type="submit"
                  onClick={onOpenEmailExpediteurEditing}
                >
                  Editer
                </button>
                {isEditingEmailExpediteur ? (
                  <button
                    className="btn btn-default"
                    id="emailExpediteurSaveButton"
                    type="submit"
                    onClick={onRemindersPrefsSave}
                    disabled={!isValidEmailExpediteur}
                  >
                    Enregistrer
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="panel panel-info">
        <div className="panel-heading">Texte des rappels par SMS</div>
        <div className="panel-body">
          <div className="form-horizontal">
            <div className="form-group">
              {isEditingSmsTxt ? (
                <div>
                  Ecrire {"{" + "d" + "}"} à l'endroit où vous voulez mettre la
                  date du RDV, {"{"}h{"}"} pour l'heure. Certains caractères (ê,
                  ë...) ne sont pas transmissibles par sms.
                  <textarea
                    className="form-control"
                    rows={5}
                    id="smsTextInput"
                    onChange={(event) =>
                      onSetSmsText(event.currentTarget.value)
                    }
                    value={tempPrefs?.smsTxt}
                  />
                  <button
                    className="btn btn-default"
                    id="editSmsSaveInputButton"
                    disabled={isInvalidSms}
                    type="submit"
                    onClick={onRemindersPrefsSave}
                  >
                    Enregistrer
                  </button>
                  <button
                    className="btn btn-default"
                    id="editSmsCancelInputButton"
                    type="submit"
                    onClick={onCancelUiTxtEditing}
                  >
                    Annuler
                  </button>
                  <span
                    className={
                      "label " +
                      (isInvalidSms ? "label-danger" : "label-success")
                    }
                  >
                    {smsCharsRemaining} caractères restants
                  </span>
                </div>
              ) : (
                <button
                  className="btn btn-default"
                  id="editSmsTextInputButton"
                  type="submit"
                  onClick={onToggleSmsTxtEditing}
                >
                  Editer
                </button>
              )}

              <textarea
                className="form-control"
                rows={5}
                id="smsTextDisplay"
                disabled
                value={smsTextDisplay}
              />
            </div>
            <div className="form-inline">
              <div className="form-group">
                <label>Nom Expéditeur :</label>
                <input
                  type="text"
                  className="form-control"
                  id="smsExpediteurInput"
                  value={tempPrefs?.smsName || ""}
                  onChange={(event) => onSetSmsName(event.currentTarget.value)}
                  disabled={!isEditingSmsName}
                />
                <span
                  className={
                    "label " +
                    (isInvalidSenderName ? "label-danger" : "label-success")
                  }
                >
                  {senderNameCharsRemaining} caractères restants
                </span>
                <button
                  className="btn btn-default"
                  id="editSmsExpediteurButton"
                  type="submit"
                  onClick={onOpenSmsExpediteurEditing}
                >
                  Editer
                </button>
                {isEditingSmsName ? (
                  <button
                    className="btn btn-default"
                    id="smsExpediteurSaveButton"
                    type="submit"
                    onClick={onRemindersPrefsSave}
                    disabled={isInvalidSenderName}
                  >
                    Enregistrer
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="panel panel-info">
        <div className="panel-heading">
          Texte des demandes de confirmation par SMS
        </div>
        <div className="panel-body">
          <div className="form-horizontal">
            <div className="form-group">
              {isEditingConfirmText ? (
                <div>
                  Ecrire {"{" + "d" + "}"} à l'endroit où vous voulez mettre la
                  date du RDV, {"{"}h{"}"} pour l'heure. Certains caractères (ê,
                  ë...) ne sont pas transmissibles par sms.
                  <textarea
                    className="form-control"
                    rows={5}
                    id="smsTextInput"
                    onChange={(event) =>
                      onSetConfirmText(event.currentTarget.value)
                    }
                    value={
                      (tempPrefs &&
                        "confirmTxt" in tempPrefs &&
                        tempPrefs.confirmTxt) ||
                      ""
                    }
                  />
                  <button
                    className="btn btn-default"
                    id="editSmsSaveInputButton"
                    disabled={isInvalidConfirmText}
                    type="submit"
                    onClick={onRemindersPrefsSave}
                  >
                    Enregistrer
                  </button>
                  <button
                    className="btn btn-default"
                    id="editSmsCancelInputButton"
                    type="submit"
                    onClick={onCancelUiTxtEditing}
                  >
                    Annuler
                  </button>
                  <span
                    className={
                      "label " +
                      (isInvalidConfirmText ? "label-danger" : "label-success")
                    }
                  >
                    {confirmTxtCharsRemaining} caractères restants
                  </span>
                </div>
              ) : (
                <button
                  className="btn btn-default"
                  id="editSmsTextInputButton"
                  type="submit"
                  onClick={onOpenConfirmTxtEditing}
                >
                  Editer
                </button>
              )}

              <textarea
                className="form-control"
                rows={5}
                id="smsTextDisplay"
                disabled
                value={confirmTextDisplay || ""}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemindersParams;
