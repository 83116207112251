import { Suspense, useState } from "react";
import { getMultiplePatientsRdvsStats } from "../../rdvs/filterTransforms/patientRdvsStats";
import { useSelectedPatientIds } from "../../main/state/globalState";
import useSelectedPatients from "../hooks/useSelectedPatients";
import { trpc } from "../../main/components/MainContainer";
import { formaters } from "vincent-utils";
import styles from "./ResumePatient.module.css";
import Flag02StrokeRounded from "../../main/icons/flag-02-stroke-rounded.tsx";
import PatientFlagsChooser from "./PatientFlagsChooser.tsx";
import _ from "underts";
import { CadendarConsts } from "cadendar-shared";
import Flag from "./Flag.tsx";

const ResumePatient = () => {
  //const selectedPatients = useSelector(patientsObjectsSelected);
  //const patientsStats = useSelector(getSelectedPatientsRdvStats);
  const [selectedPatientIds] = useSelectedPatientIds();
  const [showFlagPopup, setShowFlagPopup] = useState(false);
  const [flagPopupTop, setFlagPopupTop] = useState(0);
  const [flagPopupLeft, setFlagPopupLeft] = useState(0);

  const utils = trpc.useContext();
  const patientFlagMutation = trpc.patient.setPatientFlag.useMutation({
    onSuccess: () => {
      utils.patient.invalidate();
      utils.rdv.invalidate();
    },
  });

  const handleFlagReset = () => {
    if (!firstPatient) return;
    patientFlagMutation.mutate({ patientId: firstPatient._id, flag: null });
  };

  const handleFlagClicked = (flag: CadendarConsts.PatientFlags) => {
    if (!firstPatient) return;
    if (firstPatient.patient_flags === flag) {
      handleFlagReset();
    } else {
      patientFlagMutation.mutate({ patientId: firstPatient._id, flag });
    }
  };

  const handleOpenFlagPopup = (event: React.MouseEvent) => {
    event.stopPropagation();
    const target = event.currentTarget;
    openFlagPopup(
      target.getBoundingClientRect().top,
      target.getBoundingClientRect().right + 10
    );
  };

  const openFlagPopup = (top: number, left: number) => {
    setFlagPopupTop(top);
    setFlagPopupLeft(left);
    setShowFlagPopup(true);
  };

  const selectedPatients = useSelectedPatients();
  const { data: selectedPatientsRdvs } = trpc.rdv.findByPatientIds.useQuery(
    { patientIds: selectedPatientIds },
    {
      enabled: selectedPatientIds.length > 0,
    }
  );

  // const [selectedPatientsRdvs] = useSuperQuery(
  //   ["rdvs", "byPatientsIds", selectedPatientIds],
  //   getPatientsRdvs,
  //   [],
  //   { enabled: selectedPatientIds.length > 0 },
  //   selectedPatientIds
  // );
  const patientsStats = getMultiplePatientsRdvsStats(
    selectedPatientIds,
    selectedPatientsRdvs || []
  );

  const firstPatient = _.first(selectedPatients);
  const flag = firstPatient?.patient_flags;

  document.addEventListener("globalClick", () => {
    setShowFlagPopup(false);
  });

  return (
    <div id="resume_patient">
      {selectedPatients ? (
        selectedPatients.map((patient) => (
          <div key={"resumePatientkey" + patient._id}>
            <div className={styles.patientTitle} id="patient_title">
              {patient.nom.toUpperCase()}{" "}
              {formaters.capitaliseEveryFirstLetter(patient.prenom)}
            </div>
            <div className={styles.buttonSpacer}>
              {!patient.patient_flags ? (
                <div className={styles.button} onClick={handleOpenFlagPopup}>
                  <Flag02StrokeRounded />
                </div>
              ) : null}
            </div>

            {patient.patient_flags && patient.patient_flags === "bl" ? (
              <div className={styles.flagSpacer}>
                <Flag
                  flag="bl"
                  label="Ne plus donner de RDV !!"
                  onFlagClicked={handleOpenFlagPopup}
                />
              </div>
            ) : (
              ""
            )}
            {patient.patient_flags && patient.patient_flags !== "bl" ? (
              <div className={styles.flagSpacer}>
                <Flag
                  flag={patient.patient_flags}
                  onFlagClicked={handleOpenFlagPopup}
                />
              </div>
            ) : (
              ""
            )}
            <div>
              {patientsStats[patient._id]?.lapinDates.length > 0 ? (
                <div>
                  RDV manqués :{" "}
                  {patientsStats[patient._id].lapinDates.join(", ")}
                </div>
              ) : (
                ""
              )}
              {patientsStats[patient._id]?.manqueRetardDates.length > 0 ? (
                <div>
                  RDV manqués pour cause de retard :{" "}
                  {patientsStats[patient._id]?.manqueRetardDates.join(", ")}
                </div>
              ) : (
                ""
              )}
              {patientsStats[patient._id]?.annTardDates.length > 0 ? (
                <div>
                  Annulations tardives :{" "}
                  {patientsStats[patient._id]?.annTardDates.join(", ")}
                </div>
              ) : (
                ""
              )}
              {patientsStats[patient._id]?.canceledDates.length > 0 ? (
                <div>
                  RDV annulés :{" "}
                  {patientsStats[patient._id]?.canceledDates.join(", ")}
                </div>
              ) : (
                ""
              )}
              {patientsStats[patient._id]?.urgencesDates.length > 0 ? (
                <div>
                  Rdv en urgence :{" "}
                  {patientsStats[patient._id]?.urgencesDates.join(", ")}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="patient_outline" id="patient_title">
          PAS DE PATIENT SELECTIONNE !
        </div>
      )}
      {showFlagPopup ? (
        <div
          style={{ top: flagPopupTop, left: flagPopupLeft }}
          className={styles.popup}
        >
          <PatientFlagsChooser
            onFlagClicked={handleFlagClicked}
            onResetClicked={handleFlagReset}
            patient_flags={flag || null}
            flags={CadendarConsts.patientFlags}
          />
        </div>
      ) : null}
    </div>
  );
};

export default () => (
  <Suspense fallback={<div>Loading...</div>}>
    <ResumePatient />
  </Suspense>
);
