import styles from "./CheckBox.module.css";
import classNames from "classnames";

interface CheckBoxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
  id: string;
  className?: string;
  disabled?: boolean;
  onToggle?: () => void;
}

const CheckBox = (props: CheckBoxProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handleChange");
    e.stopPropagation();
    if (props.disabled) return;
    props.onChange(e.target.checked);
    handleToggle();
  };
  const handleToggle = () => {
    if (props.disabled) return;
    if (props.onToggle) {
      props.onToggle();
    }
  };
  return (
    <div className={styles.spacer}>
      <input
        type="checkbox"
        checked={props.checked}
        onChange={handleChange}
        id={props.id}
        className={styles.input}
        disabled={props.disabled}
      />
      <label
        htmlFor={props.id}
        className={classNames(styles.label, props.className)}
      >
        {props.label}
      </label>
    </div>
  );
};

export default CheckBox;
