;
import { CadendarConsts } from "cadendar-shared";
interface MotifsCheckboxesProps {
  toggleMotif: (motif: CadendarConsts.MotifsNames) => void;
  motifsToggled: CadendarConsts.MotifsNames[];
}

export default (props: MotifsCheckboxesProps) => {
  const motifsNames = CadendarConsts.motifsNames;
  const { motifsToggled } = props;
  const { toggleMotif } = props;
  const style = {
    paddingRight: "6px"
  };
  const motifsChecks = motifsNames.map(motif => (
    <label key={motif + "_motifLabel"} style={style}>
      <input
        type="checkbox"
        checked={motifsToggled.includes(motif)}
        id={motif + "_motif"}
        onChange={() => {
          toggleMotif(motif);
        }}
      />
      {motif}
    </label>
  ));
  return <div className="checkbox">{motifsChecks}</div>;
};
