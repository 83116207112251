import { Plage } from "cadendar-shared";
import { Collections } from "cadendar-shared";
import {functionnal} from "vincent-utils";
const isRdvBeforeHour = (rdv: Collections.Rdv, hourInMinutes: number) =>
  rdv.heure * 60 + rdv.minute + rdv.duree <= hourInMinutes;
const isRdvAfterHour = (rdv: Collections.Rdv, hourInMinutes: number) =>
  rdv.heure * 60 + rdv.minute >= hourInMinutes;
const filterAfter = (heureEnMinutes: number) => {
  "use strict";
  return (rdvs: Collections.Rdv[]) =>
    rdvs.filter(functionnal.curry2(isRdvAfterHour)(heureEnMinutes));
};
const filterBefore = (heureEnMinutes: number) => {
  "use strict";
  return (rdvs: Collections.Rdv[]) =>
    rdvs.filter(functionnal.curry2(isRdvBeforeHour)(heureEnMinutes));
};
const rdvsFromPlage = (rdvsDuJour: Collections.Rdv[], plage: Plage) => {
  const plageStart = plage.start.heure * 60 + plage.start.minute;
  const plageEnd = plage.end.heure * 60 + plage.end.minute;
  // return _.compose(filterAfter(plageStart), filterBefore(plageEnd))(rdvsDuJour);
  return filterAfter(plageStart)(filterBefore(plageEnd)(rdvsDuJour));
};
export default rdvsFromPlage;
