import {
  CadendarConsts,
  Collections,
  TimeConstraint,
  timeConstraintSchema,
} from "cadendar-shared";
import ContraintesResetButton from "./ContraintesResetButton";
import ContrainteLine from "./ContrainteLine";
import { hmsFromContraintes } from "../filtersTransforms/hmsFromContraintes";
const { jours } = CadendarConsts;
import { formaters } from "vincent-utils";
import { usePreferences, useSelectedWeek } from "../../main/state/globalState";
import _ from "underts";
import { atom } from "jotai";
import { useAtom } from "jotai";
import { utils } from "vincent-utils";
import { useHoraire } from "../../horaires/hooks/useHoraire";

const periodTypes = ["toute la journée", "avant", "après", "entre"] as const;

export type PeriodType = (typeof periodTypes)[number];

type JoursContraintes = "openDays" | (typeof jours)[number];

export type Contrainte =
  | UnavailableContrainte
  | WholeDayAvailableContrainte
  | BeforeContrainte
  | AfterContrainte
  | BetweenContrainte;

type UnavailableContrainte = {
  mode: "unavailable";
};

type WholeDayAvailableContrainte = {
  mode: "toute la journée";
};

type BeforeContrainte = {
  mode: "avant";
  firstTimeContrainte: string;
};

type AfterContrainte = {
  mode: "après";
  firstTimeContrainte: string;
};

type BetweenContrainte = {
  mode: "entre";
  firstTimeContrainte: string;
  secondTimeContrainte: string;
};

type ContraintesState =
  | {
      [jour in (typeof jours)[number]]: Contrainte | null;
    };

const isOkChecked = (state: ContraintesState, jour: JoursContraintes) => {
  if (jour === "openDays") {
    return Object.entries(state)
      .filter(([jour, _state]) => jour !== "samedi" && jour !== "dimanche")
      .map(([_jour, state]) => state)
      .every((contrainte) => {
        if (!contrainte) {
          return false;
        }
        return contrainte.mode !== "unavailable";
      });
  }
  const contrainte = state[jour];
  if (!contrainte) {
    return false;
  }
  return contrainte.mode !== "unavailable";
};

const isNoOkChecked = (state: ContraintesState, jour: JoursContraintes) => {
  if (jour === "openDays") {
    return false; //non utilisé en pratique, juste pour que TS soit content
  }
  const contrainte = state[jour];
  if (!contrainte) {
    return false;
  }
  return contrainte.mode === "unavailable";
};

const timeContrainteSelected = (
  state: ContraintesState,
  jour: JoursContraintes
) => {
  if (jour === "openDays") {
    const res = Object.entries(state)
      .filter(([jour, _state]) => jour !== "samedi" && jour !== "dimanche")
      .map(([_jour, state]) => state)
      .reduce(
        (res, contrainte) => {
          if (!contrainte) {
            return {
              value: null,
              bool: false,
            };
          }
          if (!res.bool) {
            return {
              value: null,
              bool: false,
            };
          }
          if (res.value && res.value === contrainte.mode) {
            return res;
          }
          if (!res.value) {
            return {
              value: contrainte.mode,
              bool: true,
            };
          }
          if (res.value !== contrainte.mode) {
            return {
              value: null,
              bool: false,
            };
          }
          throw new Error("should not get here");
        },
        { value: null, bool: true } as {
          value: Contrainte["mode"] | null;
          bool: boolean;
        }
      );
    if (res.bool) {
      return res.value;
    } else {
      return null;
    }
  }
  const contrainte = state[jour];
  if (!contrainte) {
    return null;
  }
  return contrainte.mode;
};

const firstTimeContrainteLabels = (
  state: ContraintesState,
  jour: JoursContraintes,
  horaire: Collections.HoraireInDB,
  duréeMiniRdv: number
) => {
  const contrainte = timeContrainteSelected(state, jour);
  if (!contrainte) {
    return [];
  }
  if (contrainte === "toute la journée" || contrainte === "unavailable") {
    return [];
  }
  const firstTimeSelected =
    firstTimeContrainteSelected(state, jour) || undefined;
  const secondTimeSelected =
    secondTimeContrainteSelected(state, jour) || undefined;
  const hms = hmsFromContraintes(
    horaire,
    jour,
    contrainte,
    1,
    duréeMiniRdv,
    firstTimeSelected
      ? formaters.getHeureMinuteObjectFromHoraireString(firstTimeSelected)
      : undefined,
    secondTimeSelected
      ? formaters.getHeureMinuteObjectFromHoraireString(secondTimeSelected)
      : undefined
  );
  return _.compact(hms.map(formaters.formatHMObjectForDisplay));
};

const secondTimeContrainteLabels = (
  state: ContraintesState,
  jour: JoursContraintes,
  horaire: Collections.HoraireInDB,
  duréeMiniRdv: number
) => {
  const contrainte = timeContrainteSelected(state, jour);
  if (!contrainte) {
    return [];
  }
  if (contrainte === "toute la journée" || contrainte === "unavailable") {
    return [];
  }
  const firstTimeSelected =
    firstTimeContrainteSelected(state, jour) || undefined;
  const secondTimeSelected =
    secondTimeContrainteSelected(state, jour) || undefined;
  const hms = hmsFromContraintes(
    horaire,
    jour,
    contrainte,
    2,
    duréeMiniRdv,
    firstTimeSelected
      ? formaters.getHeureMinuteObjectFromHoraireString(firstTimeSelected)
      : undefined,
    secondTimeSelected
      ? formaters.getHeureMinuteObjectFromHoraireString(secondTimeSelected)
      : undefined
  );
  return _.compact(hms.map(formaters.formatHMObjectForDisplay));
};

const firstTimeContrainteSelected = (
  state: ContraintesState,
  jour: JoursContraintes
) => {
  if (jour === "openDays") {
    const res = Object.entries(state)
      .filter(([jour, _state]) => jour !== "samedi" && jour !== "dimanche")
      .map(([_jour, state]) => state)
      .reduce(
        (res, contrainte) => {
          if (!contrainte) {
            return {
              value: null,
              bool: false,
            };
          }
          if (!res.bool) {
            return {
              value: null,
              bool: false,
            };
          }
          if (!("firstTimeContrainte" in contrainte)) {
            return {
              value: null,
              bool: false,
            };
          }
          if (res.value && res.value === contrainte.firstTimeContrainte) {
            return res;
          }
          if (!res.value) {
            return {
              value: contrainte.firstTimeContrainte,
              bool: true,
            };
          }
          if (res.value !== contrainte.firstTimeContrainte) {
            return {
              value: null,
              bool: false,
            };
          }
          throw new Error("should not get heure");
        },
        { value: null, bool: true } as {
          value: BeforeContrainte["firstTimeContrainte"] | null;
          bool: boolean;
        }
      );
    if (res.bool) {
      return res.value;
    } else {
      return null;
    }
  }
  const contrainte = state[jour];
  if (!contrainte) {
    return null;
  }
  return "firstTimeContrainte" in contrainte
    ? contrainte.firstTimeContrainte
    : null;
};

const secondTimeContrainteSelected = (
  state: ContraintesState,
  jour: JoursContraintes
) => {
  if (jour === "openDays") {
    const res = Object.entries(state)
      .filter(([jour, _state]) => jour !== "samedi" && jour !== "dimanche")
      .map(([_jour, state]) => state)
      .reduce(
        (res, contrainte) => {
          if (!contrainte) {
            return {
              value: null,
              bool: false,
            };
          }
          if (!res.bool) {
            return {
              value: null,
              bool: false,
            };
          }
          if (!("secondTimeContrainte" in contrainte)) {
            return {
              value: null,
              bool: false,
            };
          }
          if (res.value && res.value === contrainte.secondTimeContrainte) {
            return res;
          }
          if (!res.value) {
            return {
              value: contrainte.secondTimeContrainte,
              bool: true,
            };
          }
          if (res.value !== contrainte.secondTimeContrainte) {
            return {
              value: null,
              bool: false,
            };
          }
          throw new Error("should not get here");
        },
        { value: null, bool: true } as {
          value: BetweenContrainte["secondTimeContrainte"] | null;
          bool: boolean;
        }
      );
    if (res.bool) {
      return res.value;
    } else {
      return null;
    }
  }
  const contrainte = state[jour];
  if (!contrainte) {
    return null;
  }
  return "secondTimeContrainte" in contrainte
    ? contrainte.secondTimeContrainte
    : null;
};

const showFirstTimeContrainte = (
  state: ContraintesState,
  jour: JoursContraintes
) => {
  if (jour === "openDays") {
    return Object.entries(state)
      .filter(([jour, _state]) => jour !== "samedi" && jour !== "dimanche")
      .map(([_jour, state]) => state)
      .every((contrainte) => {
        if (!contrainte) {
          return false;
        }
        return (
          contrainte.mode !== "unavailable" &&
          contrainte.mode !== "toute la journée"
        );
      });
  }
  const contrainte = state[jour];
  if (!contrainte) {
    return false;
  }
  return (
    contrainte.mode !== "unavailable" && contrainte.mode !== "toute la journée"
  );
};

const showSecondTimeContrainte = (
  state: ContraintesState,
  jour: JoursContraintes
) => {
  if (jour === "openDays") {
    return Object.entries(state)
      .filter(([jour, _state]) => jour !== "samedi" && jour !== "dimanche")
      .map(([_jour, state]) => state)
      .every((contrainte) => {
        if (!contrainte) {
          return false;
        }
        return contrainte.mode === "entre";
      });
  }
  const contrainte = state[jour];
  if (!contrainte) {
    return false;
  }
  return contrainte.mode === "entre";
};

const getDefaultContrainte = () =>
  jours.reduce(
    (acc, jour) => ({ ...acc, [jour]: null }),
    {} as ContraintesState
  );

const ContraintesStateAtom = atom<ContraintesState>(getDefaultContrainte());

export const useContraintes = () => {
  const [contraintes, setContraintes] = useAtom(ContraintesStateAtom);
  const onNoOkToggle = (jour: JoursContraintes) => {
    setContraintes((contraintes) => {
      //si tout est à null, on met le jour à no-ok, et tous les autres jours à ok
      if (Object.values(contraintes).every((contrainte) => !contrainte)) {
        return jours.reduce((acc, jourInternal) => {
          return {
            ...acc,
            [jourInternal]: {
              mode: jourInternal === jour ? "unavailable" : "toute la journée",
            },
          };
        }, {} as ContraintesState);
      }
      if (isNoOkChecked(contraintes, jour)) {
        return {
          ...contraintes,
          [jour]: {
            mode: "toute la journée",
          },
        };
      } else {
        return {
          ...contraintes,
          [jour]: {
            mode: "unavailable",
          },
        };
      }
    });
  };
  const onOkToggle = (jour: JoursContraintes) => {
    setContraintes((contraintes) => {
      if (jour === "openDays") {
        if (isOkChecked(contraintes, "openDays")) {
          return jours
            .filter((jour) => jour !== "dimanche" && jour !== "samedi")
            .reduce((acc, jour) => {
              return {
                ...acc,
                [jour]: {
                  mode: "unavailable",
                },
              };
            }, {} as ContraintesState);
        }
        return jours
          .filter((jour) => jour !== "dimanche" && jour !== "samedi")
          .reduce((acc, jour) => {
            return {
              ...acc,
              [jour]: {
                mode: "toute la journée",
              },
            };
          }, {} as ContraintesState);
      }
      //si tout est à null, on met le jour à ok, et tous les autres jours à no-ok
      if (Object.values(contraintes).every((contrainte) => !contrainte)) {
        return jours.reduce((acc, jourInternal) => {
          return {
            ...acc,
            [jourInternal]: {
              mode: jourInternal === jour ? "toute la journée" : "unavailable",
            },
          };
        }, {} as ContraintesState);
      }
      if (isOkChecked(contraintes, jour)) {
        return {
          ...contraintes,
          [jour]: {
            mode: "unavailable",
          },
        };
      }
      return {
        ...contraintes,
        [jour]: {
          mode: "toute la journée",
        },
      };
    });
  };
  const onSelectContrainte = (
    jour: JoursContraintes,
    mode: Contrainte["mode"]
  ) => {
    setContraintes((contraintes) => {
      if (jour === "openDays") {
        return jours
          .filter((jour) => jour !== "dimanche" && jour !== "samedi")
          .reduce((acc, jour) => {
            return {
              ...acc,
              [jour]: {
                mode,
              },
            };
          }, {} as ContraintesState);
      }
      return {
        ...contraintes,
        [jour]: {
          mode,
        },
      };
    });
  };

  const onSelectFirstTimeContrainte = (
    jour: JoursContraintes,
    value: string
  ) => {
    setContraintes((contraintes) => {
      if (jour === "openDays") {
        return jours
          .filter((jour) => jour !== "dimanche" && jour !== "samedi")
          .reduce((acc, jour) => {
            return {
              ...acc,
              [jour]: {
                ...acc[jour],
                firstTimeContrainte: value,
              },
            };
          }, contraintes);
      }
      return {
        ...contraintes,
        [jour]: {
          ...contraintes[jour],
          firstTimeContrainte: value,
        },
      };
    });
  };
  const onSelectSecondTimeContrainte = (
    jour: JoursContraintes,
    value: string
  ) => {
    setContraintes((contraintes) => {
      if (jour === "openDays") {
        return jours
          .filter((jour) => jour !== "dimanche" && jour !== "samedi")
          .reduce((acc, jour) => {
            return {
              ...acc,
              [jour]: {
                ...acc[jour],
                secondTimeContrainte: value,
              },
            };
          }, contraintes);
      }
      return {
        ...contraintes,
        [jour]: {
          ...contraintes[jour],
          secondTimeContrainte: value,
        },
      };
    });
  };

  const contraintesConverted = Object.entries(contraintes).reduce(
    (acc, [jour, contrainte]) => {
      if (!contrainte) {
        return acc;
      }
      const convertedJour = {
        mode: contrainte.mode,
        ...("firstTimeContrainte" in contrainte
          ? utils.cleanObject({
              first: formaters.getHeureMinuteObjectFromHoraireString(
                contrainte.firstTimeContrainte
              ),
            })
          : {}),
        ...("secondTimeContrainte" in contrainte
          ? utils.cleanObject({
              second: formaters.getHeureMinuteObjectFromHoraireString(
                contrainte.secondTimeContrainte
              ),
            })
          : {}),
      };
      // //on s'assure qu'on n'envoie pas un "entre" avec un first et pas un second ou l'inverse
      // const validatedJour =
      //   convertedJour.mode !== "entre"
      //     ? convertedJour
      //     : convertedJour.first && convertedJour.second
      //     ? convertedJour
      //     : null;
      const validatedJourResult = timeConstraintSchema.safeParse(convertedJour);

      return {
        ...acc,
        ...(validatedJourResult.success
          ? { [jour]: validatedJourResult.data }
          : {}),
      };
    },
    {} as { [jour in (typeof jours)[number]]: TimeConstraint }
  );

  const showResetButton = !!contraintes;
  const onResetContraintes = () => setContraintes(getDefaultContrainte());
  return {
    contraintes,
    contraintesConverted,
    showResetButton,
    onResetContraintes,
    onNoOkToggle,
    onOkToggle,
    onSelectContrainte,
    onSelectFirstTimeContrainte,
    onSelectSecondTimeContrainte,
  };
};

const ContraintesPanel = () => {
  const {
    showResetButton,
    onResetContraintes,
    onNoOkToggle,
    onOkToggle,
    onSelectContrainte,
    contraintes,
    onSelectSecondTimeContrainte,
    onSelectFirstTimeContrainte,
  } = useContraintes();

  const resetButtonComp = showResetButton ? (
    <ContraintesResetButton onClick={onResetContraintes} />
  ) : (
    <span></span>
  );

  const noOkContraintesComp = jours.map((jour) => {
    const noOkTextComp = (jour: string) => <span>Pas possible le {jour}</span>;
    return (
      <ContrainteLine
        type="closed"
        isChecked={isNoOkChecked(contraintes, jour)}
        onChange={onNoOkToggle.bind(null, jour)}
        textComp={noOkTextComp(jour)}
        key={"noOk" + jour}
      />
    );
  });
  const { selectedWeek } = useSelectedWeek();
  const horaire = useHoraire(selectedWeek);
  const preferences = usePreferences();
  const duréeMiniRdv = preferences?.duréeMinRdv || 0;
  const openDaysOkComp = () => {
    const okOpenDaysTextComp = () => (
      <span>
        <span className="ok-green">Possible </span>tous les jours ouvrés de la
        semaine.
      </span>
    );
    return (
      <div>
        <ContrainteLine
          type="open"
          isChecked={isOkChecked(contraintes, "openDays")}
          onChange={onOkToggle.bind(null, "openDays")}
          textComp={okOpenDaysTextComp()}
          showTimeContrainteTypeSelector={isOkChecked(contraintes, "openDays")}
          showFirstTimeSelector={showFirstTimeContrainte(
            contraintes,
            "openDays"
          )}
          showSecondTimeSelector={showSecondTimeContrainte(
            contraintes,
            "openDays"
          )}
          timeContrainteSelected={
            timeContrainteSelected(contraintes, "openDays") || ""
          }
          firstTimeSelectorSelected={
            firstTimeContrainteSelected(contraintes, "openDays") || ""
          }
          firstTimes={
            horaire
              ? firstTimeContrainteLabels(
                  contraintes,
                  "openDays",
                  horaire,
                  duréeMiniRdv
                )
              : []
          }
          secondTimes={
            horaire
              ? secondTimeContrainteLabels(
                  contraintes,
                  "openDays",
                  horaire,
                  duréeMiniRdv
                )
              : []
          }
          secondTimeSelectorSelected={
            secondTimeContrainteSelected(contraintes, "openDays") || ""
          }
          periodTypes={periodTypes}
          onContrainteTypeSelect={onSelectContrainte.bind(null, "openDays")}
          onFirstTimeSelect={onSelectFirstTimeContrainte.bind(null, "openDays")}
          onSecondTimeSelect={onSelectSecondTimeContrainte.bind(
            null,
            "openDays"
          )}
        />
      </div>
    );
  };
  const okContraintesComp = jours.map((jour) => {
    const okTextComp = (jour: (typeof jours)[number]) => (
      <span>
        <span className="ok-green">Possible </span>le {jour}
      </span>
    );
    return (
      <ContrainteLine
        type="open"
        isChecked={isOkChecked(contraintes, jour)}
        onChange={onOkToggle.bind(null, jour)}
        textComp={okTextComp(jour)}
        showTimeContrainteTypeSelector={isOkChecked(contraintes, jour)}
        showFirstTimeSelector={showFirstTimeContrainte(contraintes, jour)}
        showSecondTimeSelector={showSecondTimeContrainte(contraintes, jour)}
        timeContrainteSelected={timeContrainteSelected(contraintes, jour) || ""}
        firstTimeSelectorSelected={
          firstTimeContrainteSelected(contraintes, jour) || ""
        }
        secondTimeSelectorSelected={
          secondTimeContrainteSelected(contraintes, jour) || ""
        }
        firstTimes={
          horaire
            ? firstTimeContrainteLabels(
                contraintes,
                jour,
                horaire,
                duréeMiniRdv
              )
            : []
        }
        secondTimes={
          horaire
            ? secondTimeContrainteLabels(
                contraintes,
                jour,
                horaire,
                duréeMiniRdv
              )
            : []
        }
        periodTypes={periodTypes}
        onContrainteTypeSelect={(mode: Contrainte["mode"]) =>
          onSelectContrainte(jour, mode)
        }
        onFirstTimeSelect={onSelectFirstTimeContrainte.bind(null, jour)}
        onSecondTimeSelect={onSelectSecondTimeContrainte.bind(null, jour)}
        key={"okDay" + jour}
      />
    );
  });

  return (
    <div className="panel panel-primary">
      <div className="panel-heading ">Filtres des rdvs proposés</div>
      <div className="panel-body">
        {resetButtonComp}
        <ul className="list-group">{noOkContraintesComp}</ul>
        <ul className="list-group">{openDaysOkComp()}</ul>
        <ul className="list-group">{okContraintesComp}</ul>
      </div>
    </div>
  );
};

export default ContraintesPanel;
