import { ChangeEvent } from "react";
import { formaters } from "vincent-utils";
import _ from "underts";
import { CadendarConsts, HMObject } from "cadendar-shared";
import Select2 from "../../../components/Select2";
import type { typeNumsType } from "cadendar-shared";
import styles from "./RdvCaracs.module.css";
import HInputGroup from "../../main/components/HInputGroup.tsx";
import HSelectGroup from "../../main/components/HSelectGroup.tsx";
import CheckBox from "../../main/CheckBox.tsx";

export interface RdvCaracsState {
  heure: number;
  minute: number;
  duree: number;
  type: number;
  objet: string;
  date: Date;
  hmPossibles: { heure: number; minute: number }[];
  dureesPossibles: number[];
  remindersCheckBoxEnabled: boolean;
  confirmCheckBoxEnabled: boolean;
  reminderChecked: boolean;
  confirmChecked: boolean;
}

export interface RdvCaracsCallbacks {
  onDureeChange: (value: number) => void;
  onHMChange: (hm: { heure: number; minute: number }) => void;
  onTypeChange: (type: typeNumsType) => void;
  onObjetChange: (objet: string) => void;
  onReminderClick: () => void;
  onConfirmClick: () => void;
}

interface RdvCaracsProps {
  rdvsCaracsState: RdvCaracsState;
  rdvsCaracsCallbacks: RdvCaracsCallbacks;
}

const RdvCaracs = (props: RdvCaracsProps) => {
  const noNull = formaters.nullToEmptyString;
  const { heure, minute } = props.rdvsCaracsState;
  const { duree } = props.rdvsCaracsState;
  const { type } = props.rdvsCaracsState;
  const { objet } = props.rdvsCaracsState;
  const { hmPossibles } = props.rdvsCaracsState;
  const { dureesPossibles } = props.rdvsCaracsState;
  const { remindersCheckBoxEnabled } = props.rdvsCaracsState;
  const { confirmCheckBoxEnabled } = props.rdvsCaracsState;
  const { reminderChecked } = props.rdvsCaracsState;
  const { confirmChecked } = props.rdvsCaracsState;
  const { onDureeChange } = props.rdvsCaracsCallbacks;
  const { onHMChange } = props.rdvsCaracsCallbacks;
  const { onTypeChange } = props.rdvsCaracsCallbacks;
  const { onObjetChange } = props.rdvsCaracsCallbacks;
  const { onReminderClick, onConfirmClick } = props.rdvsCaracsCallbacks;
  const eventValue = (event: ChangeEvent<HTMLSelectElement>) =>
    event && event.target && event.target.value;
  const caller = <R,>(func: (value: string) => R) => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      func(eventValue(event));
    };
  };
  const rdvhmString = formaters.nullToEmptyString(
    formaters.formatHMObjectForDisplay({ heure, minute })
  );
  const hmStrings =
    hmPossibles &&
    hmPossibles
      .map(formaters.formatHMObjectForDisplay)
      .map(formaters.nullToEmptyString);
  const handleHMChange = (value: string | number | undefined) => {
    if (!value || typeof value === "number") {
      return;
    }
    const hm = new HMObject(value);
    onHMChange(hm);
  };
  const handleDureeChange = (value: string | number | undefined) => {
    if (!value || typeof value === "number") {
      return;
    }
    const duree = parseInt(value);
    onDureeChange(duree);
  };
  const handleTypeChange = (key: keyof typeof CadendarConsts.typeConsts) => {
    onTypeChange(CadendarConsts.typeConsts[key]);
  };
  const hmKeyMap = hmStrings.reduce((acc, hmString) => {
    acc[hmString] = hmString;
    return acc;
  }, {} as { [key: string]: string });
  const dureeKeyMap = dureesPossibles.reduce((acc, duree) => {
    acc[duree.toString()] = duree.toString();
    return acc;
  }, {} as { [key: string]: string });
  return (
    <div className={styles.formSpacer}>
      <div className="">
        {_.isNonEmpty(hmStrings) ? (
          <HSelectGroup label="Heure" labelWidth="100px">
            <Select2
              keyMap={hmKeyMap}
              onSelect={handleHMChange}
              selected={rdvhmString}
              defaultKey={_.head(hmStrings) || ""}
              className={styles.selectStyle}
              id="heure_minute_chooser"
            />
          </HSelectGroup>
        ) : null}
        {_.isNonEmpty(dureesPossibles) ? (
          <HSelectGroup label="Durée" labelWidth="100px">
            <Select2
              keyMap={dureeKeyMap}
              onSelect={handleDureeChange}
              selected={duree.toString()}
              defaultKey={_.head(dureesPossibles).toString() || ""}
              className={styles.selectStyle}
              id="duree_chooser"
            />
          </HSelectGroup>
        ) : null}
        <HSelectGroup label="Type" labelWidth="100px">
          <Select2
            testid="type_chooser"
            id="type_chooser"
            className={styles.selectStyle}
            keyMap={CadendarConsts.typeTranslator}
            defaultKey={Object.keys(CadendarConsts.typeTranslator)[0]}
            selected={CadendarConsts.getTypeConstFromTypeNumber(type)}
            onSelect={handleTypeChange}
            sort={true}
          />
        </HSelectGroup>
        <HInputGroup
          label="Objet"
          value={noNull(objet)}
          onChange={onObjetChange}
          id="rdv_objet_field"
          labelWidth="100px"
          inputWidth="300px"
        />
        <div className={styles.checkBoxSpacer}>
          <CheckBox
            id={
              remindersCheckBoxEnabled
                ? "send_reminder"
                : "send_reminder_disabled"
            }
            checked={reminderChecked}
            onChange={onReminderClick}
            label="Envoyer MAIL/SMS"
            disabled={!remindersCheckBoxEnabled}
          />
          <CheckBox
            id="confirm_check"
            checked={confirmChecked}
            onChange={onConfirmClick}
            disabled={!confirmCheckBoxEnabled}
            label="Demander Confirmation"
          />
        </div>
      </div>
    </div>
  );
};
export default RdvCaracs;
