import _ from "underts";
import {
  getExtremesFromDayPlages,
  getHms,
} from "../../horaires/transformers/getHms";
import { formaters } from "vincent-utils";
import { HMObj, jours, Transforms } from "cadendar-shared";
import { Collections } from "cadendar-shared";

type ContrainteType = "avant" | "après" | "entre";

//filtrage basique en fonction du type de contrainte
//otherHMValue est utilisé seulement avec "entre", il permet de s'assurer que les deux sélecteurs soient dans l'ordre
const hmsFilterFromContrainteType = (
  hms: HMObj[],
  contrainteType: ContrainteType,
  contrainteNumber: 1 | 2,
  otherHMValue?: HMObj
): HMObj[] => {
  if (contrainteType === "avant") {
    if (contrainteNumber === 1) {
      return _.rest(hms); //ça ne rime à rien de dire avant la première heure de la plage, donc on la retire
    }
  }
  if (contrainteType === "après") {
    if (contrainteNumber === 1) {
      return _.initial(hms); //ça ne rime à rien de dire après la dernière heure de la plage, donc on la retire
    }
  }
  if (contrainteType === "entre") {
    if (contrainteNumber === 1) {
      return _.initial(hms).filter(
        //ça ne rime à rien de dire après la dernière heure de la plage, donc on la retire avec initial
        (hm) =>
          otherHMValue
            ? formaters.getHeureEnMinutesFromHeureMinuteObject(hm) < //on garde les valeurs avant le deuxième sélecteur
              formaters.getHeureEnMinutesFromHeureMinuteObject(otherHMValue)
            : true
      );
    }
    if (contrainteNumber === 2) {
      return _.rest(hms).filter(
        (
          hm //ça ne rime à rien de dire avant la première heure de la plage, donc on la retire
        ) =>
          otherHMValue
            ? formaters.getHeureEnMinutesFromHeureMinuteObject(hm) > //on garde les valeurs après le premier sélecteur
              formaters.getHeureEnMinutesFromHeureMinuteObject(otherHMValue)
            : true
      );
    }
  }
  return [];
};

const filterSamedayContraintesForConsistency = (
  hms: HMObj[],
  hm1?: HMObj,
  hm2?: HMObj
) => {
  if (hm1) {
    return hms.filter(
      (hm) => hm.heure * 60 + hm.minute > hm1.heure * 60 + hm1.minute
    );
  }
  if (hm2) {
    return hms.filter(
      (hm) => hm.heure * 60 + hm.minute < hm2.heure * 60 + hm2.minute
    );
  }
  return hms;
};

const hmsFromContraintes = (
  horairesRecord: Collections.HoraireInDB,
  jour: "openDays" | (typeof jours)[number],
  contrainteType: ContrainteType,
  contrainteNumber: 1 | 2,
  duréeMiniRdv: number,
  hm1?: HMObj,
  hm2?: HMObj
) => {
  if (
    !horairesRecord ||
    !horairesRecord.horaire ||
    !jour ||
    !contrainteType ||
    !contrainteNumber ||
    !duréeMiniRdv
  ) {
    return [];
  }

  if (jour !== "openDays" && !horairesRecord.horaire[jour]) {
    return [];
  }

  const extremesHm =
    jour === "openDays"
      ? Transforms.horaires.horairesExtremesForWeek(horairesRecord)
      : getExtremesFromDayPlages(horairesRecord.horaire[jour]!);
  if (!extremesHm || !extremesHm.start || !extremesHm.end) {
    return [];
  }
  const hms = getHms(extremesHm.start, extremesHm.end, duréeMiniRdv);
  if (contrainteType === "entre") {
    const filteredFromContrainteType = hmsFilterFromContrainteType(
      hms,
      contrainteType,
      contrainteNumber,
      contrainteNumber === 1 ? hm2 : hm1
    );
    const filteredFromContrainteTypeAndConsistency =
      filterSamedayContraintesForConsistency(
        filteredFromContrainteType,
        contrainteNumber === 1 ? undefined : hm1,
        contrainteNumber === 2 ? undefined : hm2
      );
    // return filterSamedayContraintesForConsistency(
    //   hmsFilterFromContrainteType(hms, contrainteType, contrainteNumber, hm2),
    //   hm1,
    //   hm2!
    // );
    return filteredFromContrainteTypeAndConsistency;
  }
  return hmsFilterFromContrainteType(hms, contrainteType, contrainteNumber);
};

const test = {
  hmsFilterFromContrainteType,
  filterSamedayContraintesForConsistency,
};

export { test, hmsFromContraintes };
