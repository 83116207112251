
interface ContraintesResetButtonProps {
  onClick: () => void;
}

const ContraintesResetButton = (props: ContraintesResetButtonProps) => {
  const { onClick } = props;
  return (
    <li className="list-group-item">
      <span>
        <button
          type="button"
          id="reset_contraintes"
          className="btn btn-warning"
          onClick={onClick}
        >
          Remise à zéro filtres
        </button>
      </span>
    </li>
  );
};

export default ContraintesResetButton;
