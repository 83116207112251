import { atom, useAtom, useSetAtom } from "jotai";
import { CadendarConsts, Collections } from "cadendar-shared";
import { formaters } from "vincent-utils";
import _ from "underts";
import { useThreeWeeksRdvs } from "../../rdvs/queries/rdvsQueries";
import useOwner from "../../users/hooks/useOwner";
import { trpc } from "../components/MainContainer";
import { ExistingPatient } from "cadendar-shared";
import { LocalNewPatient } from "../../famille/components/FamilleGridPanel";

export const TokenState = atom<string | null>(null);
export const SeletectedDoctorState = atom<string | null>(null);
export const LoggedUserId = atom<string | null>(null);

export const LoggedUserAtom = atom<Collections.User | null>(null);

export const SelectedPatientIdsAtom = atom<string[]>([]);

export const IsMultiSelectionEnabled = atom(false);
export const PatientByNameLookupState = atom("");
export const PatientByDDNLookupState = atom("");
export const useSelectedPatientIds = () => {
  const [selectedPatientIds, setSelectedPatientIds] = useAtom(
    SelectedPatientIdsAtom
  );
  const [isMultiSelectionEnabled, setIsMultiSelectionEnabled] = useAtom(
    IsMultiSelectionEnabled
  );
  const toggleMultiSelection = () => {
    setIsMultiSelectionEnabled((multi) => !multi);
  };

  const setAptRequestUsed = useSetAtom(aptRequestUsedAtom);
  const togglePatientId = (_id: string) => {
    setAptRequestUsed(null);
    if (isMultiSelectionEnabled) {
      if (selectedPatientIds.includes(_id)) {
        setSelectedPatientIds(selectedPatientIds.filter((id) => id !== _id));
      } else {
        setSelectedPatientIds([...selectedPatientIds, _id]);
      }
    } else {
      if (selectedPatientIds.includes(_id)) {
        setSelectedPatientIds([]);
      } else {
        setSelectedPatientIds([_id]);
      }
    }
  };
  const selectPatientId = (_id: string) => {
    setAptRequestUsed(null);
    if (isMultiSelectionEnabled) {
      if (selectedPatientIds.includes(_id)) {
        setSelectedPatientIds(selectedPatientIds.filter((id) => id !== _id));
      } else {
        setSelectedPatientIds([...selectedPatientIds, _id]);
      }
    } else {
      setSelectedPatientIds([_id]);
    }
  };
  const keepOnePatient = () =>
    setSelectedPatientIds((ids) => (ids.length > 0 ? [_.first(ids)!] : []));

  const resetSelectedPatientIds = () => {
    setAptRequestUsed(null);
    setSelectedPatientIds([]);
    setIsMultiSelectionEnabled(false);
  };
  return [
    selectedPatientIds,
    {
      togglePatientId,
      selectPatientId,
      keepOnePatient,
      isMultiSelectionEnabled,
      setIsMultiSelectionEnabled,
      toggleMultiSelection,
      resetSelectedPatientIds,
    },
  ] as const;
};
export const FamilleGridOpenState = atom(false);

export const TempPatientState = atom<LocalNewPatient>({
  nom: "",
  prenom: "",
  ddn: "",
  portable: "",
  email: "",
  famille_id: "",
});

type FamillyReffered = {
  type: "FAMILY_PATIENT";
  adresseur_id: string;
};

type OtherPatientReffered = {
  type: "OTHER_PATIENT";
  adresseur_id: string;
};

type OtherMeanReffered = {
  type: "OTHER_MEAN";
  value: string;
};

type AnnuaireReffered = {
  type: "ANNU";
};

export const ReffererState = atom<
  | FamillyReffered
  | OtherPatientReffered
  | OtherMeanReffered
  | AnnuaireReffered
  | null
>(null);

// interface RdvCanvas {
//   duree?: number;
//   motifs: CadendarConsts.MotifsKeys[];
// }

export interface RdvProposal {
  date?: Date;
  heure?: number;
  minute?: number;
  duree?: number;
  motifs?: CadendarConsts.MotifsKeys[];
  objet?: string;
  type?: CadendarConsts.typeNumsType;
  reminderChecked?: boolean;
  confirmChecked?: boolean;
}

//TODO on a deux états qui se ressemblent, RdvProposal, RdvCanvas

// export const RdvCanvasState = atom<RdvCanvas>({ motifs: [] });

export const RdvProposalAtom = atom<RdvProposal>({});

interface OldRdv {
  _id: Collections.Rdv["_id"]; //on a besoin de l'_id pour savoir si on est bien en train de modifier le bon rdv ?
  heure: Collections.Rdv["heure"];
  minute: Collections.Rdv["minute"];
  duree: Collections.Rdv["duree"];
  objet: Collections.Rdv["objet"];
  type: Collections.Rdv["type"];
  reminderChecked: boolean;
  confirmationChecked: boolean;
}

//convertit le rdv en format temporaire utilisé uniquement pour un rdv edit
function getOldRdvFromRdv(rdv: Collections.Rdv) {
  return {
    _id: rdv._id,
    heure: rdv.heure,
    minute: rdv.minute,
    duree: rdv.duree,
    objet: rdv.objet,
    type: rdv.type,
    reminderChecked: Array.isArray(rdv.reminders) && rdv.reminders.length > 0,
    confirmationChecked:
      Array.isArray(rdv.confirmationSms) && rdv.confirmationSms.length > 0,
  };
}

const OldRdvAtom = atom<OldRdv | null>(null);

export const useOldRdv = () => {
  const [oldRdv, setOldRdv] = useAtom(OldRdvAtom);
  const setOldRdvFromRdv = (rdv: Collections.Rdv) =>
    setOldRdv(getOldRdvFromRdv(rdv));
  return [oldRdv, { setOldRdvFromRdv }] as const;
};

export const usePreferences = () => {
  const owner = useOwner();
  const { data } = trpc.preference.get.useQuery(undefined, {
    enabled: !!owner,
  });
  return data;
  // const [preferences] = useSuperQuery(
  //   ["preferences", owner],
  //   preferencesFetch,
  //   null,
  //   { enabled: !!owner },
  //   owner
  // );
  // return preferences;
};
export const useDureeMiniRdv = () => {
  const preferences = usePreferences();
  return preferences?.duréeMinRdv || 15;
};
export const useDureeDefaultRdv = () => {
  const preferences = usePreferences();
  return preferences?.duréeDefautRdv || 15;
};
export const useMinAptDuration = () => {
  const preferences = usePreferences();
  return preferences?.duréeMinRdv || 15;
};
export const TodayState = atom(new Date());
export const selectedWeekAtom = atom(
  formaters.getDateDuLundiFromDateTime(new Date())
);
export const useSelectedWeek = () => {
  const [selectedWeek, setSelectedWeek] = useAtom(selectedWeekAtom);
  const setNextWeek = () => {
    setSelectedWeek((date) => {
      const nextWeek = new Date(date);
      nextWeek.setDate(nextWeek.getDate() + 7);
      return nextWeek;
    });
  };
  const setPrevWeek = () => {
    setSelectedWeek((date) => {
      const prevWeek = new Date(date);
      prevWeek.setDate(prevWeek.getDate() - 7);
      return prevWeek;
    });
  };
  return { selectedWeek, setNextWeek, setPrevWeek };
};

type RdvPopupMode =
  | "closed"
  | "rdvEdit"
  | "rdvCreate"
  | "aptRequestCreate"
  | "aptRequestEdit"
  | "confirmRdvSaved";
const rdvPopupState = atom<RdvPopupMode>("closed");
const confirmRdvSavedAtom = atom<null | confirmRdvSavedState>(null);
export const useRdvPopupState = () => {
  const [mode, setMode] = useAtom(rdvPopupState);
  const [rdvSavedState, setRdvSavedState] = useAtom(confirmRdvSavedAtom);
  const onOpenRdvPopup = (mode: Exclude<RdvPopupMode, "closed">) => {
    setMode(mode);
  };
  const onCloseRdvPopup = () => {
    setMode("closed");
    setRdvSavedState(null);
  };
  const isOpen = mode !== "closed";
  return {
    onOpenRdvPopup,
    onCloseRdvPopup,
    mode,
    isOpen,
    rdvSavedState,
  };
};

interface confirmRdvSavedState {
  patient: ExistingPatient;
  rdv: Collections.Rdv;
}

export const isStrategorRunningAtom = atom(false);

export const aptRequestUsedAtom = atom<null | string>(null);

const aptRequestIdSelectedAtom = atom<string | null>(null);
export const useSelectedAptRequest = () => {
  const [aptRequestId, setAptRequestId] = useAtom(aptRequestIdSelectedAtom);
  const { data: aptRequest } = trpc.aptRequest.getById.useQuery(aptRequestId!, {
    enabled: !!aptRequestId,
  });
  const onSelectAptRequestId = (aptRequestId: string | null) => {
    setAptRequestId(aptRequestId);
  };
  return { aptRequest, aptRequestId, onSelectAptRequestId };
};

export const selectedRdvIdAtom = atom<string | null>(null);

export const useSelectedRdv = () => {
  const [selectedRdvId, setSelectedRdvId] = useAtom(selectedRdvIdAtom);
  const { selectedWeek } = useSelectedWeek();
  const [threeWeekRdvs] = useThreeWeeksRdvs(selectedWeek);
  const selectedRdv = selectedRdvId
    ? threeWeekRdvs?.find((rdv) => rdv._id === selectedRdvId)
    : null;

  const onSetSelectedRdvId = (rdvId: string | null) => {
    setSelectedRdvId(rdvId);
  };
  return {
    selectedRdv,
    selectedRdvId,
    onSetSelectedRdvId,
  };
};

export const rdvToPostPoneIdAtom = atom<string | null>(null);

export const etatCivilModeAtom = atom<
  "newPatient" | "editPatient" | "viewPatient"
>("viewPatient");

export const constrainedHeightAtom = atom<number | null>(null);
