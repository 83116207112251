import styles from "./EmptyCase.module.css";
import { formaters } from "vincent-utils";
import RdvStars from "./RdvStars.tsx";
import React from "react";
import { AgendaCase, Transforms } from "cadendar-shared";
import classNames from "classnames";

interface EmptyCaseProps {
  rdv: AgendaCase & { stars?: number };
  heightMultiplier: number;
  onCaseClick: (rdv: AgendaCase) => void;
}

const EmptyCase = (props: EmptyCaseProps) => {
  const { rdv, heightMultiplier, onCaseClick } = props;

  const style = {
    height: heightMultiplier * rdv.duree + "em",
  };

  const { stars } = rdv;

  const uniqueID = Transforms.rdvs.getUniqueId(rdv);
  const hmString = formaters.formatHMObjectForDisplay(rdv);
  const isZeroMinute = (hmString: string) => hmString.split(":")[1] === "00";

  const handleClick = () => {
    if (Transforms.rdvs.isFakeCase(rdv)) {
      //est-ce que ça ne devrait pas plutôt être géré au niveau du container ?
      return;
    }
    onCaseClick(rdv);
  };
  return (
    <div
      style={style}
      key={uniqueID}
      className={classNames(styles.case_rdv_empty, {
        [styles.topBar]: isZeroMinute(hmString),
      })}
      id={uniqueID}
      onClick={handleClick}
      data-testid={"RdvCaseEmpty"}
    >
      <div className={styles.hourDisplay}>
        {formaters.formatHMObjectForDisplay(rdv)}
      </div>
      <RdvStars stars={stars} />
    </div>
  );
};

export default EmptyCase;
