import { useCallback, useState } from "react";
import { CadendarConsts } from "cadendar-shared";
import { HoraireInDB, Jours } from "cadendar-shared";
import { produce } from "immer";
import HorairesPanel, { HorairesPanelProps } from "../components/HorairesPanel";
import { formaters } from "vincent-utils";
import moment from "moment";
import _ from "underts";
import { HMObject } from "cadendar-shared";
import { trpc } from "../../main/components/MainContainer";
import { useInitialized } from "../../../utils/hooks.ts";
import HoraireDisplayChooser from "../components/HoraireDisplayChooser.tsx";
import useAllHoraires from "../hooks/useAllHoraires.ts";
import { DateTime } from "luxon";

const { jours } = CadendarConsts;

const getDefaultLocalHoraire = () =>
  jours.reduce(
    (result, jour) => ({
      ...result,
      [jour]: [],
    }),
    {} as HorairesPanelProps["localHoraire"]
  );

const isValidHoraireString = (hstring: string) =>
  !!formaters.getHeureMinuteObjectFromHoraireString(hstring);

const plageInputValidator = (plage: { start: string; end: string }) => {
  const result: { start: string[]; end: string[] } = { start: [], end: [] };
  if (!isValidHoraireString(plage.start)) {
    result.start.push("valeur incorrecte");
  }
  if (!isValidHoraireString(plage.end)) {
    result.end.push("valeur incorrecte");
  }
  if (
    isValidHoraireString(plage.start) &&
    isValidHoraireString(plage.end) &&
    new HMObject(plage.start).minus(new HMObject(plage.end)) > 0
  ) {
    result.start.push("la fin de plage doit être postérieure au début");
    result.end.push("la fin de plage doit être postérieure au début");
  }
  return result;
};

const plagesInputValidator = (plages: { start: string; end: string }[]) => {
  const length = plages.length;
  const result = _.range(0, length).map((_) => ({
    start: [] as string[],
    end: [] as string[],
  }));
  for (let i = 0; i < length - 1; i++) {
    if (
      !isValidHoraireString(plages[i].end) ||
      !isValidHoraireString(plages[i + 1].start)
    ) {
      continue;
    }
    if (
      new HMObject(plages[i].end).minus(new HMObject(plages[i + 1].start)) > 0
    ) {
      result[i].end.push("les plages ne doivent pas se confondre");
      result[i + 1].start.push("les plages ne doivent pas se confondre");
    }
  }
  return result;
};

const validateLocalState = (localState: HorairesPanelProps["localHoraire"]) => {
  const result1 = Object.entries(localState).reduce(
    (result, [jour, plages]) => ({
      ...result,
      [jour]: plages.map((plage) => plageInputValidator(plage)),
    }),
    {} as { [jour in Jours]: ReturnType<typeof plagesInputValidator> }
  );
  const result2 = Object.entries(localState).reduce(
    (result, [jour, plages]) => ({
      ...result,
      [jour]: plagesInputValidator(plages),
    }),
    {} as { [jour in Jours]: ReturnType<typeof plagesInputValidator> }
  );
  return Object.entries(result1).reduce(
    (result, [jour, plages]) => ({
      ...result,
      [jour]: plages.map((plage, idx) => ({
        start: [...plage.start, ...result2[jour as Jours][idx].start],
        end: [...plage.end, ...result2[jour as Jours][idx].end],
      })),
    }),
    {} as { [jour in Jours]: ReturnType<typeof plagesInputValidator> }
  );
};

const addDurationToHoraireString = (horaireStr: string, minutes: number) => {
  const hour = formaters.getHeureMinuteObjectFromHoraireString(horaireStr);
  if (!hour) {
    return horaireStr;
  }
  return new HMObject(hour).add(minutes).toString();
};

const addPlageToDay = (day: { start: string; end: string }[]) => {
  if (day.length === 0) {
    return [{ start: "8:00", end: "13:00" }];
  } else {
    const lastBorne = _.last(day)!;
    return [
      ...day,
      {
        start: addDurationToHoraireString(lastBorne.end, 60),
        end: addDurationToHoraireString(lastBorne.end, 120),
      },
    ];
  }
};

const removePlageFromDay = (day: { start: string; end: string }[]) => {
  if (day.length === 0) {
    return day;
  } else {
    return day.slice(0, -1);
  }
};

const removeEmptyArrayFromHoraire = (
  horaire: ReturnType<typeof getDefaultLocalHoraire>
) =>
  Object.entries(horaire).reduce(
    (result, [jour, plages]) => ({
      ...result,
      ...(plages.length > 0 && { [jour]: plages }),
    }),
    {} as typeof horaire
  );

const getHoraireToSave = (
  localHoraire: ReturnType<typeof getDefaultLocalHoraire>,
  date: Date
) => {
  const cleanedHoraire = removeEmptyArrayFromHoraire(localHoraire);
  const horaire = Object.entries(cleanedHoraire).reduce(
    (result, [jour, plages]) => {
      return {
        ...result,
        [jour]: plages.map((plage) => ({
          start: formaters.getHeureMinuteObjectFromHoraireString(plage.start),
          end: formaters.getHeureMinuteObjectFromHoraireString(plage.end),
        })),
      };
    },
    {} as HoraireInDB["horaire"]
  );
  return { horaire, format: 2, date };
};

const getHoraireAsString = (horaire: HoraireInDB["horaire"]) => {
  return Object.entries(horaire).reduce(
    (result, [jour, plages]) => ({
      ...result,
      [jour]: plages!.map((plage) => ({
        start: formaters.formatHMObjectForDisplay(plage.start),
        end: formaters.formatHMObjectForDisplay(plage.end),
      })),
    }),
    getDefaultLocalHoraire()
  );
};

const HorairesContainer = () => {
  const [horaireIndexToDisplay, setHoraireIndexToDisplay] = useState(1);
  const horaires = useAllHoraires();
  const horairesDates = horaires.map((horaire) => horaire.date);
  const onPrevWeekToDisplay = () =>
    setHoraireIndexToDisplay((i) => {
      if (i < horaires.length) {
        return i + 1;
      }
      return i;
    });

  const onNextWeekToDisplay = () =>
    setHoraireIndexToDisplay((i) => {
      if (i > 1) {
        return i - 1;
      }
      return i;
    });

  // const lastHoraire = horaires && _.last(_.sortBy(horaires, "date"));
  const lastHoraire = horaires ? _.nth(horaires, -1) : null;
  // const horaireAsString =
  //   lastHoraire &&
  //   Object.entries(lastHoraire.horaire).reduce(
  //     (result, [jour, plages]) => ({
  //       ...result,
  //       [jour]: plages!.map((plage) => ({
  //         start: formaters.formatHMObjectForDisplay(plage.start),
  //         end: formaters.formatHMObjectForDisplay(plage.end),
  //       })),
  //     }),
  //     getDefaultLocalHoraire()
  //   );
  const [localHoraire, setLocalHoraire] = useState(getDefaultLocalHoraire());
  const onOpenEditing = () => {
    setIsEditing(true);
    setLocalHoraire(
      lastHoraire
        ? getHoraireAsString(lastHoraire.horaire)
        : getDefaultLocalHoraire()
    );
  };

  const [isEditing, setIsEditing] = useState(false);
  const earliestDate =
    lastHoraire && moment(lastHoraire.date).add(1, "week").toDate();
  const nextLundi = moment(formaters.getDateDuLundiFromDateTime(new Date())!)
    .add(1, "week")
    .toDate();
  const initialDate =
    earliestDate && nextLundi.getTime() > earliestDate.getTime()
      ? nextLundi
      : earliestDate;
  const [currentWeek, setCurrentWeek, resetCurrentWeek] = useInitialized(
    initialDate,
    formaters.getDateDuLundiFromDateTime(new Date())
  );

  const setNextWeek = () =>
    setCurrentWeek((date) => {
      let newDate = new Date(date);
      do {
        newDate = DateTime.fromJSDate(newDate).plus({ week: 1 }).toJSDate();
      } while (horairesDates.find((d) => newDate.getTime() === d.getTime()));
      return newDate;
    });
  const setPrevWeek = () =>
    setCurrentWeek((date) => {
      const currentLundi = formaters.getDateDuLundiFromDateTime(new Date());
      let newDate = new Date(date);
      do {
        newDate = DateTime.fromJSDate(newDate).minus({ week: 1 }).toJSDate();
      } while (horairesDates.find((d) => newDate.getTime() === d.getTime()));
      if (newDate.getTime() > currentLundi.getTime()) {
        return newDate;
      }
      return date;
    });
  const setPlages = (jour: Jours, plages: { start: string; end: string }[]) => {
    return setLocalHoraire((horaire) =>
      produce(horaire, (draft) => {
        draft[jour] = plages;
      })
    );
  };
  const addPlage = useCallback(
    (jour: Jours) =>
      setLocalHoraire((horaire) => ({
        ...horaire,
        [jour]: addPlageToDay(horaire[jour]),
      })),
    []
  );
  const removePlage = useCallback(
    (jour: Jours) =>
      setLocalHoraire((horaire) => ({
        ...horaire,
        [jour]: removePlageFromDay(horaire[jour]),
      })),
    []
  );
  const resetUI = () => {
    setIsEditing(false);
    setLocalHoraire(getDefaultLocalHoraire());
    resetCurrentWeek();
  };
  const trpcUtils = trpc.useContext();
  const saveNewHorairesMutation = trpc.horaire.save.useMutation({
    onSettled: () => {
      trpcUtils.horaire.invalidate();
      resetUI();
    },
  });
  const saveNewHoraires = () => {
    const horaireInDb = getHoraireToSave(localHoraire, currentWeek);
    saveNewHorairesMutation.mutate(horaireInDb);
  };
  const horaireToDisplay = horaires && _.nth(horaires, -horaireIndexToDisplay);
  return (
    <div>
      {horaireToDisplay ? (
        <HoraireDisplayChooser
          onChoosePrevHoraire={onPrevWeekToDisplay}
          onChooseNextHoraire={onNextWeekToDisplay}
          prevEnabled={horaireIndexToDisplay < horaires.length}
          nextEnabled={horaireIndexToDisplay > 1}
          dateChoosen={horaireToDisplay.date}
        />
      ) : null}

      <HorairesPanel
        horaire={_.nth(horaires, -horaireIndexToDisplay)}
        localHoraire={localHoraire}
        setPrevEditWeek={setPrevWeek}
        setNextEditWeek={setNextWeek}
        addPlage={addPlage}
        removePlage={removePlage}
        setPlages={setPlages}
        isEditing={isEditing}
        setIsEditing={onOpenEditing}
        currentWeek={currentWeek}
        saveNewHoraires={saveNewHoraires}
        validationMap={validateLocalState(localHoraire)}
        onCancel={resetUI}
      />
    </div>
  );
};

export default HorairesContainer;
