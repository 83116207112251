import FamilleGridContainer from "../../famille/containers/FamilleGridContainer";
import { useCallback, useEffect } from "react";
import { FamilleGridOpenState, useRdvPopupState } from "../state/globalState";
import { useAtom } from "jotai";

const FamilleGridManager = () => {
  const [showFamilleGrid, setFamilleGridOpenState] =
    useAtom(FamilleGridOpenState);
  // const [, { resetFamille }] = useFamille();
  const handleClose = () => {
    setFamilleGridOpenState(false);
    // resetFamille();
  };
  const handleEscape = useCallback((e: KeyboardEvent) => {
    if (e.code === "Escape") {
      handleClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);
  return (
    <div>
      {showFamilleGrid ? <FamilleGridContainer onClose={handleClose} /> : null}
    </div>
  );
};

export default FamilleGridManager;
