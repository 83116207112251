import { useEffect, useRef, useState } from "react";
import styles from "./EditPatient.module.css";
import EtatCivilField from "./EtatCivilField";
import { formaters, checkers } from "vincent-utils";
import _ from "underts";
import {
  etatCivilModeAtom,
  FamilleGridOpenState,
  useSelectedPatientIds,
} from "../../main/state/globalState";
import { useAtom } from "jotai";
import { CadendarConsts, DDNSchema, E164Schema } from "cadendar-shared";
import useTempPatient from "../../ficheAppel/hooks/useTempPatient";
import useOwner from "../../users/hooks/useOwner";
import { existingPatientSchema, newPatientSchema } from "cadendar-shared";
import { trpc } from "../../main/components/MainContainer";
import { ExistingPatient } from "cadendar-shared";
import classNames from "classnames";
import Button from "../../main/components/Button.tsx";
import { convertLocalNewPatientToNewPatient } from "../../famille/components/FamilleGridPanel.tsx";

const { etatCivilStrings } = CadendarConsts;

export interface PatientEtatCivil {
  NOM: string;
  PRENOM: string;
  DDN: string;
  FIXE: string;
  PORTABLE: string;
  EMAIL: string;
  COMMENTAIRES: string;
}

type keys = keyof typeof etatCivilStrings;

export const validate = (values: Record<keys, string>) => {
  const errors = {} as Record<keys, string>;
  if (!values.NOM) {
    errors.NOM = "obligatoire";
  } else if (!checkers.isNomOrPrenom(values.NOM)) {
    errors.NOM = "nom invalide";
  }
  if (!values.PRENOM) {
    errors.PRENOM = "obligatoire";
  } else if (!checkers.isNomOrPrenom(values.PRENOM)) {
    errors.PRENOM = "prénom invalide";
  }
  if (!values.DDN) {
    errors.DDN = "obligatoire";
  } else if (!checkers.isValidDdn(values.DDN)) {
    errors.DDN = "ddn invalide";
  }
  if (values.PORTABLE && !checkers.isPortable(values.PORTABLE)) {
    errors.PORTABLE = "numéro de portable invalide";
  }
  return errors;
};

export const converters = {
  DDN: function (ddnString: string) {
    "use strict";
    return formaters.formatDDNForStoring(ddnString);
  },
  NOM: function (nomString: string) {
    "use strict";
    return formaters.capitaliseEveryFirstLetter(nomString);
  },
  PRENOM: function (nomString: string) {
    "use strict";
    return formaters.capitaliseEveryFirstLetter(nomString);
  },
  FIXE: function (fixe: string) {
    "use strict";
    return formaters.formatTelNumberForDisplay(fixe);
  },
  PORTABLE: function (portable: string) {
    "use strict";
    return formaters.formatTelNumberForDisplay(portable);
  },
  EMAIL: (value: string) => value,
  COMMENTAIRES: (value: string) => value,
};

type TempInput = { [K in keys]: string };

export const getPatientDTOFromTempInput = (tempInput: TempInput) => {
  const portable164Parsed = E164Schema.safeParse(tempInput.PORTABLE);
  const portableE164 = portable164Parsed.success
    ? portable164Parsed.data
    : null;
  const ddn = DDNSchema.parse(tempInput.DDN);

  const dto = {
    nom: tempInput.NOM.toLowerCase(),
    prenom: tempInput.PRENOM.toLowerCase(),
    ddn,
    portableE164,
    email: tempInput.EMAIL || "",
    commentaires: tempInput.COMMENTAIRES || "",
  };
  return dto;
};

export const patientToTempInput = (
  patient:
    | Partial<
        Pick<
          ExistingPatient,
          "nom" | "prenom" | "ddn" | "portableE164" | "email" | "commentaires"
        >
      >
    | {
        nom: string;
        prenom: string;
        ddn: string;
        portable: string;
        email: string;
        commentaires?: string;
      }
) => {
  return {
    NOM: (patient.nom && converters.NOM(patient.nom)) || "",
    PRENOM: (patient.prenom && converters.PRENOM(patient.prenom)) || "",
    DDN: (patient.ddn && converters.DDN(patient.ddn)) || "",
    PORTABLE:
      ("portable" in patient && converters.PORTABLE(patient.portable)) ||
      ("portableE164" in patient &&
        patient.portableE164 &&
        converters.PORTABLE(patient.portableE164)) ||
      "",
    EMAIL: (patient.email && converters.EMAIL(patient.email)) || "",
    COMMENTAIRES: patient.commentaires || "",
  };
};

export const validatorMap = {
  NOM: (value: string) => {
    return checkers.isNomOrPrenom(value);
  },
  PRENOM: (value: string) => {
    return checkers.isNomOrPrenom(value);
  },
  DDN: (value: string) => {
    return checkers.isValidDdn(value);
  },
  FIXE: (value: string) => {
    return !!checkers.isFixe(value);
  },
  PORTABLE: (value: string) => {
    return !!checkers.isPortable(value);
  },
  EMAIL: (value: string) => {
    return !!checkers.isEmail(value);
  },
  COMMENTAIRES: (_value: string) => {
    return true;
  },
};

interface EditPatientProps {
  selectedPatientId: string | null;
}

const EditPatient = (props: EditPatientProps) => {
  const [etatCivilMode, setEtatCivilMode] = useAtom(etatCivilModeAtom);
  const isEditing =
    etatCivilMode === "editPatient" || etatCivilMode === "newPatient";
  const { data: selectedPatient } = trpc.patient.getById.useQuery(
    props.selectedPatientId!,
    { enabled: !!props.selectedPatientId }
  );
  const ref = useRef<HTMLDivElement>(null);
  const { tempPatient } = useTempPatient();
  const _hasFamille = !!selectedPatient && !!selectedPatient.famille_id;

  const [tempInput, setTempInput] = useState(patientToTempInput(tempPatient));
  useEffect(() => {
    setTempInput(patientToTempInput(tempPatient));
  }, [JSON.stringify(tempPatient)]);
  useEffect(() => {
    if (!selectedPatient) return;
    setTempInput(patientToTempInput(selectedPatient));
  }, [JSON.stringify(selectedPatient)]);
  // useLayoutEffect(() => {
  //   if (ref.current) {
  //     ref.current.scrollIntoView();
  //   }
  // }, []);
  const initialTouchedState = Object.keys(etatCivilStrings).reduce(
    (result, key) => ({ ...result, [key]: false }),
    {} as { [K in keys]: boolean }
  );

  const [touchedState, setTouchedState] = useState(initialTouchedState);
  const onCancelPatientEditing = () => {
    setEtatCivilMode("viewPatient");
  };
  const [_ignored, { selectPatientId }] = useSelectedPatientIds();
  const utils = trpc.useContext();
  const commitNewPatientMutation = trpc.patient.create.useMutation({
    onSuccess: (value: string) => {
      setEtatCivilMode("viewPatient");
      utils.patient.invalidate();
      utils.rdv.invalidate(); //on invalide aussi les rdvs car si le nom du patient est modifié ça modifie le rdv
      selectPatientId(value);
      setEtatCivilMode("viewPatient");
    },
  });
  const commitEditPatientMutation = trpc.patient.saveEdited.useMutation({
    onSuccess: () => {
      setEtatCivilMode("viewPatient");
      utils.patient.invalidate();
      utils.rdv.invalidate();
    },
  });
  const owner = useOwner();
  const onSubmit = () => {
    if (!owner) throw new Error("owner is null in onSubmit");
    if (etatCivilMode === "newPatient") {
      const newPatientDTO = {
        ...(tempPatient.nom
          ? convertLocalNewPatientToNewPatient(tempPatient)
          : null),
        ...getPatientDTOFromTempInput(tempInput),
      };
      commitNewPatientMutation.mutate(newPatientSchema.parse(newPatientDTO));

      return;
    }
    if (etatCivilMode === "editPatient") {
      if (!selectedPatient)
        throw new Error("selectedPatient is null in onSubmit");
      const patientDTO = {
        ...selectedPatient,
        ...getPatientDTOFromTempInput(tempInput),
      };

      commitEditPatientMutation.mutate(
        existingPatientSchema.parse({
          ...patientDTO,
        })
      );
      setEtatCivilMode("viewPatient");
      return;
    }
  };
  const onClickPatientEdit = () => setEtatCivilMode("editPatient");
  const [, openFamilleGrid] = useAtom(FamilleGridOpenState);
  const onClickOpenFamilleGrid = () => openFamilleGrid(true);
  const errors = validate(tempInput);

  const newPatientFields = Object.keys(etatCivilStrings) as keys[];

  const handleTempInput = (key: keys, value: string) => {
    setTempInput((tempInput) => ({ ...tempInput, [key]: value }));
    setTouchedState((state) => ({ ...state, [key]: true }));
  };

  const handleBlur = (key: keys) => {
    const value = tempInput[key];
    if (validatorMap[key](value)) {
      const converted = converters[key](value) || "";
      setTempInput((state) => ({ ...state, [key]: converted }));
    }
  };
  return (
    <div
      className={classNames(
        {
          [styles.isEditing]: isEditing,
        },
        styles.container
      )}
      ref={ref}
    >
      <div>
        {isEditing ? (
          ""
        ) : (
          <div className={styles.spacer}>
            <Button
              title="Editer"
              onClick={onClickPatientEdit}
              id="etat_civil_edit"
            />
          </div>
        )}
        {newPatientFields.map((key) => (
          <EtatCivilField
            errors={errors[key]}
            label={etatCivilStrings[key]}
            id={key}
            name={key}
            value={tempInput[key] || ""}
            onChange={(value) => handleTempInput(key, value)}
            onBlur={() => {
              handleBlur(key);
            }}
            touched={touchedState[key]}
            isEditing={isEditing}
            key={"EtatCivilFieldKey" + key}
            isValid={validatorMap[key](tempInput[key])}
          />
        ))}

        {isEditing ? (
          <div className={styles.buttonsSpacer}>
            <Button
              title="Enregistrer"
              onClick={onSubmit}
              style="main"
              id="button_save_patient"
              disabled={Object.keys(errors).length > 0}
            />
            <Button
              title="Annuler"
              onClick={onCancelPatientEditing}
              style="secondary"
              id="button_cancel_patient"
            />
          </div>
        ) : (
          ""
        )}

        {isEditing ? (
          ""
        ) : _hasFamille ? (
          <Button
            title={"Editer Famille Existante"}
            onClick={onClickOpenFamilleGrid}
            id={"open_famille_dialog"}
            style="secondary"
          />
        ) : (
          <Button
            title={"Créer Famille"}
            onClick={onClickOpenFamilleGrid}
            id={"open_famille_dialog"}
          />
        )}
      </div>
    </div>
  );
};

export default EditPatient;
