import {
  isStrategorRunningAtom,
  rdvToPostPoneIdAtom,
  useSelectedPatientIds,
  useSelectedWeek,
} from "../../main/state/globalState";
import {
  StrategorPatient,
  CadendarConsts,
  StrategorFilters,
} from "cadendar-shared";
import useFicheAppel from "./useFicheAppel";
import useTempPatient from "./useTempPatient";
import useRdvProposal from "./useRdvProposal";
import { useAtomValue } from "jotai";
import { useContraintes } from "../components/ContraintesPanel";
import _ from "lodash";
import { trpc } from "../../main/components/MainContainer";
import { strategorPatientSchema } from "cadendar-shared";
export default () => {
  const isStrategorRunning = useAtomValue(isStrategorRunningAtom); //todo : comment est-ce qu'on le met true ?
  const { rdvProposal } = useRdvProposal();
  const { duree, motifs } = rdvProposal;
  const convertedMotifs =
    motifs?.map((motif) => CadendarConsts.motifConsts[motif]) || [];
  const [selectedPatientIds] = useSelectedPatientIds();
  const { ddn } = useFicheAppel();
  const { tempPatient, reffererType, reffererId } = useTempPatient();
  const strategorPatient: StrategorPatient | null = ddn
    ? {
        ...{ ddn },
        ...(reffererType ? { adressé: reffererType } : null),
        ...(reffererId ? { adresseur_id: reffererId } : null),
      }
    : null;
  const { selectedWeek } = useSelectedWeek();
  const postPonedRdvId = useAtomValue(rdvToPostPoneIdAtom);
  const { data: rdvCandidats, isFetched } =
    trpc.strategor.getRdvCandidates.useQuery(
      {
        weekDate: selectedWeek,
        duree,
        motifs: convertedMotifs,
        ...(selectedPatientIds.length > 0
          ? { patientIds: selectedPatientIds }
          : {}),
        ...(strategorPatientSchema.safeParse(strategorPatient).success
          ? { strategorPatient: strategorPatient! }
          : {}),
        ...(postPonedRdvId ? { postPonedRdvId } : {}),
      },
      { enabled: isStrategorRunning && !!strategorPatient }
    );
  const { contraintesConverted } = useContraintes();
  const filter = StrategorFilters.buildContraintesFilter(contraintesConverted);
  const rdvCandidatsFiltered = rdvCandidats
    ? filter(Object.values(_.groupBy(rdvCandidats, "date")))
    : [];
  return [rdvCandidatsFiltered, isFetched] as const;
};
