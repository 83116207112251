;
import StatsPanel from "../components/StatsPanel";
import { trpc } from "../../main/components/MainContainer";

const StatsContainer = () => {
  const { data: statsObject } = trpc.stats.get.useQuery();
  return <>{statsObject ? <StatsPanel statsObject={statsObject} /> : ""}</>;
};

export default StatsContainer;
