import { useState } from "react";
import RdvCaracs, { RdvCaracsCallbacks } from "./RdvCaracs";
import EditRdvButtonsCommit from "./EditRdvButtonsCommit";
import CancelRdvsButtonsCommit from "./CancelRdvButtons";
import { formaters } from "vincent-utils";
import { Collections } from "cadendar-shared";
import { DateTime } from "luxon";
import _ from "underts";
import { EditRdvState } from "../containers/EditRdvContainer";
import { ExistingPatient } from "cadendar-shared";
import styles from "./EditRdv.module.css";

interface EditRdvProps {
  rdvCaracsState: EditRdvState;
  hmPossibles: { heure: number; minute: number }[];
  dureesPossibles: number[];
  remindersCheckBoxEnabled: boolean;
  confirmCheckBoxEnabled: boolean;
  onSaveRdv: () => void;
  onCancelRdvEdit: () => void;
  onDeleteWithoutLogging: () => void;
  onCancelWithLogging: () => void;
  onPostPone: () => void;
  rdvCaracsCallbacks: RdvCaracsCallbacks;
  users: Collections.User[];
  rdv: Collections.Rdv;
  patient: ExistingPatient | null; //on peut être en train d'effacer une plage fermée
}

const EditRdv = (props: EditRdvProps) => {
  const { rdv, patient } = props;

  const createdOn = DateTime.fromJSDate(
    rdv.log.created.timestamp
  ).toLocaleString({
    weekday: "short",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  const editedOn =
    rdv.log.edited && _.first(rdv.log.edited)
      ? DateTime.fromJSDate(_.first(rdv.log.edited)!.timestamp).toLocaleString({
          weekday: "short",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })
      : null;

  const createdBy =
    props.users.find((u) => u._id === rdv.log.created.editor)?.messagename ||
    "";
  const editedBy =
    rdv.log.edited && _.first(rdv.log.edited)
      ? props.users.find((u) => u._id === _.first(rdv.log.edited!)!.editor)
          ?.username || ""
      : null;

  const recordMode = rdv.log.created?.mode || "";

  const {
    onSaveRdv,
    onCancelRdvEdit,
    onDeleteWithoutLogging,
    onCancelWithLogging,
    onPostPone,
  } = props;

  const [showCancel, setShowCancel] = useState(false);

  const misAJourString = editedOn
    ? "Mis à jour le : " + editedOn + " par " + editedBy
    : null;
  const nom = patient?.nom;
  const prenom = patient?.prenom;

  const npDisplay = (nom &&
    prenom &&
    formaters.formatNomPrenomForDisplay({ nom, prenom })) || {
    nom: "PAS DE PATIENT SéLECTIONNé".toLocaleUpperCase(),
    prenom: "",
  };
  const cancelButtons = showCancel ? (
    <CancelRdvsButtonsCommit
      onSimpleDelete={onDeleteWithoutLogging}
      onCancel={onCancelWithLogging}
      onPostPone={onPostPone}
    />
  ) : (
    <span />
  );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.patient}>
        {npDisplay.nom + " " + npDisplay.prenom}
      </div>
      <div>
        <div>
          <div className={styles.created}>
            Créé le : {createdOn} par {createdBy}{" "}
            {" (" +
              (recordMode === "manuel" ? "manuellement" : "automatiquement") +
              ")"}
            <div className={styles.created}>{misAJourString}</div>
          </div>
        </div>
        <div></div>
      </div>
      <div>
        <RdvCaracs
          rdvsCaracsState={{
            ...props.rdvCaracsState,
            objet: props.rdvCaracsState.objet || "",
            hmPossibles: props.hmPossibles,
            dureesPossibles: props.dureesPossibles,
            remindersCheckBoxEnabled: props.remindersCheckBoxEnabled,
            confirmCheckBoxEnabled: props.confirmCheckBoxEnabled,
          }}
          rdvsCaracsCallbacks={props.rdvCaracsCallbacks}
        />
      </div>
      <div>{cancelButtons}</div>
      <div>
        <EditRdvButtonsCommit
          onSaveRdv={onSaveRdv}
          onCancelRdvEdit={onCancelRdvEdit}
          onDeleteWithoutLogging={onDeleteWithoutLogging}
          onCancelWithLogging={onCancelWithLogging}
          onPostPone={onPostPone}
        />
      </div>
    </div>
  );
};
export default EditRdv;
