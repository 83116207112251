import styles from "./MiddleBoxes.module.css";
import ListRdvsPatient from "../../rdvs/components/ListRdvsPatient";
import {
  etatCivilModeAtom,
  useSelectedPatientIds,
} from "../../main/state/globalState";
import { useAtom, useAtomValue } from "jotai";
import PatientLookup from "../../patients/components/PatientLookup";
import EditPatient from "../../patients/components/EditPatient";
import ResumePatient from "../../patients/components/ResumePatient";
import Button from "../../main/components/Button.tsx";
import AptRequestList from "../../rdvs/components/AptRequestList.tsx";
import _ from "underts";

interface MiddleBoxesProps {
  onOpenNewFicheAppel: () => void;
  onOpenFamilleGrid: () => void;
  isShowingFicheAppel: boolean;
  isRdvPopupOpen: boolean;
}

const MiddleBoxes = (props: MiddleBoxesProps) => {
  const [selectedPatientsIds] = useSelectedPatientIds();
  const isPatientSelected = _.isNonEmpty(selectedPatientsIds);
  const selectedPatientId =
    (isPatientSelected && _.head(selectedPatientsIds)) || null;
  const [etatCivilMode, setEtatCivilMode] = useAtom(etatCivilModeAtom);
  const _isAddingPatient = etatCivilMode === "newPatient";
  const showEtatCivil = isPatientSelected || _isAddingPatient;
  const isShowingFicheAppel = props.isShowingFicheAppel;
  const { isRdvPopupOpen } = props;
  const handleNewFicheAppel = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onOpenNewFicheAppel();
  };
  const [, { resetSelectedPatientIds }] = useSelectedPatientIds();
  return (
    <div>
      <div className={styles.buttonsSpacer}>
        <Button title="Ouvrir fiche d'appel" onClick={handleNewFicheAppel} />
        <Button
          title="Nouveau patient"
          onClick={() => {
            resetSelectedPatientIds();
            setEtatCivilMode("newPatient");
          }}
        />
        <Button title="Nouvelle famille" onClick={props.onOpenFamilleGrid} />
      </div>

      <div className={styles.cardSpacer}>
        {
          <div className={styles.col25}>
            <div className={styles.card}>
              <div>
                <div className="title">Choisir un patient :</div>
                <div>
                  <PatientLookup />
                </div>
              </div>
            </div>
          </div>
        }

        <div className={styles.col30}>
          <div className={styles.buttonFicheAppel}></div>
          {isPatientSelected ? (
            <div className={styles.card}>
              <ResumePatient />
            </div>
          ) : (
            ""
          )}
          {showEtatCivil ? (
            <div className={styles.card}>
              <EditPatient
                selectedPatientId={selectedPatientId}
                key={selectedPatientId}
              />
            </div>
          ) : (
            ""
          )}
        </div>

        {isPatientSelected && selectedPatientId ? (
          <div className={styles.col40}>
            <div className={styles.card}>
              <ListRdvsPatient />
            </div>
            <AptRequestList
              patientId={selectedPatientId}
              onShowFicheAppel={() => props.onOpenNewFicheAppel()}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MiddleBoxes;
