import { formaters } from "vincent-utils";

import { CadendarConsts } from "cadendar-shared";
const { getTypeConstFromTypeNumber, typeTranslator } = CadendarConsts;
import _ from "underts";
import { trpc } from "../../main/components/MainContainer";
import { DateTime } from "luxon";

interface ListRdvsPatientProps {
  selectedPatientId: string;
}

const ListRdvsPatient2 = (props: ListRdvsPatientProps) => {
  const { data: rdvs } = trpc.rdv.findByPatientIds.useQuery({
    patientIds: [props.selectedPatientId],
  });

  return (
    <>
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Date</th>
              <th>Heure</th>
              <th>Type</th>
              <th>Objet</th>
            </tr>
          </thead>
          <tbody>
            {_.sortBy(
              rdvs || [],
              (rdv) =>
                rdv.date.getTime() +
                rdv.heure * 60 * 60 * 1000 +
                rdv.minute * 1000
            ).map((rdv) => (
              <tr key={"rdvKey" + rdv._id}>
                <td>
                  {formaters.capitaliseEveryFirstLetter(
                    DateTime.fromJSDate(rdv.date).toLocaleString({
                      day: "2-digit",
                      month: "short",
                      year: "2-digit",
                    })
                  )}
                </td>
                <td>
                  {rdv.heure}h
                  {formaters.convertSingleDigitToTwoDigits(rdv.minute)}
                </td>
                <td>{typeTranslator[getTypeConstFromTypeNumber(rdv.type)]}</td>
                <td>{rdv.objet}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListRdvsPatient2;
