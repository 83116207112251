;
import StatsLines from "./StatsLines";

interface StatsPanelProps {
  statsObject: {
    _id: string;
    owner: string;
    date: Date;
    nbrPatients: number;
    nbrPatientsAdr: number;
    nbrPremiereConsult: number;
    ageMoyenPatients: number;
    nbrPatientsPP: number;
  };
}

const StatsPanel = (props: StatsPanelProps) => {
  const { statsObject } = props;
  const stats = [
    {
      labelStat: "Nombre de Patients",
      value: statsObject.nbrPatients.toString(),
    },
    // {
    //   labelStat: "Age moyen des Patients (12 derniers mois il y a un an)",
    //   value: statsObject.lastYearAgo?.toString() || "",
    // },
    // {
    //   labelStat: "Age moyen des Patients (12 derniers mois)",
    //   value: statsObject.lastYearMeanAge?.toString() || "",
    // },
    // {
    //   labelStat: "Age moyen des Patients (6 derniers mois)",
    //   value: statsObject.lastSixMonthsMeanAge?.toString() || "",
    // },
    // {
    //   labelStat: "Age moyen des Patients (dernier mois)",
    //   value: statsObject.lastMonthMeanAge?.toString() || "",
    // },
    // {
    //   labelStat: "Age moyen des Patients (dernière semaine)",
    //   value: statsObject.lastWeekMeanAge?.toString() || "",
    // },
    {
      labelStat: "Nombre de 1ères consultations",
      value: statsObject.nbrPremiereConsult.toString(),
    },
    {
      labelStat: "Nombre de patients adressés",
      value: statsObject.nbrPatientsAdr.toString(),
    },
    {
      labelStat: "Nombre de patients PP",
      value: statsObject.nbrPatientsPP.toString(),
    },
    // {
    //   labelStat: "Nombre de Rdvs programmés",
    //   value: statsObject.nbrRdvsProgrammes.toString(),
    // },
    // {
    //   labelStat: "Nombre de Rdvs honorés",
    //   value: statsObject.nbrRdvsHonores.toString(),
    // },
    {
      labelStat: "Stats calculées le",
      value: statsObject.date.toLocaleString(),
    },
    //		{labelStat:"CA cumulé", value:caCumul},
    //		{labelStat:"Temps cumulé", value:dureeCumul},
    //		{labelStat:"Taux horaire", value:tauxHoraire},
    //		{labelStat:"Patients avec des besoins", value:nbrPatientsBesoins},
    //		{labelStat:"Nombre de patients ayant reçu un devis", value:nbrPatientsDevis}
  ];
  return <StatsLines stats={stats} />;
};

export default StatsPanel;
