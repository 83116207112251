import GrilleHeure from "./GrilleHeure";
import DayRdvs, { DayRdvsProps } from "./DayRdvs";
import { formaters } from "vincent-utils";
import { DateTime } from "luxon";
import _ from "underts";
import { Transforms } from "cadendar-shared";
import styles from "./AgendaColumn.module.css";
import classNames from "classnames";

type OnMouseEnterType = Parameters<DayRdvsProps["onMouseEnter"]>;

export type AgendaColumnProps = {
  columnNumber: number;
  débutHeureAffichage: number;
  finHeureAffichage: number;
  duréeMiniRdv: number;
} & Omit<DayRdvsProps, "heightMultiplier"> & {
    onMouseEnter: (
      event: OnMouseEnterType[0],
      rdv: OnMouseEnterType[1],
      columnNumber?: number
    ) => void;
  };

const AgendaColumn = (props: AgendaColumnProps) => {
  const { onCaseClick, onCloseCrossClick, onConfirmClick } = props;
  const { onMouseEnter, onMouseLeave } = props;
  const { rdvs } = props;
  const { columnNumber } = props;
  const { now } = props;
  const { débutHeureAffichage, finHeureAffichage, duréeMiniRdv } = props;
  const date = _.first(rdvs)?.date;
  const isToday =
    formaters.filterDateTimeForPureDate(now)!.getTime() === date?.getTime();
  const todayStr = date
    ? formaters.capitaliseEveryFirstLetter(
        DateTime.fromJSDate(date).toLocaleString({
          weekday: "long",
          day: "numeric",
          month: "long",
        })
      )
    : "";
  const heightMultiplier = Transforms.rdvs.getHeightMultiplier(duréeMiniRdv);
  const height = duréeMiniRdv * heightMultiplier;
  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.persist();
    onMouseEnter(event, null, columnNumber);
  };
  const handleMouseLeave = (event: React.MouseEvent) => {
    if (
      event.relatedTarget &&
      "id" in event.relatedTarget &&
      (event.relatedTarget as HTMLElement).id === "ColumnInfoPopup"
    ) {
      return;
    }
    onMouseLeave(event);
  };

  const heightMulClass =
    duréeMiniRdv < 15 ? styles.heightMulBig : styles.heightMultSmall;

  return (
    <div
      className={classNames(styles.agendacolumn, heightMulClass)}
      data-testid="AgendaColumn"
      id="AgendaColumnId"
    >
      {isToday ? (
        <div
          className={classNames([styles.today, "headerCol"])}
          data-testid="todayCol"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {todayStr}
        </div>
      ) : (
        <div
          className={classNames([styles.otherday, "headerCol"])}
          data-testid="otherdayCol"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {todayStr}
        </div>
      )}
      <div className={classNames([styles.spacer, "spacerCol"])}>
        <div className={styles.gridHourCol}>
          <GrilleHeure
            start={débutHeureAffichage}
            end={finHeureAffichage}
            step={duréeMiniRdv}
            height={height + "em"}
          />
        </div>
        <div className={classNames(styles.noPadding, styles.aptCol80)}>
          <DayRdvs
            rdvs={rdvs}
            onCaseClick={onCaseClick}
            onCloseCrossClick={onCloseCrossClick}
            onConfirmClick={onConfirmClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            now={now}
            heightMultiplier={heightMultiplier}
          />
        </div>
      </div>
    </div>
  );
};

export default AgendaColumn;
