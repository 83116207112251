import { CadendarConsts } from "cadendar-shared";
import Delete02Icon from "../../main/icons/delete-02-stroke-rounded.tsx";
import Flag from "./Flag.tsx";
import styles from "./PatientFlagsChooser.module.css";

interface PatientFlagsChooserProps {
  onFlagClicked: (flag: CadendarConsts.PatientFlags) => void;
  onResetClicked: () => void;
  patient_flags: string | null;
  flags: readonly CadendarConsts.PatientFlags[];
}

const PatientFlagsChooser = (props: PatientFlagsChooserProps) => {
  const { onFlagClicked, onResetClicked, patient_flags, flags } = props;
  return (
    <div>
      {flags.map((fl) => (
        <Flag
          key={fl}
          flag={fl}
          onFlagClicked={() => onFlagClicked(fl)}
          light={patient_flags !== fl}
        />
      ))}
      <div onClick={onResetClicked} className={styles.cursorStyle}>
        <Delete02Icon />
        reset
      </div>
    </div>
  );
};

export default PatientFlagsChooser;
