// import { useSuperQuery } from "../../../utils/useSuperQuery";
// import { fetchRdvsByDateWindow } from "../data/api";
// import moment from "moment";
import { DateTime } from "luxon";
import useOwner from "../../users/hooks/useOwner";
import { trpc } from "../../main/components/MainContainer";

export function useThreeWeeksRdvs(selectedWeek: Date) {
  const startDate = DateTime.fromJSDate(selectedWeek)
    .minus({ weeks: 1 })
    .toJSDate();
  const endDate = DateTime.fromJSDate(selectedWeek)
    .plus({ weeks: 2 })
    .toJSDate();
  const owner = useOwner();
  const { data, isFetched } = trpc.rdv.getByDateWindow.useQuery(
    { startDate, endDate },
    { enabled: !!selectedWeek && !!owner, keepPreviousData: true }
  );
  return [data || [], isFetched] as const;
  // return useSuperQuery(
  //   ["rdvs", selectedWeek.getTime()],
  //   fetchRdvsByDateWindow,
  //   [],
  //   { enabled: !!selectedWeek, keepPreviousData: true },
  //   moment(selectedWeek)
  //     .subtract(1, "week")
  //     .toDate(),
  //   moment(selectedWeek)
  //     .add(2, "week")
  //     .toDate()
  // );
}
