import MultiplicationSignIcon from "../../main/icons/multiplication-sign-stroke-rounded.tsx";
import React from "react";
import styles from "./RdvCaseIcons.module.css";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import Tick02Icon from "../../main/icons/tick-02-stroke-rounded.tsx";
import { FaCheck } from "react-icons/fa";
import { RxCross1, RxCross2 } from "react-icons/rx";
import classNames from "classnames";

interface RdvCaseIconsProps {
  showCross: boolean;
  handleCrossClick: () => void;
  handleConfirmClick: () => void;
  confirmationIconStyle:
    | "empty"
    | "programmed"
    | "asked"
    | "confirmed"
    | "notConfirmed";
}

const RdvCaseIcons = (props: RdvCaseIconsProps) => {
  const handleCrossClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.handleCrossClick();
  };
  const handleConfirmClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.handleConfirmClick();
  };
  return (
    <div className={styles.spacer}>
      {props.showCross ? (
        <div
          className={styles.crossIcon}
          data-testid="RdvCross"
          onClick={handleCrossClick}
        >
          <RxCross2 color="inherit" size="1.4em" strokeWidth={1} />
        </div>
      ) : null}
      {props.confirmationIconStyle === "confirmed" ? (
        <div className={classNames(styles.marginRight, styles.marginTop)}>
          <FaCheck color="green" size="1.2em" />
        </div>
      ) : null}
      {props.confirmationIconStyle === "programmed" ? (
        <div className={classNames(styles.marginRight, styles.marginTop)}>
          <BsFillQuestionCircleFill color="black" size="1.2em" />
        </div>
      ) : null}
      {props.confirmationIconStyle === "asked" ? (
        <div className="" onClick={handleConfirmClick}>
          <BsFillQuestionCircleFill color="yellow" size="1.2em" />
        </div>
      ) : null}
      {props.confirmationIconStyle === "notConfirmed" ? (
        <div
          className={classNames(styles.marginRight, styles.marginTop)}
          onClick={handleConfirmClick}
        >
          <BsFillQuestionCircleFill color="red" size="1.2em" />
        </div>
      ) : null}
    </div>
  );
};

export default RdvCaseIcons;
