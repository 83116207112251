import { useIsFetching } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { trpc } from "../modules/main/components/MainContainer";
import styles from "./RdvsLoading.module.css";

const RdvsLoading = () => {
  const rdvFetchKeys = getQueryKey(trpc.rdv);
  const isFetching = useIsFetching(rdvFetchKeys);
  return (
    <>
      {isFetching ? (
        <div className={styles.loadingStyle} id="redloading">
          Chargement...
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default RdvsLoading;
