import Message, { MessageProps } from "./Message";
import styles from "./Thread.module.css";
import classNames from "classnames";

export interface ThreadProps {
  messages: Omit<MessageProps, "onMessageClick">[];
  isDoctor: boolean;
  onArchiveClick: (threadId: string) => void;
  isResponding: boolean;
  threadId: string;
  onMessageClick: (threadId: string, idx: number) => void;
  onReplyClick: (threadId: string) => void;
  replyText: string;
  onReplySend: () => void;
  onReplyTextChange: (text: string) => void;
  onReplyKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  isEmergencyReply: boolean;
  onEmergencyClick: () => void;
  replyInputRef: React.Ref<HTMLTextAreaElement>;
}
const Thread = (props: ThreadProps) => {
  const {
    messages,
    isDoctor,
    onArchiveClick,
    isResponding,
    threadId,
    onMessageClick,
    onReplyClick,
    replyText,
  } = props;
  const {
    onReplySend,
    onReplyTextChange,
    onReplyKeyDown,
    isEmergencyReply,
    onEmergencyClick,
  } = props;
  const responseComp = (
    <div>
      <textarea
        id="textarea_reply_message"
        className={styles.textarea_reply_message}
        value={replyText}
        onChange={(event) => onReplyTextChange(event.target.value)}
        onKeyDown={onReplyKeyDown}
        ref={props.replyInputRef}
      />
      <div>
        <span
          id="send_reply_link"
          className="send_reply_link"
          onClick={onReplySend}
        >
          Envoyer{" "}
        </span>
        <label>
          <input
            type="checkbox"
            id="urgentMessageCheck"
            checked={isEmergencyReply}
            onChange={onEmergencyClick}
          />
          Urgent
        </label>
      </div>
    </div>
  );
  const hasMessageNotSeen = messages.some((message) => message.notSeenByMe);
  return (
    <div className="">
      <div
        className={classNames(styles.message, {
          [styles.message_not_seen]: hasMessageNotSeen,
        })}
        id={threadId}
      >
        {isDoctor ? (
          <div
            className={styles.archive_link}
            onClick={onArchiveClick.bind(null, threadId)}
          >
            Archiver
          </div>
        ) : null}
        {messages.map((message, idx) => (
          <Message
            {...{
              ...message,
              onMessageClick: onMessageClick.bind(null, threadId, idx),
            }}
            key={threadId + "_" + idx}
          />
        ))}

        {!isResponding ? (
          <span
            className={styles.reply_link}
            onClick={onReplyClick.bind(null, threadId)}
          >
            Répondre
          </span>
        ) : null}

        {isResponding ? responseComp : null}
      </div>
    </div>
  );
};

export default Thread;
