import {formaters} from "vincent-utils";
const getFakePatient = function () {
  return { nom: generateNom(), prenom: generatePrenom(), ddn: generateDDN() };
};
const generateNom = function () {
  "use strict";
  const syllabes = [
    "bro",
    "bra",
    "bert",
    "bou",
    "lon",
    "lou",
    "ben",
    "to",
    "ta",
    "tu",
    "lin",
    "per",
    "mer",
    "don",
    "dou",
    "dan",
    "ro",
    "po",
    "ma",
    "lo",
    "to",
    "bile",
    "bien",
    "prou",
    "mou",
    "lou",
  ];
  const nbrSyllabes = Math.floor(Math.random() * 4 + 1);
  let nom = "";
  for (let j = 0; j < nbrSyllabes; j++) {
    nom = nom + syllabes[Math.floor(Math.random() * 25)];
  }
  return formaters.capitaliseFirstLetter(nom);
};
const generatePrenom = function () {
  "use strict";
  const prenoms = [
    "Patrick",
    "John",
    "Cunégonde",
    "Tryphon",
    "Pascaline",
    "Rudolphe",
    "Anakin",
    "Goudroun",
    "Olaf",
    "Ulf",
    "Léon",
    "Pivoine",
    "Otto",
    "Maya",
    "Isidore",
    "Casimir",
    "Lopart",
    "Rudyard",
    "Balthazar",
    "Adémard",
    "Arthémond",
    "Bohémond",
    "Euripide",
    "Orantie",
    "Perséphone",
    "Pétronille",
    "Philomène",
    "Théobaldine",
    "Séraphine",
    "Roséliande",
    "Sébastienne",
    "Nanthilde",
    "Mnémosyne",
    "Nanthilde",
    "Hildegarde",
    "Gretchen",
    "Eudoxe",
    "Emérance",
    "Calypso",
    "Calixtine",
    "Brunehilde",
    "Blancheflor",
    "Athénaïs",
    "Audéarde",
    "Amarante",
    "Scholastique",
    "Lancelot",
    "Gondebaud",
  ];
  const i = Math.floor(Math.random() * 21 + 1);
  return prenoms[i];
};
const generateDDN = function () {
  "use strict";
  const dd = Math.floor(Math.random() * 30 + 1);
  const mm = Math.floor(Math.random() * 12 + 1);
  const yyyy = Math.floor(Math.random() * 80 + 1930);
  return dd + "/" + mm + "/" + yyyy;
};
export default getFakePatient;
