import { Collections } from "cadendar-shared";
import _ from "underts";
import {formaters} from "vincent-utils";

const getDatesOfRdvsOfType = (typeNumber: number, rdvs: Collections.Rdv[]) => {
  return rdvs
    .filter(rdv => rdv.type === typeNumber)
    .map(rdv => rdv.date)
    .map(formaters.formatDateForDisplay);
};
const twoDays = 2 * 24 * 60 * 60 * 1000;
const isLateCanceled = (rdv: Collections.Rdv) =>
  !!rdv.canceledOn && rdv.date.getTime() - rdv.canceledOn.getTime() < twoDays;
const isOtherCanceled = (rdv: Collections.Rdv) =>
  rdv.type === 21 && !isLateCanceled(rdv);
const getDatesOfLapins = getDatesOfRdvsOfType.bind(null, 11);
const getDatesOfAnnTard1 = getDatesOfRdvsOfType.bind(null, 12);
const getDatesOfUrgences = getDatesOfRdvsOfType.bind(null, 2);
const getDatesOfManquesRetard = getDatesOfRdvsOfType.bind(null, 22);
const getDatesOfCanceledRdvs = (rdvs: Collections.Rdv[]) =>
  rdvs
    .filter(isOtherCanceled)
    .map(rdv => rdv.date)
    .map(formaters.formatDateForDisplay);
const getDatesOfAnnTard2 = (rdvs: Collections.Rdv[]) =>
  rdvs
    .filter(isLateCanceled)
    .map(rdv => rdv.date)
    .map(formaters.formatDateForDisplay);
const getDatesOfTotalAnnTard = (rdvs: Collections.Rdv[]) =>
  _.uniq(getDatesOfAnnTard1(rdvs).concat(getDatesOfAnnTard2(rdvs)));
export const getPatientStats = (patientRdvs: Collections.Rdv[]) => {
  const rdvs = patientRdvs;
  const lapinDates = getDatesOfLapins(rdvs);
  const manqueRetardDates = getDatesOfManquesRetard(rdvs);
  const annTardDates = getDatesOfTotalAnnTard(rdvs);
  const urgencesDates = getDatesOfUrgences(rdvs);
  const canceledDates = getDatesOfCanceledRdvs(rdvs);
  return {
    lapinDates,
    manqueRetardDates,
    annTardDates,
    urgencesDates,
    canceledDates
  };
};

export const getMultiplePatientsRdvsStats = (
  patientIds: string[],
  rdvs: Collections.Rdv[]
) => {
  return patientIds.reduce((result, _id) => {
    return {
      ...result,
      [_id]: getPatientStats(rdvs.filter(rdv => rdv.patient_id === _id))
    };
  }, {} as { [_id: string]: ReturnType<typeof getPatientStats> });
};
