import { formaters } from "vincent-utils";
import moment from "moment";
import { FilledRdvCase } from "cadendar-shared";
import Flag from "../../patients/components/Flag.tsx";

export interface RdvInfoPopupProps {
  top: number;
  left: number;
  rdv: FilledRdvCase;
}

const RdvInfoPopup = (props: RdvInfoPopupProps) => {
  const { top, left } = props;
  const { rdv } = props;
  const style = {
    position: "fixed" as const,
    top: top,
    left: left,
    zIndex: 10,
  };
  const { patient_ddn } = rdv;
  const ageObj = patient_ddn
    ? formaters.getAgeFromDDN(
        moment(patient_ddn, "DD/MM/YYYY").toDate(),
        new Date()
      )
    : { annees: 0, mois: 0 };
  const patientFlagsComps = rdv.patient_flags && (
    <div id={rdv.patient_flags + "div"} key={"flag" + rdv.patient_flags}>
      {rdv.patient_flags}
    </div>
  );
  return (
    <div id="rdvinfopopup" style={style}>
      <div className="patient_outline">
        {rdv.nom && rdv.nom.toUpperCase()}{" "}
        {formaters.capitaliseEveryFirstLetter(rdv.prenom || "")}
      </div>
      <div>
        {rdv.heure}h{formaters.convertZeroToDoubleZero(rdv.minute)}
      </div>
      <div>durée:{formaters.convertMinToHMinString(rdv.duree)}</div>
      <div>{rdv.objet}</div>
      {ageObj ? (
        <div>
          {ageObj.annees} ans {ageObj.mois} mois
        </div>
      ) : null}
      {rdv.adressedBy ? <div>Adressé par {rdv.adressedBy}</div> : null}

      {rdv.patient_flags ? <Flag flag={rdv.patient_flags} /> : null}
    </div>
  );
};

export default RdvInfoPopup;
