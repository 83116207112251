import React from "react";

// In a utility library:
function assertIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !("nodeType" in e)) {
    throw new Error(`Node expected`);
  }
}

const useOutsideClick = () => {
  React.useEffect(() => {
    const handleClick = () => {
      const event = new CustomEvent("globalClick");
      document.dispatchEvent(event);
    };

    document.addEventListener("click", handleClick, false);

    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  }, []);
};

export default useOutsideClick;
