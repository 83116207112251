import moment from "moment";

export const _areDateCompatibleWithConfirmation = (
  now: Date,
  rdv: { date: Date }
) => {
  //interdit les confirmations pour les rdvs de dans deux jours et moins et pour les rdvs dans le passé
  if (
    Math.ceil(
      moment(rdv.date).diff(
        moment(now)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0),
        "days",
        true
      )
    ) <= 2
  ) {
    return false;
  }
  //pas de confirmation pour les rdvs du samedi au lundi
  if (
    moment(now).day() === 6 &&
    moment(rdv.date).day() === 1 &&
    moment(rdv.date).diff(moment(now), "days", true) < 6
  ) {
    return false;
  }
  return true;
};
