import _ from "underts";
import { isValid } from "./validPatient.ts";
import { LocalNewPatient } from "../../famille/components/FamilleGridPanel.tsx";

const validatedKeys = ["nom", "prenom", "ddn", "portable", "email"] as const;

export type FamilleGridKeys = (typeof validatedKeys)[number];

type PatientKeys = keyof LocalNewPatient;

const patientValidationState = (patient: Omit<LocalNewPatient, "owner">) => {
  const keys = Object.keys(patient) as (keyof LocalNewPatient)[];
  const keysFiltered = keys.filter((key) =>
    _.includes<FamilleGridKeys, PatientKeys>(validatedKeys, key)
  ) as FamilleGridKeys[];

  return keysFiltered.reduce((result, key) => {
    return { ...result, [key]: isValid(key, patient[key]) };
  }, {} as { [K in FamilleGridKeys]: boolean });
};

export { patientValidationState };
