import CentralCompContainer from "../../centralComp/containers/CentralCompContainer.tsx";
import MiddleBoxes from "../../middleBoxes/components/MiddleBoxes.tsx";
import FamilleGridManager from "../../main/containers/FamilleGridManager.tsx";
import { useEffect, useState } from "react";
import RdvPopup from "../../rdvs/components/RdvPopup.tsx";
import MenuPopUp from "../../centralComp/components/MenuPopUp.tsx";
import SimpleWindow from "../../../components/SimpleWindow/SimpleWindow.tsx";
import { useSetAtom } from "jotai/index";
import {
  FamilleGridOpenState,
  LoggedUserAtom,
  useRdvPopupState,
} from "../../main/state/globalState.ts";
import poster from "../../../utils/poster.ts";
import useFicheAppel from "../../ficheAppel/hooks/useFicheAppel.tsx";
import FichePageLoader from "../../ficheAppel/components/FichePageLoader.tsx";
import { useAtom } from "jotai";
import ContraintesPanel from "../../ficheAppel/components/ContraintesPanel.tsx";
import useOutsideClick from "../../main/hooks/useOutsideClick.ts";
import OnlineUsers from "../../users/components/OnlineUsers.tsx";
import useUser from "../../users/hooks/useUser.ts";

//TODO : à améliorer
const prefix = "cadendarRest";

const AgendaPage = () => {
  const [showFicheAppelPopup, setShowFicheAppelPopup] = useState(false);
  const [showMenuPopUp, setShowMenuPopUp] = useState({
    show: false,
    height: 0,
  });
  const [showFamilleGrid, setShowFamilleGrid] = useAtom(FamilleGridOpenState);
  const [isShowingContraintes, setIsShowingContraintes] = useState(false);
  const { isOpen: isRdvPopupOpen } = useRdvPopupState();
  // const menuPopUpRef = useOutsideClick<HTMLDivElement>(() => {
  //   setShowMenuPopUp((state) => ({ ...state, show: false }));
  // });
  const setUser = useSetAtom(LoggedUserAtom);
  const handleDisconnect = () => {
    poster(`${prefix}/logout`, {}).then((res) => {
      if (res) {
        setUser(null);
      }
    });
  };
  const { onResetFicheAppel } = useFicheAppel();
  const handleCloseFicheAppel = () => {
    setShowFicheAppelPopup(false);
    onResetFicheAppel();
  };
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShowMenuPopUp((state) => ({ ...state, show: false }));
        setShowFicheAppelPopup(false);
      }
    };
    document.addEventListener("globalClick", () => {
      console.log("globalClick in AgendaPage");
      setShowMenuPopUp((state) => ({ ...state, show: false }));
      // setShowFicheAppelPopup(false);
    });
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("globalClick", () => {});
    };
  }, []);

  const handleMenuClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    setShowMenuPopUp((state) => ({
      show: !state.show,
      height: target.scrollHeight + 2,
    }));
  };

  useOutsideClick();

  const user = useUser();
  const isShowingOnlineUsers = user?.tag === "doctor";

  return (
    <div>
      <CentralCompContainer />
      <MiddleBoxes
        onOpenNewFicheAppel={() => setShowFicheAppelPopup(true)}
        onOpenFamilleGrid={() => setShowFamilleGrid(true)}
        isShowingFicheAppel={showFicheAppelPopup}
        isRdvPopupOpen={isRdvPopupOpen}
      />
      {isShowingOnlineUsers ? <OnlineUsers /> : null}
      <FamilleGridManager />
      <RdvPopup
        onRdvSave={handleCloseFicheAppel}
        onOpenFicheAppel={() => setShowFicheAppelPopup(true)}
      />
      {showMenuPopUp.show ? (
        <MenuPopUp top={showMenuPopUp.height} onDisconnect={handleDisconnect} />
      ) : null}
      {showFicheAppelPopup && !showFamilleGrid ? (
        <SimpleWindow
          isVisible={true}
          title={"Fiche d'appel"}
          initialX={20}
          initialY={10}
          onClose={handleCloseFicheAppel}
        >
          <div data-testid="FichePageLoader">
            <FichePageLoader
              onAptFilterClick={() => setIsShowingContraintes(true)}
            />
          </div>
        </SimpleWindow>
      ) : null}
      {isShowingContraintes ? (
        <SimpleWindow
          isVisible={true}
          title="Contraintes du patient"
          initialX={document.documentElement.clientWidth / 2 - 200}
          initialY={10}
          onClose={() => setIsShowingContraintes(false)}
        >
          <ContraintesPanel />
        </SimpleWindow>
      ) : (
        ""
      )}
    </div>
  );
};
export default AgendaPage;
