import styles from "./IconButton.module.css";
import classNames from "classnames";

interface IconButtonProps {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
  id?: string;
  tooltip?: string;
  title?: string;
  position?: "left" | "right";
  className?: string;
  ["data-testid"]?: string;
  size?: "small" | "normal";
}

const IconButton = (props: IconButtonProps) => {
  return (
    <div className="">
      <button
        className={classNames(
          styles.button,
          styles.tooltip,
          {
            [styles.flexButton]: "title" in props,
            [styles.left]: props.position === "left" || !props.position,
            [styles.right]: props.position === "right",
            [styles.smallHeight]: props.size === "small",
          },
          props.className
        )}
        onClick={props.onClick}
        id={props.id}
        data-testid={props["data-testid"]}
      >
        {props.title}
        {props.children}
        {props.tooltip && (
          <span className={styles.tooltipText}>{props.tooltip}</span>
        )}
      </button>
    </div>
  );
};

export default IconButton;
