import { useEffect, useState } from "react";
import { formaters } from "vincent-utils";

export default function useToday() {
  const [today, setToday] = useState(
    formaters.filterDateTimeForPureDate(new Date())
  );
  useEffect(() => {
    const now = new Date();
    if (now.getDate() !== now.getDate()) {
      setToday(formaters.filterDateTimeForPureDate(now));
    }
  }, [today.getDate()]);
  return today;
}
