import { useEffect, useState } from "react";
import MonthChooser from "./MonthChooser";
import MiniCalendarTable from "./MiniCalendarTable";
import moment from "moment";
import { formaters } from "vincent-utils";
import styles from "./MiniCalendar.module.css";
import classNames from "classnames";

interface MiniCalendarProps {
  weekSelected: Date;
  today: Date;
  onSelectDay: (day: Date) => void;
}

const getFirstDayOfMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), 1);

const listeMois = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const MiniCalendar = (props: MiniCalendarProps) => {
  const { today, weekSelected, onSelectDay } = props;
  const [firstDayOfMonthSelected, setFirstDayOfMonth] = useState(
    getFirstDayOfMonth(weekSelected)
  );

  useEffect(
    () => weekSelected && setFirstDayOfMonth(getFirstDayOfMonth(weekSelected)),
    [weekSelected]
  );

  const onPrevMonth = () =>
    setFirstDayOfMonth((date) => moment(date).subtract(1, "month").toDate());
  const onNextMonth = () =>
    setFirstDayOfMonth((date) => moment(date).add(1, "month").toDate());

  const month = firstDayOfMonthSelected.getMonth();
  const year = firstDayOfMonthSelected.getFullYear();
  const monthYearString = listeMois[month] + " " + year.toString();

  return (
    <div
      id="minicalendar"
      className={classNames([styles.miniCalendar, styles.userSelectNone])}
    >
      <MonthChooser
        monthYearString={monthYearString}
        onPrevMonth={onPrevMonth}
        onNextMonth={onNextMonth}
      />
      <MiniCalendarTable
        weekSelected={
          weekSelected || formaters.getDateDuLundiFromDateTime(new Date())
        }
        today={today}
        month={month}
        year={year}
        onSelectDay={onSelectDay}
      />
    </div>
  );
};

export default MiniCalendar;
