import { trpc } from "../../main/components/MainContainer.tsx";
import _ from "underts";

const useAllHoraires = () => {
  const { data: horaires } = trpc.horaire.getAll.useQuery();

  return horaires ? _.sortBy(horaires, "date") : [];
};

export default useAllHoraires;
