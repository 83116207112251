import _ from "underts";
import {formaters} from "vincent-utils";
import { HMObj } from "cadendar-shared";
import { Collections } from "cadendar-shared";
const getHms = (hmStart: HMObj, hmEnd: HMObj, step: number) => {
  const minutesStart = formaters.getHeureEnMinutesFromHeureMinuteObject(
    hmStart
  );
  const minutesEnd = formaters.getHeureEnMinutesFromHeureMinuteObject(hmEnd);
  let minutes = [];
  for (let i = minutesStart; i <= minutesEnd; i += step) {
    minutes.push(i);
  }
  return _.compact(
    minutes.map(formaters.getHeureMinuteObjectFromHeureEnMinutes)
  );
};

const getExtremesFromDayPlages = (dayPlages: Collections.Plage[]) => {
  if (!dayPlages || dayPlages.length === 0) {
    return;
  }
  return { start: _.first(dayPlages)!.start, end: _.last(dayPlages)!.end };
};

export { getHms, getExtremesFromDayPlages };
