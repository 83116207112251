import Button from "../../main/components/Button.tsx";
import styles from "./EditRdvButtonsCommit.module.css";
import Delete02Icon from "../../main/icons/delete-02-stroke-rounded.tsx";
import { useState } from "react";
import IconButton from "../../main/components/IconButton.tsx";

interface EditRdvButtonsCommitProps {
  onSaveRdv: () => void;
  onCancelRdvEdit: () => void;
  onDeleteWithoutLogging: () => void;
  onCancelWithLogging: () => void;
  onPostPone: () => void;
}

const EditRdvButtonsCommit = (props: EditRdvButtonsCommitProps) => {
  const [showDeleteChoice, setShowDeleteChoice] = useState(false);
  const [flagPopupTop, setFlagPopupTop] = useState(0);
  const [flagPopupLeft, setFlagPopupLeft] = useState(0);
  const toggleDeleteChoice = (event: React.MouseEvent) => {
    event.stopPropagation();
    const target = event.currentTarget;
    setShowDeleteChoice(!showDeleteChoice);
    setFlagPopupLeft(target.getBoundingClientRect().right + 10);
    setFlagPopupTop(target.getBoundingClientRect().top);
  };
  return (
    <div className={styles.spacer}>
      <Button title="Enregistrer" onClick={props.onSaveRdv} style="main" />
      <Button
        title="Annuler modifications"
        onClick={props.onCancelRdvEdit}
        style="secondary"
      />
      <div
        className={""}
        onClick={toggleDeleteChoice}
        data-testid="cancelAptButton"
      >
        <IconButton
          onClick={toggleDeleteChoice}
          tooltip="Annuler Rdv"
          className={styles.colorButton}
        >
          <Delete02Icon color="white" />
        </IconButton>
      </div>
      {showDeleteChoice && (
        <div
          className={styles.deletePopup}
          style={{ top: flagPopupTop, left: flagPopupLeft }}
        >
          <Button
            title="Suppression simple"
            onClick={props.onDeleteWithoutLogging}
            data-testid="deleteWithoutLogging"
          />
          <Button
            title="Annulation"
            onClick={props.onCancelWithLogging}
            data-testid="cancelWithLogging"
          />
          <Button
            title="Report"
            onClick={props.onPostPone}
            data-testid="postPone"
          />
        </div>
      )}
    </div>
  );
};
export default EditRdvButtonsCommit;
