import { formaters } from "vincent-utils";
import styles from "./DuplicateMerger.module.css";
import PatientEtatCivilDisplay from "./PatientEtatCivilDisplay.tsx";
import PatientLookup from "./PatientLookup.tsx";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { trpc } from "../../main/components/MainContainer.tsx";
import useSelectedPatients from "../hooks/useSelectedPatients.ts";
import _ from "underts";
import ListRdvsPatient2 from "../../rdvs/components/ListRdvsPatient2.tsx";

const DuplicateMerger = () => {
  const [selectedCandidateId, setSelectedCandidateId] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedPatients = useSelectedPatients();
  const selectedPatient = _.first(selectedPatients);
  const ddn = selectedPatient && selectedPatient.ddn;
  const { data: candidates } = trpc.patient.findByDDN.useQuery(
    { ddnLookup: ddn as string },
    { enabled: !!ddn }
  );
  const utils = trpc.useContext();
  const mergeMutation = trpc.patient.mergeDuplicates.useMutation({
    onSuccess: () => {
      setIsSubmitting(false);
      utils.patient.invalidate();
      utils.rdv.invalidate();
    },
    onError: () => {
      setIsSubmitting(false);
    },
  });
  const handleMerge = () => {
    if (selectedPatient && selectedCandidate) {
      setIsSubmitting(true);
      console.log("merging", selectedPatient._id, selectedCandidate._id);
      mergeMutation.mutate([selectedPatient._id, selectedCandidate._id]);
    }
  };
  const candidatesWithoutSelected =
    (candidates && candidates.filter((c) => c._id !== selectedPatient?._id)) ||
    [];
  const firstCandidate = _.first(candidatesWithoutSelected);
  useEffect(() => {
    setSelectedCandidateId(firstCandidate?._id || "");
  }, [firstCandidate?._id]);
  const selectedCandidate = candidates?.find(
    (c) => c._id === selectedCandidateId
  );

  return (
    <div className={styles.main}>
      <div className={styles.headerTitle}>Fusionner doublons</div>
      {selectedPatient && selectedCandidate && !isSubmitting ? (
        <div className={styles.buttonSpacer}>
          <div className={styles.fusionButton} onClick={handleMerge}>
            <div className={styles.icon}>
              <svg
                id="Layer_1"
                // style="enable-background:new 0 0 25 19;"
                version="1.1"
                viewBox="0 0 25 20"
                xmlSpace="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M12.5,19c5.078,0,9.213-3.986,9.475-9H25V9h-3.025c-0.262-5.014-4.396-9-9.475-9  C7.423,0,3.287,3.986,3.025,9H0v1h3.025C3.287,15.014,7.423,19,12.5,19z M12.5,1.002c4.518,0,8.214,3.545,8.475,7.998h-5.968  l3.073-2.857L17.398,5.41L13,9.5l4.398,4.09l0.682-0.732L15.007,10h5.968c-0.261,4.453-3.957,7.998-8.475,7.998  c-4.518,0-8.214-3.545-8.475-7.998h5.969L6.92,12.857l0.682,0.732L12,9.5L7.602,5.41L6.92,6.143L9.994,9H4.025  C4.286,4.547,7.982,1.002,12.5,1.002z"
                  id="unique-left-right-in-7"
                />
              </svg>
            </div>
            Fusionner les deux patients
          </div>
        </div>
      ) : null}
      <div className={styles.gridWrapper}>
        <div className={styles.gridCase}>
          <div className={styles.caseTitle}>
            Choisir premier patient à fusionner
          </div>
          <PatientLookup />
        </div>
        <div className={styles.gridCase}>
          {candidates ? (
            <>
              <div className={styles.caseTitle}>
                Choisir doublon à fusionner
              </div>
              <div className={styles.candidates}>
                {candidatesWithoutSelected.map((candidate) => (
                  <div
                    key={candidate._id}
                    className={classNames({
                      [styles.candidate]: true,
                      [styles.selected]: selectedCandidateId === candidate._id,
                    })}
                    onClick={() => setSelectedCandidateId(candidate._id)}
                  >
                    <div className={styles.name}>
                      {formaters.capitaliseEveryFirstLetter(candidate.nom)}{" "}
                    </div>
                    <div className={styles.firstName}>
                      {formaters.capitaliseEveryFirstLetter(candidate.prenom)}{" "}
                    </div>
                    {formaters.formatDDNForStoring(candidate.ddn)}
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </div>
        {selectedPatient ? (
          <div className={styles.gridCase}>
            <div className={styles.caseTitle}>Patient sélectionné</div>

            <>
              <PatientEtatCivilDisplay patient={selectedPatient} />
              <ListRdvsPatient2 selectedPatientId={selectedPatient._id} />
            </>
          </div>
        ) : (
          ""
        )}
        {selectedCandidate ? (
          <div className={styles.gridCase}>
            <div className={styles.caseTitle}>Doublon sélectionné</div>

            <>
              <PatientEtatCivilDisplay patient={selectedCandidate} />
              <ListRdvsPatient2 selectedPatientId={selectedCandidate._id} />
            </>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default DuplicateMerger;
