import { useState, useEffect } from "react";

interface InputSettingProps {
  label: string;
  name: string;
  initialValue: string;
  onValueSubmitted: (value: string) => void;
  validate: (value: string) => false | string;
}

const InputSetting = (props: InputSettingProps) => {
  //label est le label de l'input, name est le nom pour générer les id
  const { label, name } = props;

  const { initialValue, onValueSubmitted } = props;

  //fonction qui renvoie un message d'erreur quand la valeur n'est pas valide
  const { validate } = props;

  //hooks
  const [value, onChange] = useState(initialValue);
  const [isEditing, onEdit] = useState(false);

  //on s'assure que quand le règlage change par les props il est bien mis à jour
  useEffect(() => {
    onChange(initialValue);
  }, [initialValue]);

  const inputName = "InputSetting" + name;
  const handleSubmit = () => {
    onEdit(false);
    onValueSubmitted(value);
  };
  const handleCancel = () => {
    onEdit(false);
    onChange(initialValue);
  };
  const isValid = () => !validate(value);

  return (
    <div className="form-inline">
      <div
        className={
          "form-group " +
          (isEditing ? (isValid() ? "has-success" : "has-error") : "")
        }
      >
        <label htmlFor={inputName}>{label}</label>
        <input
          type="text"
          className="form-control"
          id={inputName}
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
          disabled={!isEditing}
        />

        {isEditing ? (
          <span>
            <button
              className="btn btn-default"
              id={inputName + "SaveButton"}
              type="submit"
              onClick={handleSubmit}
              disabled={!isValid()}
            >
              Enregistrer
            </button>
            <button
              className="btn btn-default"
              id={inputName + "CancelButton"}
              type="submit"
              onClick={handleCancel}
            >
              Annuler
            </button>
          </span>
        ) : (
          <button
            className="btn btn-default"
            id={inputName + "EditButton"}
            type="submit"
            onClick={() => onEdit(true)}
          >
            Editer
          </button>
        )}
        <span className="help-block">
          {validate(value) ? validate(value) : ""}
        </span>
      </div>
    </div>
  );
};

export default InputSetting;
