import elapsedTimeInWords from "./elapsedTimeInWords";
import _ from "underts";
import { Collections } from "cadendar-shared";

const formatDateForDisplay = (date: Date, now: Date) => {
  return elapsedTimeInWords((now.getTime() - date.getTime()) / 1000);
};

const getSeenByNames = (
  msg: Collections.Message["messages"][number],
  allUsers: Collections.User[],
  currentUserId: string
) => {
  if (!msg.seenBy) {
    return [];
  }
  const seenIdFiltered = msg.seenBy.filter((id) => id !== currentUserId);
  const usersFiltered = allUsers.filter((user) =>
    seenIdFiltered.includes(user._id)
  );
  return _.compact(
    usersFiltered.map((u) => ("messagename" in u ? u.messagename : undefined))
  );
};

const isNotSeenByMe = (
  msg: Collections.Message["messages"][number],
  currentUserId: string
) => {
  if (msg.user_id === currentUserId) {
    return false;
  }
  if (!msg.seenBy || !msg.seenBy.includes(currentUserId)) {
    return true;
  }
  return false;
};

const getPosterName = (
  msg: Collections.Message["messages"][number],
  allUsers: Collections.User[]
) => {
  const poster = allUsers.find((user) => user._id === msg.user_id);
  return (poster && poster.messagename) || "";
};

const formatMessageForDisplay = (
  msg: Collections.Message["messages"][number],
  now: Date,
  allUsers: Collections.User[],
  currentUserId: string
) => {
  return {
    ...msg,
    notSeenByMe: isNotSeenByMe(msg, currentUserId),
    posterName: getPosterName(msg, allUsers),
    seenByNames: getSeenByNames(msg, allUsers, currentUserId),
    displayAge: formatDateForDisplay(msg.date, now),
  };
};

export default formatMessageForDisplay;
