import Thread from "./Thread";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import useUser from "../../users/hooks/useUser";
import { Transforms } from "cadendar-shared";
import formatThreadsForDisplay from "../filterTransform/formatThreadsForDisplay";
import useAllUsers from "../../users/hooks/useAllUsers";
import useOwner from "../../users/hooks/useOwner";
import { Collections } from "cadendar-shared";
import { trpc } from "../../main/components/MainContainer";
import beep from "../../../utils/beep.ts";
import styles from "./MessagesMainComp.module.css";
import CheckBox from "../../main/CheckBox.tsx";
import Button from "../../main/components/Button.tsx";

const threadHasMsgNotSeen = (
  thread: Collections.Message,
  user: Collections.User
) =>
  thread.messages.some(
    (obj) =>
      obj.user_id !== user._id &&
      (!obj.seenBy || !obj.seenBy!.includes(user._id))
  );

const threadHasEmergencyMsgNotSeen = (
  thread: Collections.Message,
  user: Collections.User
) =>
  thread.messages.some(
    (obj) =>
      obj.user_id !== user._id &&
      (!obj.seenBy || !obj.seenBy.includes(user._id)) &&
      obj.emergency
  );

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

const MessagesMainComp = () => {
  const [respondingThreadId, setRespondingThreadId] = React.useState<
    string | null
  >(null);
  const [replyText, setReplyText] = useState<string>("");
  const [newThreadText, setNewThreadText] = useState("");
  const [isEmergencyReply, setIsEmergencyReply] = useState(false);
  const [isNewThreadEmergency, setIsNewThreadEmergency] = useState(false);
  const [enforcedHeight, setEnforcedHeight] = useState<number | null>(null);
  const user = useUser();
  const owner = useOwner();
  const isDoctor = user ? user.tag === "doctor" : false;

  const trpcutils = trpc.useContext();
  trpc.subscriptions.onChange.useSubscription(
    { owner: owner!, userId: user!._id! },
    {
      enabled: !!owner && !!user?._id,
      onData: (data) => {
        if (!data) return;
        if (typeof data !== "object") {
          return;
        }

        if ("collection" in data && data.collection === "messages") {
          trpcutils.message.invalidate();
        }

        if ("collection" in data && data.collection === "rdvs") {
          trpcutils.rdv.invalidate();
        }
      },
    }
  );
  const { data: threads } = trpc.message.getMessages.useQuery(undefined, {
    enabled: !!owner,
  });

  const hasUnseenMessages =
    threads && user && threads.some((t) => threadHasMsgNotSeen(t, user));
  const hasUnseenEmergencies =
    threads &&
    user &&
    threads.some((t) => threadHasEmergencyMsgNotSeen(t, user));

  useEffect(() => {
    if (hasUnseenEmergencies) {
      beep.doublePlay();
    }
    if (hasUnseenMessages && !hasUnseenEmergencies) {
      beep.play();
    }
  }, [hasUnseenEmergencies, hasUnseenMessages]);

  useInterval(
    () => beep.play,
    hasUnseenMessages && !hasUnseenEmergencies ? 15 * 60 * 1000 : null
  );
  useInterval(
    () => beep.doublePlay(),
    hasUnseenEmergencies ? 5 * 60 * 1000 : null
  );
  // useEffect(() => {
  //   beep.play();
  // }, []);
  // useInterval(
  //   () => {
  //     beep.play();
  //   },
  //   threads && user && threads.some((t) => threadHasMsgNotSeen(t, user))
  //     ? 15 * 60 * 1000
  //     : null
  // );
  // useInterval(
  //   () => {
  //     beep.doublePlay();
  //   },
  //   threads &&
  //     user &&
  //     threads.some((t) => threadHasEmergencyMsgNotSeen(t, user))
  //     ? 5 * 60 * 1000
  //     : null
  // );
  const allUsers = useAllUsers();
  const convertedThreads =
    threads && user
      ? formatThreadsForDisplay(threads, new Date(), allUsers, user._id)
      : [];
  const sortedThreads = convertedThreads.sort((a, b) => {
    return a.lastmessagedate.getTime() > b.lastmessagedate.getTime() ? -1 : 1;
  });

  const archiveThreadMutation = trpc.message.archiveThread.useMutation({
    onMutate: (threadId) => {
      trpcutils.message.getMessages.setData(undefined, (state) => {
        return state!.filter((thread) => thread._id !== threadId);
      });
    },
    onSettled: () => {
      trpcutils.message.invalidate();
    },
  });

  const onClearNewThread = () => {
    setNewThreadText("");
    setIsNewThreadEmergency(false);
  };
  const markMsgSeenMutation = trpc.message.markMessageSeen.useMutation({
    onMutate: ({ threadId, msgNumber }) => {
      trpcutils.message.getMessages.setData(undefined, (state) =>
        state!.map((thread) =>
          thread._id === threadId
            ? Transforms.messages.markSeen(thread, msgNumber, user!._id)
            : thread
        )
      );
    },
    onSuccess: () => {
      trpcutils.message.invalidate();
    },
    onError: () => {
      trpcutils.message.invalidate();
    },
  });

  const onMarkMsgSeen = (threadId: string, msgNumber: number) => {
    markMsgSeenMutation.mutate({ threadId, msgNumber });
  };

  const markThreadSeenMutation = trpc.message.markThreadSeen.useMutation({
    onMutate: (threadId: string) => {
      trpcutils.message.getMessages.setData(undefined, (state) =>
        state!.map((thread) =>
          thread._id === threadId
            ? Transforms.messages.markThreadSeen(thread, user!._id)
            : thread
        )
      );
    },
    onSuccess: () => {
      trpcutils.message.invalidate();
    },
    onError: () => {
      trpcutils.message.invalidate();
    },
  });

  const replyInputRef = useRef<HTMLTextAreaElement>(null);
  const onResetReply = () => {
    setReplyText("");
    setIsEmergencyReply(false);
    setRespondingThreadId(null);
  };
  const addMsgToThreadMutation = trpc.message.addMessageToThread.useMutation({
    onMutate: ({ threadId, text, isEmergency }) => {
      const replyBackup = text;
      trpcutils.message.getMessages.cancel();
      trpcutils.message.getMessages.setData(undefined, (state) => {
        return state!.map((thread) => {
          if (thread._id === threadId) {
            return Transforms.messages.addMessageToThread(
              thread,
              text,
              new Date(),
              user!._id,
              isEmergency
            );
          }
          return thread;
        });
      });
      onResetReply();

      return {
        replyBackup,
      };
    },

    onSuccess: () => {
      trpcutils.message.invalidate();
    },
    onError: (_err, _params, context) => {
      context && setReplyText(context.replyBackup);
    },
  });
  const onAddReplyToThread = () => {
    if (!respondingThreadId) {
      throw new Error("no respondingThreadid while calling onAddReplyToThread");
    }
    addMsgToThreadMutation.mutate({
      threadId: respondingThreadId,
      text: replyText,
      isEmergency: isEmergencyReply,
    });
  };

  const newThreadMutation = trpc.message.createNewThread.useMutation({
    onMutate: () => {
      onClearNewThread();
      const previousText = newThreadText;
      trpcutils.message.getMessages.setData(undefined, (state) => [
        Transforms.messages.createNewThread(
          newThreadText,
          new Date(),
          user!._id,
          isNewThreadEmergency,
          owner!
        ),
        ...(state || []),
      ]);
      return {
        previousText,
      };
    },
    onError: (_err, _ignored, context) => {
      context && setNewThreadText(context.previousText);
    },
    onSettled: () => {
      trpcutils.message.invalidate();
    },
  });
  const onNewThreadCommit = () => {
    newThreadMutation.mutate({
      text: newThreadText,
      isEmergency: isNewThreadEmergency,
    });
  };
  const onReplyKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Escape") {
      replyInputRef.current?.blur();
      onResetReply();
    }
    if (e.key === "Enter") {
      onAddReplyToThread();
    }
  };

  const onReplyClick = (threadId: string) => {
    setRespondingThreadId(threadId);
    markThreadSeenMutation.mutate(threadId);
  };

  const newTextRef = useRef<HTMLTextAreaElement>(null);
  const onNewTextKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Esc") {
      newTextRef.current?.blur();
      onClearNewThread();
    }
    if (e.key === "Enter") {
      onNewThreadCommit();
    }
  };

  const onArchiveClick = (threadId: string) => {
    archiveThreadMutation.mutate(threadId);
  };
  // const onMarkMsgSeend = (threadId: string, msgNumber: number)=>{
  // markMsgSeenMutation.mutate({threadId, msgNumber});
  // }
  const onEmergencyReplyToggle = () => setIsEmergencyReply((state) => !state);
  const onNewThreadEmergencyToggle = () =>
    setIsNewThreadEmergency((state) => !state);
  const threadsComps =
    threads && threads.length > 0 ? (
      sortedThreads.map((thread, idx) => (
        <div key={"thread" + idx}>
          <Thread
            messages={thread.messages}
            isDoctor={isDoctor}
            onArchiveClick={onArchiveClick}
            isResponding={respondingThreadId === thread._id}
            threadId={thread._id}
            replyText={replyText}
            onReplyTextChange={setReplyText}
            onReplySend={onAddReplyToThread}
            onMessageClick={onMarkMsgSeen}
            onReplyClick={onReplyClick}
            onReplyKeyDown={onReplyKeyDown}
            onEmergencyClick={onEmergencyReplyToggle}
            isEmergencyReply={isEmergencyReply}
            replyInputRef={replyInputRef}
          />
        </div>
      ))
    ) : (
      <div className={styles.emptyMessagesBox}>
        Ici apparaîtront les messages échangés entre les membres de l'équipe
      </div>
    );
  const handleNewThreadTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => setNewThreadText(event.currentTarget.value);

  return (
    <>
      <div className={styles.messagesBox}>{threadsComps}</div>
      <div className={styles.replyContainer}>
        <textarea
          id="post_message"
          className={styles.post_message}
          value={newThreadText}
          onChange={handleNewThreadTextChange}
          onKeyDown={onNewTextKeyDown}
          placeholder={"Entrez un nouveau message"}
        />
        <div className={styles.flexContainer}>
          <CheckBox
            checked={isNewThreadEmergency}
            onChange={onNewThreadEmergencyToggle}
            label="Urgent"
            className={styles.colorWhite}
            id={"checkbox_emergency"}
          />
          <Button
            title="Envoi"
            onClick={onNewThreadCommit}
            id="button_post_message"
            size="small"
          />
        </div>
      </div>
    </>
  );
};

export default MessagesMainComp;
