import { formaters } from "vincent-utils";

import { CadendarConsts } from "cadendar-shared";
const { getTypeConstFromTypeNumber, typeTranslator } = CadendarConsts;
import _ from "underts";
import { useSelectedPatientIds } from "../../main/state/globalState";
import useOwner from "../../users/hooks/useOwner";
import { trpc } from "../../main/components/MainContainer";
import { DateTime } from "luxon";
import Settings02Icon from "../../main/icons/settings-02-stroke-rounded.tsx";
import styles from "./ListRdvsPatient.module.css";
import { useEffect, useState } from "react";
import PatientSettingsPopup from "../../patients/components/PatientSettingsPopup.tsx";
import Badge from "../../main/components/Badge.tsx";

const typeToColor = {
  [CadendarConsts.typeConsts.ANN]: "red" as const,
  [CadendarConsts.typeConsts.LAPIN]: "red" as const,
  [CadendarConsts.typeConsts.ANNTARD]: "red" as const,
} as { [key: number]: "red" }; //todo: fix this hack

const ListRdvsPatient = () => {
  const [patientIds] = useSelectedPatientIds();
  const owner = useOwner();
  const patientId = _.first(patientIds);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [settingsPopupTop, setSettingsPopupTop] = useState(0);
  const [settingsPopupLeft, setSettingsPopupLeft] = useState(0);
  const { data: rdvs } = trpc.rdv.findByPatientIds.useQuery(
    { patientIds: [patientId!] },
    { enabled: !!patientId }
  );
  // const [rdvs] = useSuperQuery(
  //   ["rdvs", "all", "byPatientId", patientId],
  //   getAllRdvs,
  //   [],
  //   { enabled: !!patientId },
  //   patientId
  // );
  // console.log("rdvs", rdvs);

  useEffect(() => {
    document.addEventListener("globalClick", () => {
      setShowSettingsPopup(false);
    });
    return () => {
      document.removeEventListener("globalClick", () => {
        setShowSettingsPopup(false);
      });
    };
  }, []);
  const handleShowSettingsPopup = (event: React.MouseEvent) => {
    event.stopPropagation();
    const target = event.currentTarget;
    setSettingsPopupTop(target.getBoundingClientRect().top);
    setSettingsPopupLeft(target.getBoundingClientRect().right + 10);
    setShowSettingsPopup((val) => !val);
  };

  return (
    <>
      <div>
        <div className={styles.headerStyle}>
          <div className={styles.titleStyle}>Liste des rdvs</div>
          <div
            className={styles.buttonStyle}
            data-testid="patientSettingsButton"
          >
            <Settings02Icon onClick={handleShowSettingsPopup} />
          </div>
        </div>
        <div className={styles.centerer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.th}>Date</th>
                <th className={styles.th}>Heure</th>
                <th className={styles.th}>Type</th>
                <th className={styles.th}>Objet</th>
              </tr>
            </thead>
            <tbody>
              {_.sortBy(
                rdvs || [],
                (rdv) =>
                  rdv.date.getTime() +
                  rdv.heure * 60 * 60 * 1000 +
                  rdv.minute * 1000
              ).map((rdv) => (
                <tr key={"rdvKey" + rdv._id} className={styles.tr}>
                  <td className={styles.td}>
                    {formaters.capitaliseEveryFirstLetter(
                      DateTime.fromJSDate(rdv.date).toLocaleString({
                        day: "2-digit",
                        month: "short",
                        year: "2-digit",
                      })
                    )}
                  </td>
                  <td className={styles.td}>
                    {rdv.heure}h
                    {formaters.convertSingleDigitToTwoDigits(rdv.minute)}
                  </td>
                  <td className={styles.td}>
                    <Badge
                      text={
                        typeTranslator[getTypeConstFromTypeNumber(rdv.type)]
                      }
                      color={typeToColor[rdv.type]}
                    />
                  </td>
                  <td className={styles.td}>{rdv.objet}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showSettingsPopup && patientId ? (
        <div
          className={styles.popupStyle}
          style={{ top: settingsPopupTop, left: settingsPopupLeft }}
        >
          <PatientSettingsPopup patientId={patientId} />
        </div>
      ) : null}
    </>
  );
};

export default ListRdvsPatient;
