import { Collections, CadendarConsts } from "cadendar-shared";
import {
  aptRequestUsedAtom,
  useRdvPopupState,
  useSelectedAptRequest,
  useSelectedPatientIds,
} from "../../main/state/globalState";
import useRdvProposal from "../../ficheAppel/hooks/useRdvProposal";
import { trpc } from "../../main/components/MainContainer";
import { useSetAtom } from "jotai";
import styles from "./AptRequestList.module.css";
import Button from "../../main/components/Button.tsx";

interface AptRequestListProps {
  patientId: string;
  onShowFicheAppel: () => void;
}

const AptRequestList = (props: AptRequestListProps) => {
  const { patientId } = props;
  // const aptRequests = useQuery(
  //   ["aptRequestList", patientId],
  //   () => getAptRequests(owner, patientId),
  //   { enabled: !!owner && !!patientId }
  // ).data;
  const { data: aptRequests } =
    trpc.aptRequest.getAllByPatientId.useQuery(patientId);

  const { onSelectAptRequestId } = useSelectedAptRequest();
  const { onOpenRdvPopup } = useRdvPopupState();
  const handleAptRequestView = (aptRequestId: string) => {
    onSelectAptRequestId(aptRequestId);
    onOpenRdvPopup("aptRequestEdit");
  };

  const [, { selectPatientId }] = useSelectedPatientIds();
  const {
    setDuree,
    setType,
    setObjet,
    setReminderChecked,
    setConfirmChecked,
    assignMotifsNums,
  } = useRdvProposal();

  const setAptRequestUsed = useSetAtom(aptRequestUsedAtom);
  const handleTakeAptRequest = (aptRequest: Collections.AptRequest) => {
    selectPatientId(aptRequest.patientId);
    setAptRequestUsed(aptRequest._id);

    setDuree(aptRequest.length);
    setObjet(aptRequest.object);
    setType(aptRequest.type as CadendarConsts.typeNumsType);
    setReminderChecked(aptRequest.reminderChecked);
    setConfirmChecked(aptRequest.confirmChecked);
    assignMotifsNums([]); //hack pour forcer strategor
    props.onShowFicheAppel();
  };

  const handleCreateLink = () => {
    onSelectAptRequestId(null);
    onOpenRdvPopup("aptRequestCreate");
  };
  return (
    <div className={styles.card}>
      <div className={styles.headerStyle}>
        <div className={styles.titleStyle}>Rdvs à prendre</div>
      </div>
      {aptRequests && aptRequests.length > 0 ? (
        <table className={styles.table}>
          <thead>
            <tr className={styles.tr}>
              <th className={styles.th}>Durée</th>
              <th className={styles.th}>Objet</th>
            </tr>
          </thead>
          <tbody id="aptRequestList">
            {aptRequests
              .filter((aptRequests) => !aptRequests.taken)
              .map((aptRequest) => (
                <tr key={aptRequest._id}>
                  <td className={styles.td}>{aptRequest.length}</td>
                  <td className={styles.td}>{aptRequest.object}</td>
                  <td className={styles.td}>
                    <a
                      id="aptRequestEdit"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleAptRequestView(aptRequest._id)}
                    >
                      Editer
                    </a>
                  </td>
                  <td className={styles.td}>
                    {aptRequest.taken ? (
                      <span className="label label-success">Rdv déjà pris</span>
                    ) : (
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleTakeAptRequest(aptRequest)}
                        id="aptRequestTake"
                      >
                        Prendre le rdv
                      </a>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <></>
      )}
      <div className={styles.spacer}>
        <Button
          title="Créer un lien RDV à prendre"
          onClick={handleCreateLink}
        />
      </div>
    </div>
  );
};

export default AptRequestList;
