import Input, { InputProps } from "./Input.tsx";
import styles from "./HInputGroup.module.css";
import classNames from "classnames";

interface HInputGroupProps extends InputProps {
  label: string;
  inputWidth?: string;
  labelWidth?: string;
  id?: string;
  isTouched?: boolean;
  isValid?: boolean;
  readonly?: boolean;
  onBlur?: () => void;
  inputTestId?: string;
  placeholder?: string;
}

const HInputGroup = (props: HInputGroupProps) => {
  const labelStyle = props.labelWidth ? { width: props.labelWidth } : {};
  return (
    <div className={styles.spacer}>
      <label
        htmlFor={props.label + "_input"}
        className={classNames(styles.inputLabel, {
          [styles.invalid]: props.isTouched && !props.isValid,
          [styles.valid]: props.isTouched && props.isValid,
        })}
        id={props.label + "_input"}
        style={labelStyle}
      >
        {props.label}
      </label>
      <Input
        {...props}
        width={props.inputWidth}
        data-testid={props.inputTestId}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default HInputGroup;
