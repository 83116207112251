import { useEffect, useState } from "react";

import FamilleGridRow from "./FamilleGridRow";
import { patientsValidStates } from "../filterTransforms/patientsValidStates";
import { useAtom } from "jotai";
import {
  SelectedPatientIdsAtom,
  useSelectedPatientIds,
} from "../../main/state/globalState";
import {
  DDNSchema,
  E164Schema,
  ExistingPatient,
  NewPatient,
} from "cadendar-shared";
import { FamilleGridKeys } from "../../patients/validators/individualValidators.ts";
import Button from "../../main/components/Button.tsx";
import styles from "./FamilleGridPanel.module.css";
import IconButton from "../../main/components/IconButton.tsx";
import { FaPlus } from "react-icons/fa";
import FamilleExistingPatientAdder from "./FamilleExistingPatientAdder.tsx";
import { existingPatientSchema, newPatientSchema } from "cadendar-shared";
import PageChooser from "../../ficheAppel/components/PageChooser.tsx";
import _ from "underts";
import { v4 as uuidv4 } from "uuid";
import { formaters } from "vincent-utils";
import useSelectedPatients from "../../patients/hooks/useSelectedPatients.ts";
import { trpc } from "../../main/components/MainContainer.tsx";
import { produce } from "immer";
import useTempPatient from "../../ficheAppel/hooks/useTempPatient.ts";
import z from "zod";

const requiredFields = ["nom", "prenom", "ddn", "famille_id"];

const memberIsReadyToSave = (
  membre: LocalExistingPatient | LocalNewPatient
) => {
  const allMembersValid = Object.keys(membre).every((key) => {
    switch (key) {
      case "nom":
        return membre.nom.length > 0;
      case "prenom":
        return membre.prenom.length > 0;
      case "ddn":
        return DDNSchema.safeParse(membre.ddn).success;
      case "portable":
        return (
          !membre.portable || E164Schema.safeParse(membre.portable).success
        ); //empty string is ok
      case "famille_id":
        return membre.famille_id.length > 0;
      case "email":
        return (
          !membre.email || z.string().email().safeParse(membre.email).success
        );
      default:
        return true;
    }
  });
  const presentKeys = Object.keys(membre);
  return (
    requiredFields.every((field) => presentKeys.includes(field)) &&
    allMembersValid
  );
};

const familyIsReadyToSave = (
  membres: (LocalExistingPatient | LocalNewPatient)[]
) => {
  const everyMemberOk = membres.every(memberIsReadyToSave);
  const familleIds = membres.map((m) => m.famille_id);
  const uniqueFamilleIds = new Set(familleIds);
  const familleIdIsUnique = uniqueFamilleIds.size === 1;
  console.log("familleIdIsUnique", familleIdIsUnique);
  console.log("uniqueFamilleIds", uniqueFamilleIds);
  console.log("familleIds", familleIds);
  console.log("everyMemberOk", everyMemberOk);
  return everyMemberOk && familleIdIsUnique;
};

const onSetFamilleId = (
  membres: (LocalNewPatient | LocalExistingPatient)[],
  forceId?: string
) => {
  function applyFamilleId(
    membres: (LocalNewPatient | LocalExistingPatient)[],
    famille_id: string
  ) {
    return membres.map((m) => {
      return { ...m, famille_id };
    });
  }
  if (forceId) {
    console.log("forceId", forceId);
    return applyFamilleId(membres, forceId);
  }
  const familleIdFromMembres = _.uniq(membres.map((p) => p.famille_id));
  if (familleIdFromMembres.length === 1) {
    const famille_id = _.first(familleIdFromMembres) as string;
    return applyFamilleId(membres, famille_id);
  }
  if (familleIdFromMembres.length === 0) {
    const famille_id = uuidv4();
    return applyFamilleId(membres, famille_id);
  }
  throw new Error("multiple famille_id in membres");
};

const patientDdnCompare = (a: { ddn: string }, b: { ddn: string }) => {
  if (!a.ddn) {
    return 1;
  }
  if (!b.ddn) {
    return -1;
  }
  const parsedA = formaters.parseDateString(a.ddn);
  if (!parsedA) {
    return 1;
  }
  const parsedB = formaters.parseDateString(b.ddn);
  if (!parsedB) {
    return -1;
  }
  return parsedA.getTime() - parsedB.getTime();
};

const hasId = <T extends Record<string, any>>(p: T): p is T & { _id: string } =>
  "_id" in p;

const isNewPatient = (p: unknown): p is NewPatient =>
  newPatientSchema.safeParse(p).success;

const isExistingPatient = (p: unknown): p is ExistingPatient =>
  existingPatientSchema.safeParse(p).success;

const sortPatients = (patients: (LocalNewPatient | LocalExistingPatient)[]) => {
  const patientsWithIds = patients.filter(hasId);
  const patientsWithoutIds = patients.filter((p) => !hasId(p));
  patientsWithIds.sort(patientDdnCompare);
  return [...patientsWithIds, ...patientsWithoutIds];
};

interface FamilleGridPanelProps {
  isFicheAppelMode: boolean;
  onPrevPage?: () => void;
  onNextPage?: () => void;
  onClose: () => void;
}

export interface LocalNewPatient {
  nom: string;
  prenom: string;
  portable: string;
  ddn: string;
  email: string;
  famille_id: string;
}

export type LocalExistingPatient = LocalNewPatient & {
  _id: string;
};

function convertToLocalPatient<P extends NewPatient | ExistingPatient>(
  patient: P
) {
  return {
    nom: patient.nom || "",
    prenom: patient.prenom || "",
    portable: patient.portableE164
      ? formaters.formatTelNumberForDisplay(patient.portableE164)
      : "",
    ddn: patient.ddn || "",
    email: patient.email || "",
    famille_id: patient.famille_id || "",
    ...("_id" in patient ? { _id: patient._id } : {}),
  };
}

export function convertLocalExistingPatientToExistingPatient(
  patient: LocalExistingPatient
) {
  const e164 = E164Schema.safeParse(patient.portable);
  return {
    _id: patient._id,
    nom: patient.nom,
    prenom: patient.prenom,
    portableE164: e164.success ? e164.data : null,
    ddn: DDNSchema.parse(patient.ddn),
    email: patient.email,
    ...(patient.famille_id ? { famille_id: patient.famille_id } : {}),
  };
}

export function convertLocalNewPatientToNewPatient(patient: LocalNewPatient) {
  const e164 = E164Schema.safeParse(patient.portable);
  return {
    nom: patient.nom,
    prenom: patient.prenom,
    portableE164: e164.success ? e164.data : null,
    ddn: DDNSchema.parse(patient.ddn),
    email: patient.email,
    ...(patient.famille_id ? { famille_id: patient.famille_id } : {}),
  };
}

function convertToExistingPatient(patient: LocalExistingPatient) {
  const e164 = E164Schema.safeParse(patient.portable);
  return {
    _id: patient._id,
    nom: patient.nom,
    prenom: patient.prenom,
    portableE164: e164.success ? e164.data : null,
    ddn: DDNSchema.parse(patient.ddn),
    email: patient.email,
    ...(patient.famille_id ? { famille_id: patient.famille_id } : {}),
  };
}

const FamilleGridPanel = (props: FamilleGridPanelProps) => {
  const [membres, setMembres] = useState<
    (LocalNewPatient | LocalExistingPatient)[]
  >([]);
  const [membresToRemoveFromFamille, setMembresToRemoveFromFamille] = useState<
    LocalExistingPatient[]
  >([]);
  const selectedPatients = useSelectedPatients();
  const selectedPatient = _.first(selectedPatients);
  const [, setSelectedPatientIds] = useAtom(SelectedPatientIdsAtom);
  const [isAddingExistingPatient, setIsAddingExistingPatient] = useState(false);
  // const [, setFamilleGridOpenState] = useAtom(FamilleGridOpenState);

  const [, { selectPatientId }] = useSelectedPatientIds();

  useEffect(() => {
    if (membres.length === 0 && selectedPatient) {
      const convertedPatient = {
        _id: selectedPatient._id,
        nom: selectedPatient.nom || "",
        prenom: selectedPatient.prenom || "",
        portable: formaters.formatTelNumberForDisplay(
          selectedPatient.portableE164?.toString() || ""
        ),
        ddn: selectedPatient.ddn || "",
        email: selectedPatient.email || "",
        famille_id: selectedPatient.famille_id || "",
      };
      addPatient(convertedPatient);
    }
  }, [JSON.stringify(selectedPatient)]);

  const { tempPatient, adresseurPatient, selectedFamilleAdressedBy } =
    useTempPatient();

  useEffect(() => {
    if (selectedFamilleAdressedBy && adresseurPatient) {
      addPatient(convertToLocalPatient(adresseurPatient)).then(() => {
        addPatient(tempPatient);
      });
    }
  }, [JSON.stringify(selectedFamilleAdressedBy)]);
  console.log("tempPatient", tempPatient);

  const _onPatientsFetched = (patients: ExistingPatient[]) => {
    setMembres((membres) => {
      const membresToAdd = patients.filter(
        (patient) => !membres.find((p) => "_id" in p && p._id === patient._id)
      );
      const membresToAddConverted = membresToAdd.map((p) => {
        return {
          _id: p._id,
          nom: p.nom || "",
          prenom: p.prenom || "",
          portable: p.portableE164
            ? formaters.formatTelNumberForDisplay(p.portableE164)
            : "",
          ddn: p.ddn || "",
          email: p.email || "",
          famille_id: p.famille_id || "",
        };
      });
      return sortPatients([...membres, ...membresToAddConverted]);
    });
  };
  const familleIds = membres.map((m) => m.famille_id);
  const uniqueFamilleIds = [...new Set(familleIds)];
  console.log("uniqueFamilleIds", uniqueFamilleIds);
  const familleId = _.first(uniqueFamilleIds);

  trpc.patient.findByFamilleId.useQuery(
    { familleId: familleId! },
    {
      enabled: !!familleId,
      onSuccess: _onPatientsFetched,
    }
  );

  // useEffect(() => {
  //   setFamilleGridOpenState(true);
  // }, []);

  const stateIsReadyToSave = familyIsReadyToSave(membres);
  console.log("membres", membres);
  console.log("stateIsReadyToSave", stateIsReadyToSave);
  console.log("disabled", !stateIsReadyToSave);
  const addNewPatient = () => {
    //les nouveaux patients n'ont pas encore d'id car pas enregistrés dans la base
    //utile de les laisser comme ça pour qu'ils soient classés correctement
    setMembres((membres) =>
      ensureId(
        produce(membres, (draft) => {
          draft.push({
            nom: "",
            prenom: "",
            portable: "",
            ddn: "",
            email: "",
            famille_id: "",
          });
        })
      )
    );
  };

  const updatePatient = <ITEM extends FamilleGridKeys>(
    idx: number,
    item: ITEM,
    value: LocalNewPatient[ITEM]
  ) => {
    setMembres((membres) =>
      produce(membres, (draft) => {
        draft[idx][item] = value;
      })
    );
  };

  const copyDown = <ITEM extends FamilleGridKeys>(idx: number, item: ITEM) => {
    setMembres((membres) =>
      produce(membres, (draft) => {
        const pat = draft[idx];
        const value = pat[item];
        draft.forEach((_, i) => {
          if (i > idx && !draft[i][item]) {
            draft[i][item] = value;
          }
        });
      })
    );
  };

  const addPatient = async (
    patient: LocalNewPatient | LocalExistingPatient
  ) => {
    console.log("addPatient", patient);
    setMembres((membres) => {
      if ("_id" in patient) {
        //il est déjà dans les membres
        if (membres.find((p) => hasId(p) && p._id === patient._id)) {
          console.warn("patient already in famille");
          return membres;
        }
      }
      const addedPatientDDN = patient.ddn;
      if (membres.find((p) => p.ddn === addedPatientDDN)) {
        console.warn("patient with same ddn already in famille");
        return membres;
      }
      let newMembres = [...membres, patient];
      if (patient.famille_id) {
        newMembres = onSetFamilleId(newMembres, patient.famille_id); //si famille_id est undefined, ça marche aussi
      }

      newMembres = sortPatients(newMembres);
      newMembres = ensureId(newMembres);
      return newMembres;
    });
  };

  const ensureId = (membres: (LocalExistingPatient | LocalNewPatient)[]) => {
    const famille_id =
      membres.find((m) => !!m.famille_id)?.famille_id || uuidv4();
    return onSetFamilleId(membres, famille_id);
  };

  const removeId = (membre: LocalExistingPatient | LocalNewPatient) => {
    return { ...membre, familleId: "" };
  };

  const utils = trpc.useContext();
  const removeFamilleIdMutation = trpc.patient.removeFamilleId.useMutation({
    onSuccess: () => {
      utils.patient.invalidate();
      setMembresToRemoveFromFamille([]);
    },
  });
  const removePatientFromFamille = (idx: number) => {
    const patientToRemove = membres[idx];
    setMembres((membres) =>
      produce(membres, (draft) => {
        if (idx < draft.length) {
          draft.splice(idx, 1);
        } else {
          throw new Error(
            "trying to remove a patient at an index out of range"
          );
        }
      })
    );

    if ("_id" in patientToRemove) {
      setMembresToRemoveFromFamille((membres) => [...membres, patientToRemove]);
    }
  };

  const saveEditePatientMutation = trpc.patient.saveEdited.useMutation({
    onSuccess: () => {
      utils.patient.invalidate();
      props.onClose();
    },
  });
  const updatePatientMutation = trpc.patient.update.useMutation({
    onSuccess: () => {
      utils.patient.invalidate();
      props.onClose();
    },
  });
  const saveNewPatientMutation = trpc.patient.create.useMutation({
    onSuccess: (_id) => {
      utils.patient.invalidate();
      selectPatientId(_id);
      props.onClose();
    },
  });

  const savePatients = async () => {
    if (membres.length === 0) {
      return false;
    }
    const withFamilleId =
      membres.length > 1 ? ensureId(membres) : [removeId(membres[0])];
    const existingPatients = withFamilleId.filter(
      (p) => "_id" in p
    ) satisfies LocalExistingPatient[];
    const newPatients = withFamilleId.filter((p) => !("_id" in p));
    if (
      existingPatients.find((p) => !p.famille_id) &&
      existingPatients.length !== 1
    ) {
      throw new Error(
        "famille_id is undefined while saving patient " +
          JSON.stringify(existingPatients, null, 2)
      );
    }
    if (newPatients.find((p) => !p.famille_id) && newPatients.length !== 1) {
      throw new Error(
        "famille_id is undefined while saving patient " +
          JSON.stringify(newPatients, null, 2)
      );
    }
    existingPatients
      .map(convertToExistingPatient)
      .forEach((patient) =>
        updatePatientMutation.mutate({ _id: patient._id, props: patient })
      );
    newPatients.map(convertLocalNewPatientToNewPatient).forEach((patient) => {
      saveNewPatientMutation.mutate(patient);
    });
    membresToRemoveFromFamille.forEach((patient) => {
      removeFamilleIdMutation.mutate(patient._id);
    });
  };

  const onFamilleEditCancel = () => {
    props.onClose();
  };

  const onFamilleSave = async () => {
    savePatients();
  };
  const onAddPatient = (patient: ExistingPatient) => {
    addPatient(convertToLocalPatient(patient));
    setSelectedPatientIds([patient._id]);
  };

  const onCopyDown = (idx: number) => (carac: FamilleGridKeys) => {
    copyDown(idx, carac);
  };
  const onRemovePatientFromFamille = (idx: number) => () => {
    removePatientFromFamille(idx);
  };
  const validArr = patientsValidStates(membres);
  const mainValid = validArr.every((valid) => valid);

  const handleNextPage = () => {
    savePatients().then(() => {
      props.onNextPage ? props.onNextPage() : null;
    });
  };
  const rowComps = membres.map((membre, idx) => (
    <FamilleGridRow
      {...membre}
      onMembreChange={(carac: FamilleGridKeys, value: string) =>
        updatePatient(idx, carac, value)
      }
      isValid={validArr[idx]}
      familyRank={idx}
      key={("_id" in membre && membre._id) || idx}
      onCopyDown={onCopyDown(idx)}
      onRemoveMembre={onRemovePatientFromFamille(idx)}
    />
  ));
  const rowComposWithButton = [
    ...rowComps,
    <tr key="newPatientButtonRow">
      <td>
        <IconButton
          onClick={addNewPatient}
          id="addLineFamilleGrid"
          tooltip="Créer nouveau patient"
          data-testid="addLineFamilleGrid"
          className={styles.col3}
        >
          <FaPlus />
        </IconButton>
      </td>
    </tr>,
  ];
  const existingMembresComp = isAddingExistingPatient ? (
    <FamilleExistingPatientAdder onAddPatient={onAddPatient} />
  ) : (
    <span></span>
  );

  return (
    <div>
      <div className={styles.verticalSpacer}>
        <table className="" id="familleGrid">
          <thead>
            <tr className={styles.header}>
              <th></th>
              <th>Nom</th>
              <th>Prenom</th>
              <th>DDN</th>
              <th>Portable</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>{rowComposWithButton}</tbody>
        </table>
        <div className={styles.verticalSpacer}>
          <div>
            <Button
              title={"Ajouter Patient Existant"}
              onClick={() => setIsAddingExistingPatient(true)}
              id={"addExistingPatientToFamille"}
              style="secondary"
            />
          </div>
          {existingMembresComp}
          {props.isFicheAppelMode && props.onPrevPage ? (
            <PageChooser
              prevPageEnabled={true}
              nextPageEnabled={stateIsReadyToSave}
              onPrevPage={props.onPrevPage}
              onNextPage={handleNextPage}
              prevPageText="Précédent"
              nextPageText="Suivant"
            />
          ) : (
            <div className={styles.horizontalSpacer}>
              <Button
                title={"Enregistrer"}
                onClick={onFamilleSave}
                id={"familleGridSaveInDb"}
                style="main"
                disabled={!stateIsReadyToSave}
              />
              <Button
                title={"Annuler modifications"}
                onClick={onFamilleEditCancel}
                id={"familleGridClearSession"}
                style="secondary"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FamilleGridPanel;
