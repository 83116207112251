import HoraireDisplay from "./HoraireDisplay.tsx";


import { CadendarConsts } from "cadendar-shared";
import { HoraireInDB, Jours } from "cadendar-shared";
import HoraireLineEdit, { HoraireLineEditProps } from "./HoraireLineEdit";
import {DateTime} from "luxon";

const { jours } = CadendarConsts;

export interface HorairesPanelProps {
  horaire: HoraireInDB|undefined;
  localHoraire: { [jour in Jours]: HoraireLineEditProps["plages"] };
  setPrevEditWeek: () => void;
  setNextEditWeek: () => void;
  addPlage: (jour: Jours) => void;
  removePlage: (jour: Jours) => void;
  setPlages: (jour: Jours, plages: { start: string; end: string }[]) => void;
  isEditing: boolean;
  setIsEditing: () => void;
  saveNewHoraires: () => void;
  onCancel: () => void;
  currentWeek: Date;
  validationMap: { [jour in Jours]: { start: string[]; end: string[] }[] };
}

const HorairesPanel = (props: HorairesPanelProps) => {
  return (
    <>
      {props.horaire ?(
      <HoraireDisplay horaire={props.horaire} />): null}

      <div className="clear_div">
        <button
          className="btn btn-default"
          id="nouveauxHorairesButton"
          type="button"
          onClick={() => props.setIsEditing()}
        >
          Nouveaux Horaires
        </button>
        Les nouveaux horaires commencent le lundi
      </div>
      {props.isEditing ? (
        <>
          <div>
            {DateTime.fromJSDate(props.currentWeek).toLocaleString(DateTime.DATE_MED)}
            <div className="btn-group">
              <button
                className="btn"
                id="buttonPrevLundi"
                onClick={props.setPrevEditWeek}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </button>
              <button
                className="btn"
                id="buttonNextLundi"
                onClick={props.setNextEditWeek}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row-fluid">
              {jours.map(jour => (
                <div className="span1" key={jour + "key"}>
                  {jour + " "}
                  <HoraireLineEdit
                    plages={props.localHoraire[jour]}
                    addPlage={() => props.addPlage(jour)}
                    removePlage={() => props.removePlage(jour)}
                    onSetPlages={plages => props.setPlages(jour, plages)}
                    validationMap={props.validationMap[jour]}
                    jour={jour}
                  />
                </div>
              ))}
            </div>
            <div className="row-fluid">
              <button
                className="btn btn-default"
                id="buttonSaveNewHoraires"
                onClick={props.saveNewHoraires}
                disabled={Object.values(props.validationMap).some(errorPlages =>
                  errorPlages.some(
                    errorPlage =>
                      errorPlage.start.length > 0 || errorPlage.end.length > 0
                  )
                )}
              >
                Enregistrer
              </button>

              <button
                className="btn btn-default"
                id="buttonSaveNewHoraires"
                onClick={props.onCancel}
              >
                Annuler
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default HorairesPanel;
